import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-supported-channels',
  templateUrl: './supported-channels.component.html',
  styleUrls: ['./supported-channels.component.scss']
})
export class SupportedChannelsComponent {
  @Input() supportedChannels: string[] = [];

}
