import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { debounceTime, Subject, Subscription, takeUntil } from 'rxjs';

import { ConfirmationDialogComponent } from '../shared/components/app-confirmation-dialog.component';
import {
  AppState,
  OrderStatus,
  OrderType,
  LoadingState,
} from '../../state/app-state';
import { PdfAnalysisResultService } from '../../services/pdf-analysis-result.service';
import { AppStateService } from '../../services/app-state.service';
import { isObjectEmpty, isArrayEmpty } from '../shared/utils/helper';
import { FirebaseService } from '../../services/firebase.service';
import { OrderService } from '../../services/order.service';
import { TranslateService } from '@ngx-translate/core';
// View Models.

const OrderSortProperties = ['Date', 'Order name', 'Recipients'] as const;
type OrderSortPropertyType = (typeof OrderSortProperties)[number];

type OrderTransformationCriteria = {
  orderNameSearchKeyword: string;
  orderStatusList: (OrderStatus | 'All')[];
  sort: {
    property: OrderSortPropertyType;
    direction: 'asc' | 'desc';
  };
};

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit, OnDestroy {
  currentState!: AppState;
  status: { name: OrderStatus | 'All'; selected: boolean }[] = [
    {
      name: 'All',
      selected: true,
    },
    {
      name: 'Draft',
      selected: true,
    },
    {
      name: 'PDFMismatch',
      selected: true,
    },
    {
      name: 'Error',
      selected: true,
    },
    {
      name: 'Done',
      selected: true,
    },
  ];
  sorter: OrderSortPropertyType[] = ['Date', 'Order name', 'Recipients'];
  selectedStatusDisplay = 'Status: All';
  selectedSort: OrderSortPropertyType = 'Date';
  selectedSortDirection: 'asc' | 'desc' = 'desc';
  orderNameSearchKeyword = '';
  orders: OrderType[] = [];
  orderSearchTerm$ = new Subject<void>();
  private destroy$ = new Subject<void>();
  private subscriptions: Subscription = new Subscription();
  private profileId: string = '';

  constructor(
    private router: Router,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private pdfAnalysisResultService: PdfAnalysisResultService,
    private firebaseService: FirebaseService,
    public stateService: AppStateService,
    private orderService: OrderService,
    private translate: TranslateService
  ) {}

  async ngOnInit() {
    this.stateService.appState$
      .pipe(takeUntil(this.destroy$))
      .subscribe((appState: AppState) => {
        this.currentState = appState;
        if (
          !isObjectEmpty(appState.currentUser) &&
          !isObjectEmpty(appState.currentProfile) &&
          !appState.orders.isLoading &&
          !appState.orders.isLoaded
        ) {
          this.stateService.startLoading(LoadingState.OrdersLoading);
          this.orderService.loadOrdersNotDone().then(() => {
            this.stateService.stopLoading(LoadingState.OrdersLoading);
          });
        }

        this.orders = OrdersComponent.transformOrders(
          Array.from(this.currentState.orders.list.values()),
          this.getTransformationCriteria
        );

        if (appState.profileId && this.profileId !== appState?.profileId) {
          this.profileId = appState?.profileId;
        }
      });

    // Transform orders on search trigger with debounce.
    const OrderSearchDebounceTimeMs = 250;
    this.orderSearchTerm$
      .pipe(debounceTime(OrderSearchDebounceTimeMs), takeUntil(this.destroy$))
      .subscribe((_) => {
        this.orders = OrdersComponent.transformOrders(
          Array.from(this.currentState.orders.list.values()),
          this.getTransformationCriteria
        );
      });

    this.subscriptions.add(
      this.pdfAnalysisResultService.subscribeOrdersPdfAnalysis()
    );
  }

  selectSort(value: any) {
    this.selectedSort = value;
    this.orders = OrdersComponent.transformOrders(
      Array.from(this.currentState.orders.list.values()),
      this.getTransformationCriteria
    );
  }

  sort(value: any) {
    this.selectedSortDirection = value;
    this.orders = OrdersComponent.transformOrders(
      Array.from(this.currentState.orders.list.values()),
      this.getTransformationCriteria
    );
  }

  selectStatus(
    $event: any,
    value: { name: OrderStatus | 'All'; selected: boolean }
  ) {
    $event.stopPropagation();
    $event.preventDefault();

    // Update display status state and status options.
    const item = this.status.findIndex((s) => s.name == value.name);
    if (item != undefined) {
      this.status[item].selected = !this.status[item].selected;

      if (item == 0) {
        this.status.forEach((s) => (s.selected = this.status[item].selected));
        this.selectedStatusDisplay = this.status[item].selected
          ? 'Status: All'
          : 'Status:';
      } else {
        const selectedArr: string[] = [];
        for (let i = 1; i < this.status.length; i++) {
          if (this.status[i].selected) selectedArr.push(this.status[i].name);
        }

        if (selectedArr.length < this.status.length - 1) {
          this.status[0].selected = false;
          this.selectedStatusDisplay = 'Status: ' + selectedArr.join();
        } else if (selectedArr.length == this.status.length - 1) {
          this.status[0].selected = true;
          this.selectedStatusDisplay = 'Status: All';
        } else {
          this.selectedStatusDisplay = 'Status: ' + selectedArr.join();
        }
      }
    }

    // Transform the order list.
    this.orders = OrdersComponent.transformOrders(
      Array.from(this.currentState.orders.list.values()),
      this.getTransformationCriteria
    );
  }

  private static filterOrders(
    orders: OrderType[],
    orderStatusList: OrderTransformationCriteria['orderStatusList']
  ) {
    const hasAllOrderStatus = orderStatusList.includes('All');
    return orders.filter(
      (orderType) =>
        hasAllOrderStatus || orderStatusList.includes(orderType.status)
    );
  }

  private static sortOrders(
    orders: OrderType[],
    sortCriteria: OrderTransformationCriteria['sort']
  ) {
    const compareValues = (
      property: OrderSortPropertyType,
      a: OrderType,
      b: OrderType
    ): number => {
      switch (property) {
        case 'Date':
          return (
            a.createdAt.toDate().getTime() - b.createdAt.toDate().getTime() //a.createdAt.toDate().getTime() - b.createdAt.toDate().getTime()
          );
        case 'Order name':
          return a.name.localeCompare(b.name);
        case 'Recipients':
          return a.numRecipients - b.numRecipients;
        default:
          throw new Error(`Invalid sort property: ${property}`);
      }
    };
    const { property, direction } = sortCriteria;
    const comparisonFunction = (a: OrderType, b: OrderType) =>
      compareValues(property, a, b);

    if (direction === 'desc') {
      return orders.sort((a, b) => -comparisonFunction(a, b));
    }

    return orders.sort(comparisonFunction);
  }

  private static transformOrders(
    orders: OrderType[],
    orderTransformationCriteria: OrderTransformationCriteria
  ) {
    const { orderNameSearchKeyword, orderStatusList } =
      orderTransformationCriteria;
    const searchKeyword = orderNameSearchKeyword?.trim().toLocaleLowerCase();

    const filteredOrders = this.filterOrders(orders, orderStatusList).filter(
      (orderType) =>
        (orderType?.name || '')
          .trim()
          .toLocaleLowerCase()
          .includes(searchKeyword)
    );

    const sortedOrders = this.sortOrders(
      filteredOrders,
      orderTransformationCriteria.sort
    );

    return sortedOrders;
  }

  get getAllOrderStatusOrAllStatus() {
    return this.status.filter((s) => s.selected).map((s) => s.name);
  }

  get getTransformationCriteria(): OrderTransformationCriteria {
    return {
      orderNameSearchKeyword: this.orderNameSearchKeyword,
      orderStatusList: this.getAllOrderStatusOrAllStatus,
      sort: {
        property: this.selectedSort,
        direction: this.selectedSortDirection,
      },
    };
  }

  onNewOrder() {
    this.router.navigate([
      this.profileId,
      'orders',
      'null',
      'edit',
      'doc-upload',
    ]);
  }

  onSearchChange($event: any) {
    this.orderSearchTerm$.next();
  }

  async onSelectCompany(eventPayload: { profileId?: string }) {}

  onEditOrder(orderId: string) {
    // Set current edit order in app state.
    const currentEditOrder = this.currentState.orders.list.get(orderId) ?? null;
    this.stateService.updateState({ currentEditOrder });
    this.router.navigate([
      this.profileId,
      'orders',
      orderId,
      'edit',
      currentEditOrder?.currentEditStep,
    ]);
  }

  onViewOrderSummary(orderId: string) {
    const currentEditOrder = this.currentState.orders.list.get(orderId) ?? null;
    this.stateService.updateState({ currentEditOrder });
    this.router.navigate([this.profileId, 'orders', orderId, 'summary']);
  }

  isExpandDisabled(order: OrderType): boolean {
    return (
      order.status !== 'PdfSplitting' &&
      order.status !== 'MessageProduction' &&
      order.status !== 'Done' &&
      order.status !== 'InProduction'
    );
  }

  async onDeleteOrder(orderId: string) {
    let confirmationMessage = '';
    this.translate
      .get('delete.confirmationMessage')
      .subscribe((res: string) => {
        confirmationMessage = res;
      });
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: { message: confirmationMessage },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      // Update the data set.
      if (confirm) {
        this.stateService.startLoading(LoadingState.OrderLoading);

        this.firebaseService
          .deleteOrderInfo(this.profileId, orderId)
          .then(() => {
            this.orders = OrdersComponent.transformOrders(
              Array.from(this.currentState.orders.list.values()),
              this.getTransformationCriteria
            );
            this.stateService.stopLoading(LoadingState.OrderLoading);
          });
      }
    });
  }

  async onAdjustOrder(orderId: string) {
    try {
      this.stateService.startLoading(LoadingState.OrdersLoading);
      await this.pdfAnalysisResultService.deletePropsWhenMismatched(orderId);
      this.stateService.stopLoading(LoadingState.OrdersLoading);
      this.router.navigate([
        this.profileId,
        'orders',
        orderId,
        'edit',
        'doc-upload',
      ]);
    } catch (error) {
      console.error('Error in onAdjustOrder:', error);
    }
  }

  onPageLimitOrder(orderId: string) {
    const currentEditOrder = this.currentState.orders.list.get(orderId) ?? null;
    this.stateService.updateState({ currentEditOrder });
    this.router.navigate([
      this.profileId,
      'orders',
      orderId,
      'edit',
      'doc-upload',
    ]);
  }

  trackByOrderId(index: number, order: OrderType): string {
    return order.id;
  }

  isNoSearchResult() {
    return isArrayEmpty(this.orders);
  }

  navigateToPresets() {
    this.router.navigate([this.profileId, 'presets']);
  }
  
  goToMonitoring() {
    this.router.navigate([this.profileId, 'monitoring']);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.subscriptions && !this.subscriptions.closed) {
      this.subscriptions.unsubscribe();
    }
    // reset orders.isLoaded to false
    if (this.currentState) {
      this.stateService.updateState({
        orders: { ...this.currentState.orders, isLoaded: false },
      });
    }
  }
}
