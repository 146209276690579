export function isArrayEmpty(arr: any[] | undefined | null) {
  return arr == null || arr == undefined || arr.length === 0;
}

export function isObjectEmpty(obj: Object | undefined | null) {
  return obj == null || obj == undefined || Object.keys(obj).length === 0;
}

export function getCurrentDateTimeString() {
  const date = new Date();
  let month = '' + (date.getMonth() + 1),
    day = '' + date.getDate(),
    year = date.getFullYear(),
    hour = '' + date.getHours(),
    minute = '' + date.getMinutes(),
    second = '' + date.getSeconds();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hour.length < 2) hour = '0' + hour;
  if (minute.length < 2) minute = '0' + minute;
  if (second.length < 2) second = '0' + second;

  return [month, day, year].join('-') + ' ' + [hour, minute, second].join(':');
}

export function getCurrentDateString() {
  const date = new Date();
  let month = '' + (date.getMonth() + 1),
    day = '' + date.getDate(),
    year = date.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-'); // Formats as YYYY-MM-DD
}

export function getCurrentTimeString() {
  const date = new Date();
  let hour = '' + date.getHours(),
    minute = '' + date.getMinutes(),
    second = '' + date.getSeconds();

  if (hour.length < 2) hour = '0' + hour;
  if (minute.length < 2) minute = '0' + minute;
  if (second.length < 2) second = '0' + second;

  return [hour, minute, second].join(':'); // Formats as HH:MM:SS
}

function sortObjectByKeys(unordered: any): any {
  if (unordered instanceof Array) {
    return unordered.map(sortObjectByKeys);
  } else if (unordered instanceof Object) {
    const ordered: any = {};
    Object.keys(unordered)
      .sort()
      .forEach((key) => {
        ordered[key] = sortObjectByKeys(unordered[key]);
      });
    return ordered;
  }
  return unordered;
}

export function isEquivalent(a: any, b: any): boolean {
  const sortedA = sortObjectByKeys(a);
  const sortedB = sortObjectByKeys(b);
  const aProps = Object.getOwnPropertyNames(sortedA);
  const bProps = Object.getOwnPropertyNames(sortedB);

  if (aProps.length !== bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];
    const aValue = sortedA[propName];
    const bValue = sortedB[propName];

    if (aValue instanceof Object && bValue instanceof Object) {
      if (!isEquivalent(aValue, bValue)) {
        return false;
      }
    } else if (aValue !== bValue) {
      return false;
    }
  }

  return true;
}
