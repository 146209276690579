import { Component, Input, OnChanges } from '@angular/core';

import { PdfAnalysisStages } from '../../../../state/app-state';

@Component({
  selector: 'app-pdf-analysis-process-indicator',
  templateUrl: './pdf-analysis-process-indicator.component.html',
  styleUrls: ['./pdf-analysis-process-indicator.component.scss'],
})
export class PdfProcessIndicatorComponent implements OnChanges {
  @Input() analysisStatus: PdfAnalysisStages = 'WAITING';
  @Input() prepared: boolean = false;
  // PREPARING
  @Input() preparingProgressPercentage: number = 0;
  @Input() preparingDocumentCurrentNum: number | undefined = 0;
  @Input() preparingDocumentCount: number | undefined = 0;
  // DETECTING
  @Input() detectingProgressPercentage: number = 0;
  @Input() detectingCurrentPageNum: number | undefined = 0;
  @Input() detectingNumPages: number | undefined = 0;
  // SEGREGATING
  @Input() segregatingProgressPercentage: number = 0;
  @Input() segregatingCurrentPageNum: number | undefined = 0;
  @Input() segregatingNumPages: number | undefined = 0;
  // VALIDATING
  @Input() validatingProgressPercentage: number = 0;
  @Input() validationCurrentMessageNum: number | undefined = 0;
  @Input() validationNumMessages: number | undefined = 0;

  stepColor: string[] = ['', '', ''];
  progressBarMode: 'indeterminate' | 'determinate' = 'indeterminate';
  progressValue = 0;
  showProgressBar = true; // Add a new property to control the visibility of the progress bar

  ngOnChanges(): void {
    this.updateStepColors();
    this.updateProgressBar();
  }

  private updateStepColors(): void {
    switch (this.analysisStatus) {
      case 'PREPARING':
        this.stepColor = ['orange', 'grey', 'grey', 'grey'];
        break;
      case 'DETECTING':
        this.stepColor = ['green', 'orange', 'grey', 'grey'];
        break;
      case 'SEGREGATING':
        this.stepColor = ['green', 'green', 'orange', 'grey'];
        break;
      case 'VALIDATING':
        this.stepColor = ['green', 'green', 'green', 'orange'];
        break;
      case 'DONE':
        this.stepColor = ['green', 'green', 'green', 'green'];
        break;
      default:
        this.stepColor = ['grey', 'grey', 'grey', 'grey'];
        break;
    }
  }

  private updateProgressBar(): void {
    if (this.analysisStatus === 'WAITING') {
      this.showProgressBar = false;
    } else {
      this.showProgressBar = true;

      if (this.analysisStatus === 'PREPARING') {
        this.progressBarMode = 'determinate';
        this.progressValue = this.preparingProgressPercentage;
      } else if (this.analysisStatus === 'DETECTING') {
        this.progressBarMode = 'determinate';
        this.progressValue = this.detectingProgressPercentage;
      } else if (this.analysisStatus === 'SEGREGATING') {
        this.progressBarMode = 'determinate';
        this.progressValue = this.segregatingProgressPercentage;
      } else if (this.analysisStatus === 'VALIDATING') {
        this.progressBarMode = 'determinate';
        this.progressValue = this.validatingProgressPercentage;
      } else {
        this.progressBarMode = 'determinate';
        this.progressValue = 100;
      }
    }
  }
}
