<ng-container *ngIf="this.stateService.appState$ | async as appState">
    <div *ngIf="!!this.appState.currentEditOrder" class="page">
        <!-- edit order subheader -->
        <app-order-name-subject-sub-header></app-order-name-subject-sub-header>

        <div class="content-header-container" style="margin-top:50px">
            <div>
                <img src="assets/img/upload_img.svg">
            </div>
            <div class="d-flex" >
                <div class="flex-column">
                    <div class="title">{{"pages.addressCheck.yourRecipients" | translate}}</div>
                    <div class="subtitle">{{"pages.addressCheck.statement" | translate}}</div>
                </div>
            </div>
        </div>

        <div class="content-body-container">
            <div class="d-flex flex-column address-check-content">
                <div class="d-flex">
                    <div style="padding-right: 60px; width: 100%;">
                        <div>
                            <mat-chip-listbox (change)="applyFilter($event)">
                                <mat-chip-option selected value="all">{{"filter.All" | translate}}</mat-chip-option>
                                <mat-chip-option value="withIssues">{{"pages.addressCheck.withIssues" | translate}}</mat-chip-option>
                                <mat-chip-option value="withoutIssues">{{"pages.addressCheck.withoutIssues" | translate}}</mat-chip-option>
                            </mat-chip-listbox>
                        </div>
                        <div class="d-flex flex-column recipients-table-container">
                            <table mat-table [dataSource]="dataSource" style="height: 100%;" matSort>
                                <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
                                        <th 
                                            style="padding: 0 10px;"
                                            mat-header-cell *matHeaderCellDef mat-sort-header
                                            [disabled]="!column.sortable" 
                                            [ngClass]="column.cellClass"
                                        >
                                        {{!column.header ? '' : ('table.' + column.header | translate)}}
                                        </th>
                                        <td mat-cell *matCellDef="let row" style="padding: 10px;" [ngClass]="column.cellClass">
                                            <ng-container [ngSwitch]="column.columnDef" >
                                                <ng-container *ngSwitchCase="'image'">
                                                    <div style="position: relative; width: 35px; height: 48px;">
                                                        <div class="skeleton-loader" *ngIf="!imageLoaded[row]"></div>
                                                        <img 
                                                            [src]="column.cell(row)" (load)="onImageLoad(row)" 
                                                            style="width: 100%; height: 100%; border: 1px solid #D1D1D1;"
                                                        >
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'action'">
                                                    <div class="d-flex flex-row">
                                                        <button *ngIf="!row?.error" mat-icon-button aria-label="" (click)="onEditRow(row)">
                                                            <img src="assets/logos/pencil-icon.svg" style="height: 18px; width: 18px">
                                                        </button>
                                                        <button mat-icon-button aria-label="" (click)="onDeleteRow(row)">
                                                            <img src="assets/logos/trash-icon.svg" style="height: 18px; width: 18px">
                                                        </button>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'addressCheck'">
                                                    <app-address-validation-flag [validationResult]="row"></app-address-validation-flag>
                                                </ng-container>
                                                <ng-container  *ngSwitchCase="'recipient'">
                                                    <ng-container *ngIf="column.cell(row) as recipientInfo">
                                                        <div *ngIf="isRecipientType(recipientInfo)">
                                                            <div>{{recipientInfo.recipient}}</div>
                                                            <div>{{recipientInfo.email}}</div>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container  *ngSwitchCase="'invoice-info'">
                                                    <ng-container *ngIf="column.cell(row) as invoiceInfo">
                                                        <div *ngIf="isInvoiceType(invoiceInfo)">
                                                            <div>{{invoiceInfo.amount}}</div>
                                                            <div>{{invoiceInfo.reference}}</div>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'subject'">    
                                                    <app-subject-icon 
                                                        *ngIf="column.columnDef === 'subject'" 
                                                        [subjectInfo]="row"
                                                    ></app-subject-icon>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'country'">    
                                                    <span 
                                                        *ngIf="row?.address?.country; else noCountry"
                                                        [ngClass]="'flag-icon fi fi-' + (row?.address?.countryCode | lowercase)" 
                                                        matTooltip="{{row?.address?.country}}"
                                                    ></span>
                                                    <ng-template #noCountry>n/a</ng-template>
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    {{column.cell(row)}}
                                                </ng-container>
                                            </ng-container>
                                        </td>
                                </ng-container>
                
                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div style="padding: 0 2rem;">
            <div class="d-flex ac-button-bar">
                <button mat-raised-button class="footer-btn" (click)="onCancel()">{{"buttons.cancel" | translate}}</button>
                <!-- <button mat-raised-button class="footer-btn" (click)="onBack()">Back</button> -->
                <button mat-raised-button color="primary"  class="footer-btn next-btn" (click)="onNext()" [disabled]="!nextButtonEnabled">{{"buttons.next" | translate}}</button>
            </div>
        </div>
    </div>
</ng-container>
