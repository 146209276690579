<app-header (onSelectCompany)="onSelectCompany($event)"></app-header>
<ng-container *ngIf="this.stateService.appState$ | async as appState">
	<mat-toolbar class="header-overview">
		<span class="title">{{'overview.ordersOverview' | translate}}</span>
		<ng-template [ngIf]="appState.orders.list.size > 1">
			<span style="flex: 1 1 auto;"></span>
			<input matInput [placeholder]="'filter.orderName' | translate" class="name-search" [(ngModel)]="orderNameSearchKeyword"
				(input)="onSearchChange($event)" />
			<div class="vl"></div>
			<div class="sorter">
				<input matInput readonly placeholder="Date" [matMenuTriggerFor]="sort_menu" [value]="'filter.' + selectedSort | translate">
				<button mat-stroked-button (click)="sort(selectedSortDirection === 'asc' ? 'desc' : 'asc')">
					<mat-icon>{{ selectedSortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}</mat-icon>
				</button>
			</div>
			<input matInput readonly placeholder="Status" class="status-filter" [matMenuTriggerFor]="status_menu"
			[value]="'selectedStatusDisplay' | translate">
		</ng-template>
	</mat-toolbar>

	<mat-menu #sort_menu="matMenu">
		<div class="sort-menu-width"></div>
		<button mat-menu-item *ngFor="let item of sorter" (click)="selectSort(item)">{{'filter.' + item | translate}}</button>
	</mat-menu>
	<mat-menu #status_menu="matMenu">
		<div class="status-menu-width"></div>
		<button mat-menu-item *ngFor="let item of status" (click)="selectStatus($event, item)">
			<mat-icon *ngIf="item.selected == false">check_box_outline_blank</mat-icon>
			<mat-icon *ngIf="item.selected == true">check_box</mat-icon>
			{{'filter.' + item.name | translate}}
		</button>
	</mat-menu>
	<div 
		*ngIf="!!appState.currentProfile" 
		style="
			padding: 20px 40px;
			display: flex;
			flex-direction: column;
			align-items: center;
		"
	>
		<button mat-raised-button color="primary" class="new-order-btn" (click)="onNewOrder()">
			{{ "overview.newOrder" | translate }}
		</button>

		<div class="no-orders-msg" *ngIf="appState.orders.list.size == 0">
			<img src="assets/img/no_orders.png">
			<p>Hi {{appState.currentUser?.displayName || appState.currentUser?.email}}</p>
			<div>{{"statement.sendYourLetter" | translate}}</div>
			<div>{{"statement.weTakeCare" | translate}}</div>
			<div>{{"statement.letsStartCreating" | translate}}</div>
		</div>
		<div style="width: 100%; max-width: 1140px;">
			<div *ngIf="appState.orders.list.size > 0 && isNoSearchResult()" class="no-results-found">
				<div class="no-order-message">{{"overview.noOrdersFound" | translate}}</div>
			</div>
			<ng-container *ngIf="orders.length > 0">
				<div class="order-list-container">
					<ng-container *ngFor="let order of orders; trackBy:trackByOrderId">
						<app-order-item
							[order]="order"
							[isExpandDisabled]="isExpandDisabled(order)"
							(onEditOrder)="onEditOrder($event)"
							(onViewOrderSummary)="onViewOrderSummary($event)"
							(onDeleteOrder)="onDeleteOrder($event)"
							(onAdjustOrder)="onAdjustOrder($event)"
							(onPageLimitOrder)="onPageLimitOrder($event)"
						>
						</app-order-item>
					</ng-container>
				</div>
            </ng-container>
		</div>
		<div class="footer-actions" *ngIf="orders.length > 0">
			<p>{{'overview.monitoringSummary' | translate}}</p>
			<button mat-raised-button class="footer-btn" (click)="goToMonitoring()">
				{{ "overview.monitoring" | translate }}
			</button>
		</div>
	</div>
</ng-container>