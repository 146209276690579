import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  forwardRef,
  ChangeDetectorRef,
} from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { collection, getDocs, query, where } from 'firebase/firestore';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormControl,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { first, filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { AppStateService } from '../../../../services/app-state.service';
import { AppState, PdfAnalysisPreset } from '../../../../state/app-state';
import { isArrayEmpty } from '../../utils/helper';

@Component({
  selector: 'app-pdf-analysis-preset-select',
  templateUrl: './pdf-analysis-preset-select.component.html',
  styleUrls: ['./pdf-analysis-preset-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PdfAnalysisPresetSelectComponent),
      multi: true,
    },
  ],
})
export class PdfAnalysisPresetSelectComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() control!: FormControl;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() selectDefault: boolean = true;
  @Input() useCustomField: boolean = false;
  @Input() hasDifferences: boolean = false;
  private langChangeSubscription!: Subscription;
  presets: PdfAnalysisPreset[] = [];

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(
    private firestore: Firestore,
    private translate: TranslateService,
    private stateService: AppStateService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.control.disable();
    this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
      this.changeDetectorRef.markForCheck();
    });

    this.stateService.appState$
      .pipe(
        filter((appState: AppState) => Boolean(appState.currentProfile)),
        first()
      )
      .subscribe((appState: AppState) => {
        const userProfileIds = appState.currentProfile?.id
          ? [appState.currentProfile?.id]
          : [];
        this.loadPresets(userProfileIds);
      });
  }

  async loadPresets(userProfileIds: string[]) {
    // Updated query to include 'where' clause for 'status:published'
    const getPdfAnalysisPresetsQuery = query(
      collection(this.firestore, 'pdf-analysis-presets'),
      where('status', '==', 'published') // Filtering for presets with status 'published'
    );

    try {
      const querySnapshot = await getDocs(getPdfAnalysisPresetsQuery);

      for (const doc of querySnapshot.docs) {
        const preset = { id: doc.id, ...doc.data() } as PdfAnalysisPreset;

        // Check if profileIds intersects with userProfileIds or profileIds is undefined or empty
        if (!preset.profileIds || preset.profileIds.length === 0) {
          this.presets.push({ ...preset, accessibility: 'public' });
        }

        if (
          (preset?.profileIds || []).some((id) => userProfileIds.includes(id))
        ) {
          this.presets.push({ ...preset, accessibility: 'private' });
        }
      }

      // Sort the presets by nameEn
      this.presets.sort((a, b) => a.nameEn.localeCompare(b.nameEn));

      // Try to find a private preset that's set as default.
      const privateDefaultPreset = this.presets.find(
        (preset) =>
          preset.isDefault &&
          Array.isArray(preset.profileIds) &&
          preset.profileIds.length > 0
      );
      // If a private default preset isn't found, try to find a public default preset.
      const publicDefaultPreset = this.presets.find(
        (preset) =>
          preset.isDefault &&
          (!preset.profileIds || preset.profileIds.length === 0)
      );
      // Choose the private default preset if available, otherwise choose the public one.
      const defaultPreset = privateDefaultPreset || publicDefaultPreset;
      if (this.selectDefault && defaultPreset) {
        const { accessibility, ...defaultPresetForControl } = defaultPreset;
        this.control.setValue(defaultPresetForControl);
      }
      this.control.enable();
    } catch (error) {
      this.control.enable();
      console.error('Error loading presets:', error);
    }
  }

  writeValue(value: any): void {
    if (value) {
      const { accessibility, ...valueWithoutAccessibility } = value;
      this.control.patchValue(valueWithoutAccessibility, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  comparePresets(p1: PdfAnalysisPreset, p2: PdfAnalysisPreset): boolean {
    return p1 && p2 ? p1.id === p2.id : p1 === p2;
  }

  getDisplayText(preset: PdfAnalysisPreset): string {
    if (!preset) return '';

    let translationKey: string;

    const lang = this.translate.currentLang || this.translate.getDefaultLang();
    switch (lang) {
      case 'de':
        translationKey = preset.nameDe || preset.nameEn;
        break;
      case 'en':
        translationKey = preset.nameEn;
        break;
      case 'fr':
        translationKey = preset.nameFr || preset.nameEn;
        break;
      case 'it':
        translationKey = preset.nameIt || preset.nameEn;
        break;
      default:
        translationKey = preset.nameEn;
        break;
    }

    if (translationKey) {
      return this.translate.instant(translationKey);
    }

    return '';
  }

  ngOnDestroy() {
    this.langChangeSubscription.unsubscribe();
  }
}
