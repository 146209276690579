import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { SETTINGS as AUTH_SETTINGS } from '@angular/fire/compat/auth';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxMoveableModule } from 'ngx-moveable';
import { A11yModule } from '@angular/cdk/a11y';
import { ToastrModule } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';

import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatNativeDateModule } from '@angular/material/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { HeaderComponent } from './components/header/header.component';
import { OrdersComponent } from './components/orders/orders.component';
import { PresetsComponent } from './components/presets/presets.component';
import { ToastComponent } from './components/shared/toast/toast.component';
import { OverprintComponent } from './components/overprint/overprint.component';
import { EditOrderComponent } from './components/edit-order/edit-order.component';
import { LoadingComponent } from './components/shared/components/loading.component';
import { SummaryComponent } from './components/edit-order-steps/summary/summary.component';
import { PresetStudioComponent } from './components/preset-studio/preset-studio.component';
import { ApiKeyMissingComponent } from './components/api-key-missing/api-key-missing.component';
import { CompletedComponent } from './components/edit-order-steps/completed/completed.component';
import { InvalidProfileComponent } from './components/invalid-profile/invalid-profile.component';
import { NoProfileFoundComponent } from './components/no-profile-found/no-profile-found.component';
import { DocUploadComponent } from './components/edit-order-steps/doc-upload/doc-upload.component';
import { OrderItemComponent } from './components/shared/components/order-item/order-item.component';
import { DeliveredOrdersComponent } from './components/delivered-orders/delivered-orders.component';
import { ChosenChannelComponent } from './components/shared/chosen-channel/chosen-channel.component';
import { WarningDialogComponent } from './components/shared/components/app-warning-dialog.component';
import { PDFLimitDialogComponent} from './components/shared/components/app-pdf-limit-dialog.component';
import { PresetItemComponent } from './components/shared/components/preset-item/preset-item.component';
import { SubjectIconComponent } from './components/shared/components/subject-icon/subject-icon.component';
import { OrderPresetDialogComponent } from './components/shared/components/order-preset-dialog.component';
import { AddressCheckComponent } from './components/edit-order-steps/address-check/address-check.component';
import { ChannelCheckComponent } from './components/edit-order-steps/channel-check/channel-check.component';
import { PresetEditorComponent } from './components/shared/components/preset-editor/preset-editor.component';
import { RecipientEditComponent } from './components/edit-order-steps/recipient-edit/recipient-edit.component';
import { ConfirmationDialogComponent } from './components/shared/components/app-confirmation-dialog.component';
import { SetBasePresetDialogComponent } from './components/shared/components/set-base-preset-dialog.component';
import { OrderSettingsComponent } from './components/edit-order-steps/order-settings/order-settings.component';
import { SupportedChannelsComponent } from './components/shared/supported-channels/supported-channels.component';
import { PageLimitFlagComponent } from './components/shared/components/page-limit-flag/page-limit-flag.component';
import { KlaraStatusFlagComponent } from './components/shared/components/klara-status-flag/klara-status-flag.component';
import { PresetPdfUploadComponent } from './components/preset-edit-steps/preset-pdf-upload/preset-pdf-upload.component';
import { KlaraApiKeyFormComponent } from './components/shared/components/klara-api-key-form/klara-api-key-form.component';
import { PresetEditorFormComponent } from './components/preset-edit-steps/preset-editor-form/preset-editor-form.component';
import { OrderItemMessagesComponent } from './components/shared/components/order-item-messages/order-item-messages.component';
import { OrderStepIndicatorComponent } from './components/shared/components/order-step-indicator/order-step-indicator.component';
import { AddressValidationFlagComponent } from './components/shared/components/address-validation-flag/address-validation-flag.component';
import { DeliveryTypeItemsTableComponent } from './components/shared/components/delivery-type-items-table/delivery-type-items-table.component';
import { PdfAnalysisPresetSelectComponent } from './components/shared/components/pdf-analysis-preset-select/pdf-analysis-preset-select.component';
import { PdfProcessIndicatorComponent } from './components/shared/components/pdf-analysis-process-indicator/pdf-analysis-process-indicator.component';
import { OrderNameSubjectSubHeaderComponent } from './components/shared/components/order-name-subject-sub-header/order-name-subject-sub-header.component';
import { PdfResultsProcessIndicatorComponent } from './components/shared/components/pdf-results-process-indicator/pdf-results-process-indicator.component';

import { NetworkService } from 'src/app/services/network.service';
import { OrderStatusPipe } from './components/shared/pipes/order-status.pipe';
import { DateConversionInterceptor } from './core/interceptors/date-conversion.interceptor';
import { HoverPopoverDirective } from './components/shared/directives/hover-popover.directive';
import { CustomTooltipDirective } from './components/shared/directives/custom-tooltip.directive';
import { EllipsisTooltipDirective } from './components/shared/directives/ellipsis-tooltip.directive';
import { CalculatePdfResultsProgressPipe } from './components/shared/pipes/order-pdf-results-progress.pipe';
import { CalculatePdfAnalysisProgressPipe } from './components/shared/pipes/order-pdf-analysis-progress.pipe';

import { AppRoutingModule } from './app-routing.module';
import { ApiKeyGuard } from './components/shared/guard/api-key.guard';
import { OrderStatusGuard } from './components/shared/guard/order-status.guard';
import { ProfileIdGuard } from './components/shared/guard/profile-id.guard';

import { environment } from '../environments/environment';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// Function to check the network status during app initialization
export function checkNetworkStatus(networkService: NetworkService) {
  return () => firstValueFrom(networkService.isOnline$);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ToastComponent,
    ToastComponent,
    LogoutComponent,
    HeaderComponent,
    OrdersComponent,
    OrderStatusPipe,
    SummaryComponent,
    LoadingComponent,
    PresetsComponent,
    PresetsComponent,
    EditOrderComponent,
    DocUploadComponent,
    OverprintComponent,
    CompletedComponent,
    OrderItemComponent,
    PresetItemComponent,
    PresetItemComponent,
    SubjectIconComponent,
    AddressCheckComponent,
    ChannelCheckComponent,
    HoverPopoverDirective,
    PresetStudioComponent,
    PresetEditorComponent,
    RecipientEditComponent,
    CustomTooltipDirective,
    ChosenChannelComponent,
    WarningDialogComponent,
    ApiKeyMissingComponent,
    PageLimitFlagComponent,
    OrderSettingsComponent,
    InvalidProfileComponent,
    NoProfileFoundComponent,
    InvalidProfileComponent,
    NoProfileFoundComponent,
    PDFLimitDialogComponent,
    KlaraApiKeyFormComponent,
    EllipsisTooltipDirective,
    KlaraStatusFlagComponent,
    PresetPdfUploadComponent,
    DeliveredOrdersComponent,
    PresetEditorFormComponent,
    SupportedChannelsComponent,
    OrderItemMessagesComponent,
    OrderPresetDialogComponent,
    OrderStepIndicatorComponent,
    ConfirmationDialogComponent,
    SetBasePresetDialogComponent,
    PdfProcessIndicatorComponent,
    AddressValidationFlagComponent,
    CalculatePdfResultsProgressPipe,
    DeliveryTypeItemsTableComponent,
    PdfAnalysisPresetSelectComponent,
    CalculatePdfAnalysisProgressPipe,
    OrderNameSubjectSubHeaderComponent,
    PdfResultsProcessIndicatorComponent,
  ],
  imports: [
    A11yModule,
    FormsModule,
    BrowserModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatMenuModule,
    MatSortModule,
    MatTabsModule,
    MatInputModule,
    MatRadioModule,
    MatChipsModule,
    MatTableModule,
    MatButtonModule,
    MatSelectModule,
    MatDialogModule,
    AppRoutingModule,
    HttpClientModule,
    MatToolbarModule,
    MatSidenavModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatGridListModule,
    NgxMoveableModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    A11yModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatSelectCountryModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),
    MatSelectCountryModule.forRoot('de'),
    provideFirestore(() => getFirestore()),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: checkNetworkStatus,
      deps: [NetworkService],
      multi: true,
    },
    {
      provide: AUTH_SETTINGS,
      useValue: { appVerificationDisabledForTesting: true },
    },
    // https://stackoverflow.com/a/71445543/1345947
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateConversionInterceptor,
      multi: true,
    },
    {
      provide: MatDialogRef,
      useValue: {},
    },
    OrderStatusGuard,
    ProfileIdGuard,
    ApiKeyGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
