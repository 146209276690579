<div class="process-loader-indicator">
	<div class="step-container" *ngIf="showStepBar">
		<div *ngFor="let color of stepColor; let last = last" class="step" [ngClass]="{'blinking': color === 'orange'}"
			[ngStyle]="{'background-color': color, 'width': getStatusWidth(status), 'margin-right': last ? '0' : '5px'}"></div>
	</div>
	<mat-progress-bar mode="determinate" [value]="progressPercentage"></mat-progress-bar>
	<div class="status-text" *ngIf="showStepBar">
		<ng-container *ngIf="status === 'CHANNEL DETERMINATION'">{{ 'status.Channeling' | translate }}</ng-container>
		<ng-container *ngIf="status === 'PDF SPLITTING'">{{ 'status.PdfSplitting' | translate }}</ng-container>
		<ng-container *ngIf="status === 'MESSAGE PRODUCTION'">{{ 'stage.MsgProduction' | translate }}</ng-container>
	</div>
	<div class="progress-count">
		<ng-container *ngIf="status === 'PDF SPLITTING'">
			<span>PDFs {{ currentMessageNum }} of {{ numMessages }}</span>
		</ng-container>
		<ng-container *ngIf="status !== 'PDF SPLITTING'">
			<span>{{ 'table.messages' | translate }} {{ currentMessageNum }} of {{ numMessages }}</span>
		</ng-container>
	</div>
	<div class="empty"></div>
</div>