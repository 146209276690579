import { Timestamp } from '@angular/fire/firestore';
import { KlaraDeliveriesResult } from 'functions/src/domain/models/orders-deliveries.model';

export type AppState = {
  isNavExpanded: boolean;
  isLogout: boolean;
  isLoggingIn: boolean;
  currentUser: firebase.default.User | null;
  currentProfile: ProfileType | null;
  currentEditOrder: OrderType | null;
  currentMainDocId: string | null;
  currentRecipient: AddressValidationResult | null;
  currentPreset: PdfAnalysisPreset | null;
  isCreatingOrder: boolean;
  isLoadingOrder: boolean;
  isLoadingPreset: boolean;
  uploadingMainDoc: boolean;
  uploadingAttachmentDoc: boolean;
  attachmentPdf: PdfAttachment | null;
  profiles: ProfilesType;
  profileId: string | null;
  orders: OrdersType;
  presets: PresetsType;
};

export type ProfilesType = {
  isLoading: boolean;
  isLoaded: boolean;
  list: ProfileType[];
};

export type OrdersType = {
  isLoading: boolean;
  isLoaded: boolean;
  list: Map<string, OrderType>;
  listDone: Map<string, OrderType>;
};

export type PresetsType = {
  isLoading: boolean;
  isLoaded: boolean;
  list: Map<string, PdfAnalysisPreset>;
  listPublic: PdfAnalysisPreset[];
};

export type ProfileType = {
  id: string;
  tenantId: string;
  companyId: string;
  name: string;
  companyName: string;
  klaraApiKey: string;
};

export type PdfMain = {
  mockedDocId: string;
  thumbnailAsset: string;
  displayFilename: string;
  displayDate: Timestamp;
  recipients: RecipientInfo[];
};

export type PdfAttachment = {
  thumbnailAsset: string;
  displayFilename: string;
  displayDate: Date;
};

export type OrderStatus =
  | 'Draft'
  | 'Submitted'
  | 'Processing'
  | 'Done'
  | 'PDFAnalysis'
  | 'ChannelDetermination'
  | 'PdfSplitting'
  | 'MessageProduction'
  | 'InProduction'
  | 'Error'
  | 'PDFMismatch'
  | 'PDFPageLimit'
export type PdfAnalysisStages =
  | 'WAITING'
  | 'PREPARING'
  | 'DETECTING'
  | 'SEGREGATING'
  | 'VALIDATING'
  | 'DONE';
export type CurrentEditStep =
  | 'order-basics'
  | 'doc-upload'
  | 'address-check'
  | 'channel-check'
  | 'summary'
  | 'completed';
export type OrderType = {
  schemaVersion: number;
  id: string;
  userId: string;
  userLoginSnapshot: string;
  profileId: string;
  ePostToken: {
    body: {
      grant_type: string;
      refresh_token: string;
      client_id: string;
      client_secret: string;
    }
    url: string;
  } | null | undefined;
  status: OrderStatus;
  createdAt: Timestamp;
  modifiedAt: Timestamp;
  name: string;
  numRecipients: number;
  amountCents: number;
  currentEditStep: CurrentEditStep;
  subject: string;
  pdfAnalysisResults?: PdfAnalysisResult[];
  deliveryTypeLineItems?: DeliveryTypeLineItem[];
  pdfAnalysisStatus?: {
    preparingIsFileDownloaded: boolean;
    stage: PdfAnalysisStages;
    preparingDocumentCurrentNum: number;
    preparingDocumentCount: number;
    detectingCurrentPageNum: number;
    detectingNumPages: number;
    segregatingCurrentPageNum: number;
    segregatingNumPages: number;
    validationCurrentMessageNum: number;
    validationNumMessages: number;
  };
  channelDeterminationStatus?: {
    numMessages: number;
    currentMessageNum: number;
  };
  orderDeliveryStepStatus?: {
    numMessages: number;
    currentMessageNum: number;
  };
  allowedChannels: {
    isEpostAllowed: boolean;
    isPrintAllowed: boolean;
    isEmailAllowed: boolean;
  };
  messagePdfs?: OrderFile[];
  attachmentPdfs?: OrderFile[];
  pdfAnalysisPresetSnapshot: PdfAnalysisPreset;
  docType: 'invoice' | 'reminder' | 'message' | 'contract';
  postalDeliveryTypeDomestic:
    | 'b-post'
    | 'b-post mass'
    | 'a-post'
    | 'a-post plus'
    | 'registered';
  postalDeliveryTypeInternational: 'standard' | 'express';
  printMetadata: {
    color: 'bw' | 'color';
    printType: 'duplex' | 'simplex';
  };
  ebillDueDate: Timestamp | null;
  tempSelectedAddressValidationStrategy: 'google' | 'eirene';
  pdfViolations: pdfViolationsFlag[];
  klaraDeliveryId: KlaraDeliveriesResult['deliveryId'];
};

export type OrderFile = {
  createdAt: Timestamp;
  id: string;
  filename: string;
  fileType: 'pdf' | 'attachment';
  name: string;
  type: string;
  attachmentType?: 'prepend' | 'append';
  processType?: 'single' | 'multiple';
  errorMessage?: string;
  pageCount: number;
};

export type DeliveryTypeLineItem = {
  selectedChannel: string | 'ePost' | 'email' | 'print' | 'n/a';
  print: string;
  envelope: string;
  priceCents: number;
  typeCount: number;
  total: number;
};

export type AddressValidationFlag = {
  code: string;
  message: string;
};

export type Country = {
  name: string;
  alpha2Code: string;
  alpha3Code: string;
  numericCode: string;
  callingCode: string;
};

export type Address = {
  recipient: string;
  street: string;
  number?: string;
  streetNo?: string;
  streetSubNo?: string;
  zip: string;
  city: string;
  country?: string;
  countryCode?: string;
};

type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};
export type NullableAddress = Nullable<Address>;

/**
 * @deprecated
 */
export type RecipientInfo = {
  id: string;
  address: Nullable<Address>;
  overrideRecipientDetails: RecipientDetails | null;
  validationErrors: AddressValidationFlag[];
  channel: string;
};

export type ComputedOrders = {
  deliveryItems: DeliveryTypeLineItem[] | null;
  totalRecipients: number;
};

export type PdfAnalysisProgress = {
  analysisStatus: PdfAnalysisStages;
  prepared: boolean;
  preparingProgressPercentage: number;
  detectingProgressPercentage: number;
  segregatingProgressPercentage: number;
  validatingProgressPercentage: number;
};

// PDF Analysis

export type PdfAnalysisPage = {
  number: number;
  swissQrCode: {
    hasSwissQrCode: boolean;
    rawContent: string;
    swissQRBill: {
      amount: number;
      reference: string;
    };
  };
  windows: {
    left: {
      validationLevel1: boolean;
      rawContent: string;
    };
    right: {};
  };
};

export type PdfAnalysisMessageEmailValidation = {
  domain: {
    free: string;
    disposable: string;
    acceptAll: string;
    name: string;
  };
  dns: {
    record: string;
    type: string;
  };
  email: string;
  account: {
    disabled: string;
    role: string;
    fullMailbox: string;
  };
  status: string;
  reason: string;
  provider: string;
};

export type PdfAnalysisMessageValidationLevel2 = {
  result: {
    address: {
      postalAddress: {
        regionCode: string;
        postalCode: string;
        locality: string;
        addressLines: string[];
        languageCode: string;
      };
      formattedAddress: string;
      unconfirmedComponentTypes: string[];
      addressComponents: {
        componentType: string;
        confirmationLevel:
          | 'UNCONFIRMED_BUT_PLAUSIBLE'
          | 'CONFIRMED'
          | 'CONFIRMATION_LEVEL_UNSPECIFIED'
          | 'UNCONFIRMED_AND_SUSPICIOUS';
        componentName: {
          text: string;
          languageCode?: string;
        };
        inferred?: boolean;
      }[];
    };
    verdict: {
      hasUnconfirmedComponents: boolean;
      validationGranularity: string;
      geocodeGranularity: string;
      inputGranularity: string;
      hasInferredComponents: boolean;
      addressComplete: boolean;
    };
    geocode: {
      plusCode: {
        globalCode: string;
      };
      featureSizeMeters: number;
      bounds: {
        high: {
          latitude: number;
          longitude: number;
        };
        low: {
          latitude: number;
          longitude: number;
        };
      };
      placeId: string;
      location: {
        latitude: number;
        longitude: number;
      };
      placeTypes: string[];
    };
  };
  responseId: string;
};

export type PdfAnalysisMessage = {
  id: string;
  docId: string;
  numPages: number;
  startsAtPage: number;
  qrInvoicePageNumber: number;
  selectedChannel: string | 'ePost' | 'email' | 'print' | 'n/a';
  supportedChannels: {
    ePost?: { participantId: string };
    print: { default: true };
    email?: { emailAddress: string };
  };
  isDeleted?: boolean;
  addressValidationResults: PdfAnalysisMessageValidationLevel2;
  overrideAddressValidationResults?: AddressValidationResultsModel;
  emailValidationApiResults?: PdfAnalysisMessageEmailValidation;
  overrideEmailValidationApiResults?: PdfAnalysisMessageEmailValidation;
  recipientDetails: RecipientDetails;
  overrideRecipientDetails: RecipientDetails;
  overrideSubject?: string;
  overrideDocType?: OrderType['docType'];
  overridePostalDeliveryTypeDomestic?: OrderType['postalDeliveryTypeDomestic'];
  overridePostalDeliveryTypeInternational?: OrderType['postalDeliveryTypeInternational'];
  overridePrintMetadata?: OrderType['printMetadata'];
  isSwissAddress: boolean;
  suggestedRecipientDetails: RecipientDetails;
  addressValidationResult: AddressValidityDetails;
  overrideAddressValidationResult: AddressValidityDetails;
  additionalBlankPage: boolean;
  isEdited: boolean;
  klaraDeliveryStatus?: string | 'FAILED' | 'DELIVERED';
  klaraDeliveryStatusMessage?: string;
  attachmentNumPages: number;
  qstatRelocationFlag: boolean;
  envelopeSize: 'C4' | 'C5' | 'n/a';
};

export type PdfAnalysisResult = {
  id: string;
  numPages: number;
  startsAtPage: number;
  pages: PdfAnalysisPage[];
  messages: PdfAnalysisMessage[];
};

export type AddressValidationResult = {
  messageId: string;
  docId: string;
  numPages: number;
  startsAtPage: number;
  qrInvoicePageNumber: number;
  address: Address;
  validationErrors: AddressValidationFlag[];
  validationWarnings: AddressValidationFlag[];
  emailInvalidWarning: string;
  overrideRecipientDetails?: RecipientDetails;
  fileUrls?: string[];
  selectedChannel?: string | 'ePost' | 'email' | 'print' | 'n/a';
  supportedChannels?: {
    ePost?: { participantId: string };
    print: { default: true };
    email?: { emailAddress: string };
  };
  email?: string;
  // QR code based.
  amount?: number;
  reference?: string;
  isEmailValid?: boolean;
  subject: string;
  isSubjectOverride: boolean;
  docType: OrderType['docType'];
  postalDeliveryTypeDomestic: OrderType['postalDeliveryTypeDomestic'];
  postalDeliveryTypeInternational: OrderType['postalDeliveryTypeInternational'];
  printMetadata: OrderType['printMetadata'];
  isSwissAddress: boolean;
  suggestedAddress: AddressValidation;
  validationEngine: 'google' | 'eirene';
  additionalBlankPage: boolean;
  isEdited: boolean;
  validationLevel?: number;
  klaraDeliveryStatus?: string | 'FAILED' | 'DELIVERED';
  klaraDeliveryStatusMessage?: string;
  error?: {
    title: string;
    message: string;
  };
  attachmentNumPages: number;
  qstatRelocationFlag: boolean;
  envelopeSize: 'C4' | 'C5' | 'n/a';
};

export type RecipientDetails = {
  address: Address;
  email?: {
    emailAddress: string;
    isEmailValid: boolean;
  };
};

export type RecipientValidationRequest = {
  orderId: string;
  docId: string;
  messageId: string;
  recipientDetails: RecipientDetails;
};

type EmailAddressValidationModelStatus =
  | 'deliverable'
  | 'risky'
  | 'undeliverable'
  | 'unknown';
export type EmailAddressValidationModel = {
  email: string;
  status: EmailAddressValidationModelStatus;
  reason: string;
  domain?: EmailAddressDomainModel;
  account?: EmailAddressAccountModel;
  dns?: EmailAddressDNSModel;
  provider?: string;
};

type EmailAddressDomainModel = {
  name: string;
  acceptAll: string;
  disposable: string;
  free: string;
};

type EmailAddressAccountModel = {
  role: string;
  disabled: string;
  fullMailbox: string;
};

type EmailAddressDNSModel = {
  type: string;
  record: string;
};

type AddressValidationApiResults = { eirene: string; google: string };

export type RecipientValidationResponse = {
  emailValidationApiResults: EmailAddressValidationModel;
  recipientDetailsValidationResult: RecipientDetailsValidationResult;
  overrideAddressValidationApiResults: AddressValidationApiResults;
};

export type AddressValidationResultsModel = {
  address: {
    postalAddress: {
      regionCode: string;
      postalCode: string;
      locality: string;
      addressLines: string[];
      languageCode: string;
    };
    formattedAddress: string;
    unconfirmedComponentTypes: string[];
    addressComponents: {
      componentType: string;
      confirmationLevel: 'UNCONFIRMED_BUT_PLAUSIBLE' | 'CONFIRMED';
      componentName: {
        text: string;
        languageCode?: string;
      };
      inferred?: boolean;
    }[];
  };
  verdict: {
    hasUnconfirmedComponents: boolean;
    validationGranularity: string;
    geocodeGranularity: string;
    inputGranularity: string;
    hasInferredComponents: boolean;
    addressComplete: boolean;
  };
  geocode: {
    plusCode: {
      globalCode: string;
    };
    featureSizeMeters: number;
    bounds: {
      high: {
        latitude: number;
        longitude: number;
      };
      low: {
        latitude: number;
        longitude: number;
      };
    };
    placeId: string;
    location: {
      latitude: number;
      longitude: number;
    };
    placeTypes: string[];
  };
};

export type PdfAnalysisPreset = {
  id: string;
  nameDe: string;
  nameEn: string;
  nameFr: string;
  nameIt: string;
  requiresBlankFirstPage: boolean;
  isDefault: boolean;
  postalAddressBoxLocation: {
    topLeftX: number;
    topLeftY: number;
    width: number;
    height: number;
  };
  emailAddressBoxLocation: {
    topLeftX: number;
    topLeftY: number;
    width: number;
    height: number;
  };
  mobileNumberBoxLocation: {
    topLeftX: number;
    topLeftY: number;
    width: number;
    height: number;
  };
  whiteLineBoxLocation: {
    topLeftX: number;
    topLeftY: number;
    width: number;
    height: number;
  };
  profileIds: string[];
  status: PresetStatus;
  createdAt: Timestamp;
  createdBy: string;
  modifiedAt: Timestamp;
  modifiedBy: string;
  accessibility?: 'public' | 'private';
  filename?: string;
  subject: string;
  docType: 'invoice' | 'reminder' | 'message' | 'contract';
  isAddressValidationRequired: boolean;
  processType: 'single' | 'multiple';
  ebillDueDate: DueDate | null;
  emailTemplate: string;
  emailSubject: string;
  emailAdditionalInfo: {
    from: {
      name: string;
      email: string;
    };
    replyTo: {
      name: string;
      email: string;
    };
    cc: {
      name: string;
      email: string;
    };
    bcc: {
      name: string;
      email: string;
    };
  };
  postalDeliveryTypeDomestic:
    | 'b-post'
    | 'b-post mass'
    | 'a-post'
    | 'a-post plus'
    | 'registered';
  postalDeliveryTypeInternational: 'standard' | 'express';
  printMetadata: {
    color: 'bw' | 'color';
    printType: 'duplex' | 'simplex';
  };
  deleteType: 'pdf' | 'jpg' | 'both';
  orderName: string;
  orderNameDateVariable: boolean;
  orderNameTimeVariable: boolean;
  orderSubjectDateVariable: boolean;
  orderSubjectTimeVariable: boolean;
};

export type PresetStatus =
  | 'draft'
  | 'generating'
  | 'processed'
  | 'published'
  | 'error';

export type OrderLevelToMsgProps = {
  subject: OrderType['subject'];
  docType: OrderType['docType'];
  postalDeliveryTypeDomestic: OrderType['postalDeliveryTypeDomestic'];
  postalDeliveryTypeInternational: OrderType['postalDeliveryTypeInternational'];
  printMetadata: OrderType['printMetadata'];
};

export enum LoadingState {
  OrdersLoading = 'orders',
  OrderLoading = 'order',
  OrderCreating = 'creating',
  ProfilesLoading = 'profiles',
  PresetsLoading = 'presets',
  PresetLoading = 'preset',
  LoginLoading = 'login',
}

export type ValidityLevel = 'error' | 'warning' | 'ok' | 'n/a';

export type addressValidationField =
  | 'recipient'
  | 'street'
  | 'streetNo'
  | 'streetSubNo'
  | 'zip'
  | 'city'
  | 'countryCode';

export type AddressValidityDetails = {
  validationEngine: 'google' | 'eirene';
  validationLevel?: number;
  recipient?: {
    validityLevel: ValidityLevel;
    validityMessage: string;
    suggestedCorrection: string;
    excessText?: string;
  };
  street?: {
    validityLevel: ValidityLevel;
    validityMessage: string;
    suggestedCorrection: string;
    excessText?: string;
  };
  streetNo?: {
    validityLevel: ValidityLevel;
    validityMessage: string;
    suggestedCorrection: string;
    excessText?: string;
  };
  streetSubNo?: {
    validityLevel: ValidityLevel;
    validityMessage: string;
    suggestedCorrection: string;
    excessText?: string;
  };
  zip?: {
    validityLevel: ValidityLevel;
    validityMessage: string;
    suggestedCorrection: string;
    excessText?: string;
  };
  city?: {
    validityLevel: ValidityLevel;
    validityMessage: string;
    suggestedCorrection: string;
    excessText?: string;
  };
  countryCode?: {
    validityLevel: ValidityLevel;
    validityMessage: string;
    suggestedCorrection: string;
    excessText?: string;
  };
  error?: {
    title: string;
    message: string;
  };
};

export type AddressValidation = {
  recipient?: string;
  street?: string;
  excessStreetText?: string;
  streetNo?: string;
  streetSubNo?: string;
  zip?: string;
  city?: string;
  countryCode?: string;
};

export type RecipientDetailsValidationResult = {
  address: AddressValidityDetails;
  email?: {
    emailAddress: string;
    isEmailValid: boolean;
  };
};

export type KeycloakTokens = {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
  id_token: string;
  not_before_policy: number;
  session_state: string;
  scope: string;
  receivedAt: number,
  additionalInfo: {
    uid: string;
    sid: string;
    tenantId: string;
    companyId: string;
    preferredUsername: string;
    email: string;
    name: string;
    givenName: string;
    familyName: string;
    emailVerified: string;
  };
};

 export type pdfViolationsFlag = {
    id: string;
    name: string;
    pageCount: number;
};

export type TenantToken = KeycloakTokens & {
  profile: ProfileType;
};

export type DueDate = {
  type: 'dynamic' | 'fixed';
  value: number | Timestamp;
};