import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Timestamp } from '@angular/fire/firestore';
import { MatSort } from '@angular/material/sort';
import { switchMap, Subscription } from 'rxjs';

import { ConfirmationDialogComponent } from '../../shared/components/app-confirmation-dialog.component';
import { PdfAnalysisResultService } from '../../../services/pdf-analysis-result.service';
import { RecipientEditComponent } from '../recipient-edit/recipient-edit.component';
import { extractAddressInformation } from './../../shared/utils/address-utils';
import { AppStateService } from '../../../services/app-state.service';
import { StorageService } from '../../../services/storage.service';
import { SessionService } from '../../../services/session.service';
import { OrderService } from '../../../services/order.service';
import { isObjectEmpty } from './../../shared/utils/helper';

import {
  Address,
  AppState,
  OrderType,
  LoadingState,
  PdfAnalysisPage,
  PdfAnalysisResult,
  PdfAnalysisMessage,
  OrderLevelToMsgProps,
  AddressValidationResult,
} from '../../../state/app-state';

interface RecipientInfo {
  email: string;
  recipient: string;
}

interface InvoiceInfo {
  amount: string;
  reference: string;
}

@Component({
  selector: 'app-address-check',
  templateUrl: './address-check.component.html',
  styleUrls: ['./address-check.component.scss'],
})
export class AddressCheckComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  // TODO changes pipe
  isRecipientType(item: any): item is RecipientInfo {
    return (
      item && typeof item === 'object' && 'recipient' in item && 'email' in item
    );
  }
  isInvoiceType(item: any): item is InvoiceInfo {
    return (
      item &&
      typeof item === 'object' &&
      'amount' in item &&
      'reference' in item
    );
  }

  columns = [
    {
      columnDef: 'image',
      header: '',
      cell: (element: AddressValidationResult) =>
        this.thumbnailsMap[
          `${element.docId}-${element.startsAtPage}_thumb.jpg`
        ],
      cellClass: '',
      sortable: false,
    },
    {
      columnDef: 'recipient',
      header: 'Recipients',
      cell: (element: AddressValidationResult): RecipientInfo => {
        const email = element.email != undefined ? element.email : null;
        const recipient = element?.address?.recipient;
        return { email: email ?? '', recipient };
      },
      cellClass: '',
      sortable: true,
    },
    {
      columnDef: 'subject',
      header: 'Subject',
      cell: (element: AddressValidationResult) => {
        return {
          subject: element.subject,
          isSubjectOverride: element.isSubjectOverride 
        };
      },
      cellClass: '',
      sortable: false,
    },
    {
      columnDef: 'invoice-info',
      header: 'Invoice Info',
      cell: (element: AddressValidationResult): InvoiceInfo => {
        const amount =
          element.amount != undefined
            ? 'CHF ' + (Math.round(element.amount * 100) / 100).toFixed(2)
            : 'n/a';
        const reference = element.reference != undefined ? element.reference : 'n/a';
        return { amount, reference };
      },
      cellClass: '',
      sortable: true,
    },
    {
      columnDef: 'doc-type',
      header: 'Doc Type',
      cell: (element: AddressValidationResult) => {
        const translatedDocType = this.translate.instant(`pages.addressCheck.editRecipient.${element.docType}`);
        return translatedDocType  || '';
      },
      cellClass: '',
      sortable: true,
    },
    {
      columnDef: 'pages',
      header: 'Pages',
      cell: (element: AddressValidationResult) =>
        !element.additionalBlankPage
          ? element.numPages + element.attachmentNumPages
          : element.numPages + 1 + element.attachmentNumPages,
      cellClass: 'cell-pages',
      sortable: true,
    },
    {
      columnDef: 'delivery-type',
      header: 'Delivery Type',
      cell: (element: AddressValidationResult) => {
        const deliveryTypeMap = {
          'b-post': 'B-Post',
          'b-post mass': 'B-Post Mass',
          'a-post': 'A-Post',
          'a-post plus': 'A-Post Plus',
          'registered': 'Registered',
        }
        let localDelivery = '';
        if (['b-post', 'a-post'].includes(element?.postalDeliveryTypeDomestic || '')) {
          localDelivery = deliveryTypeMap[element.postalDeliveryTypeDomestic];
        } else {
          localDelivery = 'B-Post'
        }

        return !element.isSwissAddress
          ? `${this.translate.instant('table.deliveryTypeInternational')} Standard`
          : `${this.translate.instant('table.deliveryTypeDomestic')} ${localDelivery}`;
      },
      cellClass: '',
      sortable: true,
    },
    {
      columnDef: 'country',
      header: 'Country',
      cell: (element: AddressValidationResult) => element,
      cellClass: 'cell-pages',
      sortable: true,
    },
    {
      columnDef: 'addressCheck',
      header: 'Address Check',
      cell: (element: AddressValidationResult) => element,
      cellClass: '',
      sortable: false,
    },
    {
      columnDef: 'action',
      header: 'Action',
      cell: (element: Address) => ``,
      cellClass: '',
      sortable: false,
    },
  ];
  displayedColumns = this.columns.map((c) => c.columnDef);

  // TODO appState refactoring
  dataSource = new MatTableDataSource([] as AddressValidationResult[]);
  private subscriptions = new Subscription();
  private currentState!: AppState;
  private profileId: string = '';
  private prevCurrentEditOrderString: string = '';
  private currentEditOrder!: OrderType;
  thumbnailsMap: { [key: string]: string } = {};
  imageLoaded: { [key: string]: boolean } = {};

  onImageLoad(row: any) {
    this.imageLoaded[row] = true;
  }

  // commented-out logic on this method might be useful later on
  get nextButtonEnabled() {
    // Update on override address.
    // return this.dataSource?.data?.every(
    //   ({ validationErrors, overrideRecipientDetails }) => {
    //     const withOverrideAddress = !!overrideRecipientDetails?.address;
    //     const withValidationErrors = validationErrors.length > 0;

    //     return !withValidationErrors || withOverrideAddress;
    //   }
    // );
    // return this.dataSource?.data?.length

    // disable next if one of the message has error
    return this.dataSource?.data?.every(
      ({ error }) => {
        return !error;
      }
    );
  }

  constructor(
    private router: Router,
    private dialog: MatDialog,
    public route: ActivatedRoute,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public pdfAnalysisResultService: PdfAnalysisResultService,
    private sessionService: SessionService,
    public storageService: StorageService,
    public stateService: AppStateService,
    public orderService: OrderService,
    private translate: TranslateService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.subscriptions.add(
      this.route.parent?.paramMap.subscribe(async (params) => {
        const orderId = params.get('orderId');
        if (orderId) {
          this.stateService.startLoading(LoadingState.OrderLoading);
          await this.orderService
            .getOrderWithPdfAnalysisResults(orderId)
            .then(() => {
              this.stateService.stopLoading(LoadingState.OrderLoading);
            });
        } else {
          console.error('Order ID is not available.');
        }
      })
    );

    this.subscriptions.add(
      this.stateService.appState$.subscribe(async (appState: AppState) => {
        this.currentState = appState;
        const currentEditOrderStringify = JSON.stringify(appState.currentEditOrder);
        if (
          !isObjectEmpty(appState.currentEditOrder) &&
          this.prevCurrentEditOrderString !== currentEditOrderStringify
        ) {
          this.prevCurrentEditOrderString = currentEditOrderStringify;
          this.currentEditOrder = appState.currentEditOrder!;
          this.updateDataSource();
          await this.getPdfAnalysisResMsgsThumb()
        }
        if (
          !isObjectEmpty(appState.currentProfile) &&
          this.profileId !== appState?.currentProfile?.id
        ) {
          this.profileId = appState.currentProfile!.id;
        }
      })
    );
  }

  updateDataSource() {
    this.dataSource = new MatTableDataSource(this.getAllAddresses);
    this.dataSource.sortingDataAccessor = (
      item: AddressValidationResult,
      property: string
    ): string | number => {
      if (property === 'pages') {
        return item.numPages;
      }

      const prop = property as keyof Address;
      const address = item.address as Address;
      if (prop === 'country') {
        return address.country ?? '';
      }

      return address[prop] ?? '';
    };
    this.dataSource.sort = this.sort;

    this.dataSource.filterPredicate = (
      item: AddressValidationResult,
      filter: string
    ) => {
      const withValidationErrors = item.validationErrors.length > 0;
      const withInvalidEmail = item.emailInvalidWarning.length > 0;
      const withWarnings = item.validationWarnings.length > 0;
      
      switch (filter) {
        default:
          return true;
        case 'withIssues':
          return (withValidationErrors || withInvalidEmail || withWarnings);
        case 'withoutIssues':
          return !(withValidationErrors || withInvalidEmail  || withWarnings);
      }
    };

    // Set filter.
    this.dataSource.filter = this.currentFilterGroup;
  }

  onCancel() {
    this.router.navigate([this.profileId, 'orders']);
  }

  async onBack() {
    this.orderService.updateCurrentOrderEditStep('doc-upload');
    this.router.navigate(['../doc-upload'], { relativeTo: this.route });
  }

  async onNext() {
    this.stateService.startLoading(LoadingState.OrderLoading);
    const ePostToken = await this.sessionService.getSessionEpostToken(this.currentState?.currentProfile?.tenantId || '');
    const updatedOrderType: Partial<OrderType> = {
      status: 'ChannelDetermination',
      ePostToken
    };
    await this.orderService.updateOrder(updatedOrderType).then(() => {
      this.stateService.stopLoading(LoadingState.OrderLoading);
      this.router.navigate([this.profileId, 'orders']);
    });
  }

  async onEditRow(addressValidationResult: AddressValidationResult) {
    this.stateService.startLoading(LoadingState.OrderLoading);
    const { numPages, startsAtPage, docId } = addressValidationResult;
    const filenames = Array.from({ length: numPages }, (_, index) => {
      const pageNumber = startsAtPage + index;
      return `${pageNumber}.jpg`;
    });
    //added docId to avoid error (not displaying the thumbnail when edit for the first time).
    const fileUrls = await this.storageService.getImageUrls(filenames, docId);

    // Set the current recipient info in AppState.
    const currentRecipient = {
      ...addressValidationResult,
      fileUrls,
    };

    const dialogRef = this.dialog.open(RecipientEditComponent, {
      panelClass: 'fullscreen-dialog',
      enterAnimationDuration: 500,
    });
    this.stateService.updateState({
      ...this.currentState,
      currentRecipient,
      isLoadingOrder: false,
    });

    dialogRef.afterClosed().subscribe((_) => {
      // Update the data set.
      this.updateDataSource();
    });
  }

  // Improvement: Refactor same logic as recipient edit.
  onDeleteRow(addressValidationResult: AddressValidationResult) {
    const proceedRemoval = (confirmed: boolean) => {
      this.stateService.startLoading(LoadingState.OrderLoading);
      if (!confirmed) {
        this.stateService.stopLoading(LoadingState.OrderLoading);
        return Promise.resolve(true);
      }
      const { docId, messageId } = addressValidationResult || {};

      return this.pdfAnalysisResultService.deleteMessage(docId, messageId).then((_) => {
        this.stateService.stopLoading(LoadingState.OrderLoading);
        return true;
      });
    };

    let confirmationMessage = '';
    this.translate.get('pages.addressCheck.editRecipient.confirmationRemove').subscribe((res: string) => {
      confirmationMessage = res;
    });

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: { message: confirmationMessage },
    });

    dialogRef.afterClosed().pipe(switchMap(proceedRemoval)).subscribe();
  }

  // Holds the reference of filter group.
  private currentFilterGroup = 'all';

  applyFilter(event: any) {
    this.dataSource.filter = event.value;

    // Make a local copy of the filter group.
    this.currentFilterGroup = this.dataSource.filter;
  }

  get getAllAddresses(): AddressValidationResult[] {
    const {
      pdfAnalysisResults: orderPdfAnalysisResults,
      subject,
      docType,
      postalDeliveryTypeDomestic,
      printMetadata,
    } = this.currentEditOrder || {};
    const pdfAnalysisResults = orderPdfAnalysisResults as PdfAnalysisResult[];
    const orderLevelToMsgProps = {
      subject,
      docType,
      postalDeliveryTypeDomestic,
      printMetadata,
    } as OrderLevelToMsgProps;
    
    const extractQrInfo = (
      messages: PdfAnalysisMessage[],
      pages: PdfAnalysisPage[]
    ): { messageId: string; reference: string; amount: number }[] => {
      const lastPageNumberOfMessages = messages.map(
        ({ id, numPages, startsAtPage, qrInvoicePageNumber }) => ({
          id,
          lastPage: qrInvoicePageNumber || (startsAtPage + numPages - 1),
        })
      );

      const lastPageOfMessagesMap = new Map(
        lastPageNumberOfMessages.map((x) => [x.lastPage, x])
      );

      return pages.flatMap((page) => {
        if (!lastPageOfMessagesMap.has(page.number)) {
          return [];
        } else {
          return [
            {
              messageId: lastPageOfMessagesMap.get(page.number)!.id,
              reference: page.swissQrCode?.swissQRBill?.reference,
              amount: page.swissQrCode?.swissQRBill?.amount,
            },
          ];
        }
      });
    };
    const extractQrInfoList = pdfAnalysisResults?.flatMap((result) =>
      extractQrInfo(result.messages, result.pages)
    );
    const qrInfoLookup = new Map(
      extractQrInfoList?.map((x) => [x.messageId, x])
    );
   
    const addressValidationResults = pdfAnalysisResults
      ?.flatMap((pdfAnalysisResult) => // Flatten the messages arrays into a single array
        pdfAnalysisResult.messages.map((message) => {
          // Get QR information from gFlatMap
          const qrInfo = qrInfoLookup.get(message.id);

          return {
            ...message,
            docId: pdfAnalysisResult.id,
            amount: qrInfo?.amount,
            reference: qrInfo?.reference,
            overrideSubject: message.overrideSubject
          };
        })
      )
      // Don't include deleted messages.
      .filter((message) => !message?.isDeleted ? true : false)
      .map((x) => extractAddressInformation(x, orderLevelToMsgProps, x.amount, x.reference))
      // Sort the addressValidationResults array so that items with validationErrors appear first
      .sort((a, b) => {
        if (a.validationErrors.length > 0 && b.validationErrors.length === 0) {
          return -1;
        }
        if (a.validationErrors.length === 0 && b.validationErrors.length > 0) {
          return 1;
        }
        if (a.validationWarnings.length > 0 && b.validationWarnings.length === 0) {
          return -1;
        }
        if (a.validationWarnings.length === 0 && b.validationWarnings.length > 0) {
          return 1;
        }
        if (a.emailInvalidWarning.length > 0 && b.emailInvalidWarning.length === 0) {
          return -1;
        }
        if (a.emailInvalidWarning.length === 0 && b.emailInvalidWarning.length > 0) {
          return 1;
        }
        return 0;
      });

    return addressValidationResults ?? [];
  }

  async getPdfAnalysisResMsgsThumb() {
    const pdfAnalysisResults = this.currentEditOrder?.pdfAnalysisResults as PdfAnalysisResult[];
    const docs = (pdfAnalysisResults || []).map((result: PdfAnalysisResult) => {
      const filenames = result.messages.map((mgs: PdfAnalysisMessage) => `${mgs.startsAtPage}_thumb.jpg`);
      return { docId: result.id, filenames };
    });
    
    this.thumbnailsMap = await this.storageService.getFilenameUrlMap(docs);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.stateService.updateState({ currentEditOrder: null });
  }
}
