import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { takeUntil, distinctUntilChanged, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { AppState, ProfileType } from '../../state/app-state';
import { AppStateService } from '../../services/app-state.service';
import { isArrayEmpty } from '../shared/utils/helper';

@Component({
  selector: 'app-no-profile-found',
  templateUrl: './no-profile-found.component.html',
  styleUrls: ['./no-profile-found.component.scss'],
})
export class NoProfileFoundComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private auth: AngularFireAuth,
    private stateService: AppStateService
  ) {}

  ngOnInit() {
    this.stateService.appState$
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(
          (prev, curr) => prev.profiles.list === prev.profiles.list
        ),
        filter((appState) => !isArrayEmpty(appState.profiles.list))
      )
      .subscribe((appState: AppState) => {
        const { profileId, currentProfile, profiles } = appState || {};
        if (profiles.list.length) {
          const id = profileId || currentProfile?.id || profiles.list[0].id;
          this.router.navigate([id, 'orders']);
        }
      });
  }

  logout() {
    this.auth.signOut().then(() => {
      // Reset local state to default value
      this.stateService.resetStateToDefault(true);
      this.router.navigate(['/logout']);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
