import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';

import { FirebaseService } from './firebase.service';
import { AppStateService } from './app-state.service';

import { environment } from '../../environments/environment';
import { KeycloakTokens, ProfileType } from '../state/app-state';

type EPostToken = {
  body: {
    grant_type: string;
    refresh_token: string;
    client_id: string;
    client_secret: string;
    tenant_id: string;
    company_id: string;
  };
  url: string;
};

type Sessions = { [key: string]: KeycloakTokens };

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private refreshIntervalId: any;

  constructor(
    private router: Router,
    private auth: AngularFireAuth,
    private stateService: AppStateService,
    private firebaseService: FirebaseService
  ) {}

  isTokenValid(token: KeycloakTokens): boolean {
    return Boolean(
      token &&
        token.refresh_token &&
        token.receivedAt &&
        token.refresh_expires_in
    );
  }

  async getSessionEpostToken(tenantId: string): Promise<EPostToken | null> {
    const { clientId, clientSecret, domain, realmName } = environment.keycloak || {};

    if (!clientId || !clientSecret || !domain || !realmName) {
      console.error('Keycloak environment variables are missing or invalid');
      await this.logoutUser();
      return null;
    }

    // Refresh the tokens before getting the new one
    await this.refreshTokenBeforeExpiry();

    const storedToken = this.getTenantTokens(tenantId) as KeycloakTokens;
    if (!storedToken) {
      console.error('No session token found in local storage.');
      await this.logoutUser();
      return null;
    }

    if (!storedToken['refresh_token']) {
      console.error('No refresh token found in stored session.');
      await this.logoutUser();
      return null;
    }

    const ePostToken: EPostToken = {
      body: {
        grant_type: 'refresh_token',
        refresh_token: storedToken['refresh_token'],
        client_id: clientId,
        client_secret: clientSecret,
        tenant_id: storedToken.additionalInfo.tenantId,
        company_id: storedToken.additionalInfo.companyId,
      },
      url: `https://${domain}/auth/realms/${realmName}/protocol/openid-connect/token`,
    };

    return ePostToken;
  }

  setTenantTokens(tokens: KeycloakTokens | Sessions, tenantId?: string): void {
    if (!tokens) {
      console.log(
        'Invalid Action: setting Sessions Tenant Tokens is cancelled, Tokens value is null'
      );
      return;
    }

    if (tenantId) {
      const sessions = (this.getTenantTokens() as Sessions) || {};
      sessions[tenantId] = tokens as KeycloakTokens;
      localStorage.setItem('sessions', JSON.stringify(sessions));
    } else {
      localStorage.setItem('sessions', JSON.stringify(tokens));
    }
  }

  getTenantTokens(
    tenantId?: string
  ): { [key: string]: KeycloakTokens } | KeycloakTokens | null {
    const storedSessionsString = localStorage.getItem('sessions');
    if (!storedSessionsString) {
      console.log('Local storage sessions value is null or undefined');
      this.logoutUser();
      return tenantId ? null : {};
    }

    try {
      const storedSessions = JSON.parse(storedSessionsString);
      const token = tenantId ? storedSessions[tenantId] : storedSessions;

      if (tenantId && !this.isTokenValid(token)) {
        console.error(`Invalid or no token found for tenant: ${tenantId}`);
        this.logoutUser();
        return null;
      }

      return token;
    } catch (error) {
      console.error('Error parsing sessions from localStorage:', error);
      this.logoutUser();
      return tenantId ? null : {};
    }
  }

  async refreshTokenBeforeExpiry() {
    try {
      const storedSessions = this.getTenantTokens() as Sessions;

      if (!storedSessions) {
        console.error('No valid sessions found in local storage.');
        await this.logoutUser();
        return;
      }

      const { domain, realmName, clientId, clientSecret } = environment.keycloak || {};
      const refreshUrl = `https://${domain}/auth/realms/${realmName}/protocol/openid-connect/token`;

      for (const tenantId in storedSessions) {
        const session = storedSessions[tenantId] as KeycloakTokens;
        if (!this.isTokenValid(session)) {
          console.error(`No valid session found for tenant ${tenantId}.`);
          await this.logoutUser();
          return;
        }

        const currentTime = new Date().getTime();
        const tokenReceivedTime = new Date(session.receivedAt).getTime();
        const refreshTokenExpiryTime = tokenReceivedTime + session.refresh_expires_in * 1000;

        if (currentTime >= refreshTokenExpiryTime) {
          console.error(`Token for tenant ${tenantId} is already expired.`);
          await this.logoutUser();
          return;
        }

        const body = {
          grant_type: 'refresh_token',
          client_id: clientId,
          client_secret: clientSecret,
          refresh_token: session.refresh_token,
          tenant_id: session.additionalInfo.tenantId,
          company_id: session.additionalInfo.companyId,
        };

        try {
          const refreshedTokens = await this.firebaseService.refreshToken({
            refreshUrl,
            body,
          });

          if (
            refreshedTokens &&
            refreshedTokens.refresh_expires_in &&
            refreshedTokens.refresh_token
          ) {
            const currentTime = new Date().getTime();
            this.setTenantTokens(
              {
                ...refreshedTokens,
                receivedAt: currentTime,
                additionalInfo: session.additionalInfo,
              },
              tenantId
            );
            console.log(
              `Keycloak tokens for tenant ${tenantId} refreshed and stored in local storage.`
            );
          } else {
            console.error(
              `Invalid refreshed tokens for tenant ${tenantId}:`,
              refreshedTokens
            );
            await this.logoutUser();
          }
        } catch (error) {
          console.error(
            `Error refreshing Keycloak token for tenant ${tenantId}:`,
            error
          );
          await this.logoutUser();
        }
      }
    } catch (error) {
      console.error('Error in refreshTokenBeforeExpiry:', error);
      await this.logoutUser();
    }
  }

  async logoutUser(errorCode = '') {
    try {
      const { domain, realmName, redirectUri } = environment.keycloak || {};
      const originUrl = window.location.origin;

      // Clear the refresh interval
      this.clearRefreshInterval();
      console.log('Clearing refresh token interval');

      // Clear local storage
      localStorage.removeItem('sessions');
      console.log('remove local storage sessions');

      // Reset local state to default value
      this.stateService.resetStateToDefault(true);
      console.log('Reset local state to default value');

      // sign out from firebase
      await this.auth.signOut();
      console.log('Sign out from firebase');

      // Navigate to login page
      this.router.navigate(['/logout']);
      console.log('Navigate to logout page');

      // handle redirect url, root_url and error
      let logoutRedirectUri = `${redirectUri}/logout`;
      if (redirectUri !== originUrl) {
        logoutRedirectUri += `?root_url=${originUrl}`;
        if (errorCode) {
          logoutRedirectUri += `&error=${errorCode}`;
        }
      } else {
        if (errorCode) {
          logoutRedirectUri += `?error=${errorCode}`;
        }
      }

      // redirect to logout on keycloak side
      const keycloakLogoutUrl = `https://${domain}/auth/realms/${realmName}/protocol/openid-connect/logout?redirect_uri=${encodeURIComponent(
        logoutRedirectUri
      )}`;
      window.location.href = keycloakLogoutUrl;
    } catch (error) {
      console.error('Error during sign out:', error);
    }
  }

  // Call refreshTokenBeforeExpiry immediately and then every 10 minutes
  startRefreshInterval() {
    this.refreshTokenBeforeExpiry(); // Check and refresh immediately

    const refreshInterval = 10 * 60 * 1000; // 10 minutes in milliseconds
    this.refreshIntervalId = setInterval(
      () => this.refreshTokenBeforeExpiry(),
      refreshInterval
    );
  }

  // Clear the refresh interval
  clearRefreshInterval() {
    if (this.refreshIntervalId) {
      clearInterval(this.refreshIntervalId);
      this.refreshIntervalId = null;
    }
  }
}
