import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { AppState, OrderType, LoadingState, PdfAnalysisPreset } from '../state/app-state';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  private appStateSubscription?: Subscription;
  private value: AppState = {
    isNavExpanded: true,
    isLogout: false,
    isLoggingIn: false,
    currentUser: null,
    currentProfile: null,
    currentEditOrder: null,
    currentMainDocId: null,
    currentRecipient: null,
    currentPreset: null,
    uploadingMainDoc: false,
    uploadingAttachmentDoc: false,
    isCreatingOrder: false,
    isLoadingOrder: false,
    isLoadingPreset: false,
    attachmentPdf: null,
    profileId: null,
    profiles: {
      isLoaded: false,
      isLoading: false,
      list: [],
    },
    orders: {
      isLoaded: false,
      isLoading: false,
      list: new Map<string, OrderType>(),
      listDone: new Map<string, OrderType>(),
    },
    presets: {
      isLoaded: false,
      isLoading: false,
      list: new Map<string, PdfAnalysisPreset>(),
      listPublic: [],
    },
  };
  public appState$ = new BehaviorSubject<AppState>(this.value);

  constructor() {
    this.appStateSubscription = this.appState$.subscribe((state) => {
      this.value = state;
    });
  }

  updateState(newStatePartial: Partial<AppState>): void {
    const newState: AppState = { ...this.value, ...newStatePartial };
    this.appState$.next(newState);
  }

  startLoading(state: LoadingState): void {
    switch (state) {
      case LoadingState.OrdersLoading:
        this.updateState({ orders: { ...this.value.orders, isLoading: true } });
        break;
      case LoadingState.OrderLoading:
        this.updateState({ isLoadingOrder: true });
        break;
      case LoadingState.OrderCreating:
        this.updateState({ isCreatingOrder: true });
        break;
      case LoadingState.ProfilesLoading:
        this.updateState({
          profiles: { ...this.value.profiles, isLoading: true },
        });
        break;
      case LoadingState.PresetsLoading:
        this.updateState({
          presets: { ...this.value.presets, isLoading: true },
        });
        break;
      case LoadingState.PresetLoading:
        this.updateState({ isLoadingPreset: true });
        break;
      case LoadingState.LoginLoading:
        this.updateState({ isLoggingIn: true });
        break;
      default:
        console.error('Invalid loading state!');
    }
  }

  stopLoading(state: LoadingState): void {
    switch (state) {
      case LoadingState.OrdersLoading:
        this.updateState({
          orders: { ...this.value.orders, isLoading: false },
        });
        break;
      case LoadingState.OrderLoading:
        this.updateState({ isLoadingOrder: false });
        break;
      case LoadingState.OrderCreating:
        this.updateState({ isCreatingOrder: false });
        break;
      case LoadingState.ProfilesLoading:
        this.updateState({
          profiles: { ...this.value.profiles, isLoading: false },
        });
        break;
      case LoadingState.PresetsLoading:
        this.updateState({
          presets: { ...this.value.presets, isLoading: false },
        });
        break;
      case LoadingState.PresetLoading:
        this.updateState({ isLoadingPreset: false });
        break;
      case LoadingState.LoginLoading:
          this.updateState({ isLoggingIn: false });
          break;
      default:
        console.error('Invalid loading state!');
    }
  }

  resetStateToDefault(isLogout: boolean = false): void {
    this.value = {
      isNavExpanded: true,
      isLogout,
      isLoggingIn: false,
      currentUser: null,
      currentProfile: null,
      currentEditOrder: null,
      currentMainDocId: null,
      currentRecipient: null,
      currentPreset: null,
      uploadingMainDoc: false,
      uploadingAttachmentDoc: false,
      isCreatingOrder: false,
      isLoadingOrder: false,
      isLoadingPreset: false,
      attachmentPdf: null,
      profileId: null,
      profiles: {
        isLoaded: false,
        isLoading: false,
        list: [],
      },
      orders: {
        isLoaded: false,
        isLoading: false,
        list: new Map<string, OrderType>(),
        listDone: new Map<string, OrderType>(),
      },
      presets: {
        isLoaded: false,
        isLoading: false,
        list: new Map<string, PdfAnalysisPreset>(),
        listPublic: [],
      },
    };
    this.appState$.next(this.value);
  }

  ngOnDestroy(): void {
    this.appStateSubscription?.unsubscribe();
  }
}
