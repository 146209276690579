import { Component, Input } from '@angular/core';
import {
  AddressValidationFlag,
  AddressValidationResult,
} from '../../../../state/app-state';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-address-validation-flag',
  templateUrl: './address-validation-flag.component.html',
  styleUrls: ['./address-validation-flag.component.scss'],
})
export class AddressValidationFlagComponent {

  constructor(
    private translateService: TranslateService
  ) {}

  @Input() validationResult!: AddressValidationResult;

  formatTooltip(errors: AddressValidationFlag[], type: string): string {
    const prefix = `${errors.length} ${type}`;
    const errorMessage = errors[0].message;
    const errorDetails = errors.map((err) => `- ${err.code}`).join('\n');
    if (errorMessage === 'Invalid') {
      return `${prefix}\n\n${this.translateService.instant('invalidErrorMessage')}\n${errorDetails}`;
    }
    return `${prefix}\n\n${errorMessage}\n${errorDetails}`;
  }
  
  formatErrorTooltip(error?: { title?: string; message?: string }): string {
    if (error) {
      if(error.title === 'Unable to process address validation'){
        return `${this.translateService.instant('pages.addressCheck.errorMsg.title')}\n\n${
          this.translateService.instant('pages.addressCheck.errorMsg.description')
        }`;
      }else {
        return `${error.title ?? this.translateService.instant('pages.addressCheck.errorMsg.titleNotAvailable')}\n\n${
          error.message ??  this.translateService.instant('pages.addressCheck.errorMsg.descNotAvailable')
        }`;
      }
    } else {
      return  this.translateService.instant('pages.addressCheck.errorMsg.errObjUndefined');
    }
  }
}
