import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil, debounceTime } from 'rxjs';

import { ConfirmationDialogComponent } from '../shared/components/app-confirmation-dialog.component';
import { PdfPresetService } from '../../services/pdf-preset.service';
import { AppStateService } from '../../services/app-state.service';
import { StorageService } from '../../services/storage.service';
import {
  AppState,
  PdfAnalysisPreset,
  PresetStatus,
  LoadingState,
} from '../../state/app-state';
import { isObjectEmpty, isArrayEmpty } from '../shared/utils/helper';
import { TranslateService } from '@ngx-translate/core';

type PresetTransformationCriteria = {
  presetNameSearchKeyword: string;
  presetStatusList: (PresetStatus | 'all')[];
};

@Component({
  selector: 'app-presets',
  templateUrl: './presets.component.html',
  styleUrls: ['./presets.component.scss'],
})
export class PresetsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private currentState!: AppState;
  private profileId: string | null = null;
  public presets: PdfAnalysisPreset[] = [];
  public status: { name: PresetStatus | 'all'; selected: boolean }[] = [
    {
      name: 'all',
      selected: true,
    },
    {
      name: 'draft',
      selected: true,
    },
    {
      name: 'published',
      selected: true,
    },
  ];
  selectedStatusDisplay = 'Status: all';
  presetNameSearchKeyword = '';
  presetSearchTerm$ = new Subject<void>();

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private pdfPresetService: PdfPresetService,
    private storageService: StorageService,
    public stateService: AppStateService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.stateService.appState$
      .pipe(takeUntil(this.destroy$))
      .subscribe((appState: AppState) => {
        this.currentState = appState;
        this.profileId = appState.profileId;
        if (
          !isObjectEmpty(appState.currentUser) &&
          !isObjectEmpty(appState.currentProfile) &&
          !appState.presets.isLoading &&
          !appState.presets.isLoaded
        ) {
          this.stateService.startLoading(LoadingState.PresetsLoading);
          this.pdfPresetService.loadPresets().then(() => {
            this.stateService.stopLoading(LoadingState.PresetsLoading);
          });
        }

        if (this.currentState.presets.list.size > 0) {
          this.presets = PresetsComponent.transformPresets(
            Array.from(this.currentState.presets.list.values()),
            this.getTransformationCriteria
          );
        }
      });

    // Transform presets on search trigger with debounce.
    this.presetSearchTerm$
      .pipe(debounceTime(250), takeUntil(this.destroy$))
      .subscribe((_) => {
        this.presets = PresetsComponent.transformPresets(
          Array.from(this.currentState.presets.list.values()),
          this.getTransformationCriteria
        );
      });
  }

  get getAllStatus() {
    return this.status.filter((s) => s.selected).map((s) => s.name);
  }

  get getTransformationCriteria(): PresetTransformationCriteria {
    return {
      presetNameSearchKeyword: this.presetNameSearchKeyword,
      presetStatusList: this.getAllStatus,
    };
  }

  private static filterPresets(
    presets: PdfAnalysisPreset[],
    presetStatusList: PresetTransformationCriteria['presetStatusList']
  ) {
    const hasAllPresetStatus = presetStatusList.includes('all');
  
    return presets.filter((preset) => {
      if (hasAllPresetStatus) {
        return true;
      }
      
      if (presetStatusList.includes('draft') && 
          (preset.status === 'draft' || preset.status === 'generating' || preset.status === 'processed')) {
        return true;
      }
  
      return presetStatusList.includes(preset.status);
    });
  }

  private static transformPresets(
    presets: PdfAnalysisPreset[],
    presetTransformationCriteria: PresetTransformationCriteria
  ) {
    const { presetNameSearchKeyword, presetStatusList } = presetTransformationCriteria;
    const searchKeyword = presetNameSearchKeyword?.trim().toLocaleLowerCase();

    const filteredPresets = this.filterPresets(
      presets,
      presetStatusList
    ).filter((preset) =>
      (preset?.nameEn || '').trim().toLocaleLowerCase().includes(searchKeyword)
    );

    // Sort the filteredPresets by createdAt
    filteredPresets.sort((a, b) => {
      return b.createdAt.seconds - a.createdAt.seconds;
    });

    return filteredPresets;
  }

  onSearchChange($event: any) {
    this.presetSearchTerm$.next();
  }

  selectStatus(
    $event: any,
    value: { name: PresetStatus | 'all'; selected: boolean }
  ) {
    $event.stopPropagation();
    $event.preventDefault();

    // Update display status state and status options.
    const item = this.status.findIndex((s) => s.name == value.name);
    if (item != undefined) {
      this.status[item].selected = !this.status[item].selected;

      if (item == 0) {
        this.status.forEach((s) => (s.selected = this.status[item].selected));
        this.selectedStatusDisplay = this.status[item].selected
          ? 'Status: all'
          : 'Status:';
      } else {
        const selectedArr: string[] = [];
        for (let i = 1; i < this.status.length; i++) {
          if (this.status[i].selected) selectedArr.push(this.status[i].name);
        }

        if (selectedArr.length < this.status.length - 1) {
          this.status[0].selected = false;
          this.selectedStatusDisplay = 'Status: ' + selectedArr.join();
        } else if (selectedArr.length == this.status.length - 1) {
          this.status[0].selected = true;
          this.selectedStatusDisplay = 'Status: all';
        } else {
          this.selectedStatusDisplay = 'Status: ' + selectedArr.join();
        }
      }
    }

    // Transform the preset list.
    this.presets = PresetsComponent.transformPresets(
      Array.from(this.currentState.presets.list.values()),
      this.getTransformationCriteria
    );
  }

  trackByOPresetId(index: number, preset: PdfAnalysisPreset): string {
    return preset.id;
  }

  onNewPresetClick() {
    this.router.navigate([this.profileId, 'presets', 'create']);
  }

  onEdit(id: string) {
    const currentPreset = this.currentState.presets.list.get(id) ?? null;
    if (currentPreset && this.profileId) {
      this.stateService.updateState({ currentPreset });
      if (currentPreset.status !== 'draft') {
        this.router.navigate([this.profileId, 'presets', id, 'edit']);
      } else {
        this.router.navigate([this.profileId, 'presets', 'create', id]);
      }
    }
  }

  onDelete(id: string) {
    let confirmationMessage:string = '';
    this.translate.get('presets.confirmationDelete').subscribe((res: string) => {
      confirmationMessage = res;
    });
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: { message: confirmationMessage },
    });
  
    dialogRef.afterClosed().subscribe(async (confirm) => {
      if (confirm) {
        this.stateService.startLoading(LoadingState.PresetsLoading);
        try {
          const currentPreset = this.currentState.presets.list.get(id) ?? null;
          const deleteType = currentPreset?.deleteType || 'both';
          // Use Promise.all to wait for both promises to finish
          await Promise.all([
            this.storageService.deletePresetFile(id, deleteType),
            this.pdfPresetService.deletePreset(id)
          ]);
  
          // Update presets after both promises have finished
          this.presets = PresetsComponent.transformPresets(
            Array.from(this.currentState.presets.list.values()),
            this.getTransformationCriteria
          );
        } catch (error) {
          console.error('Error while deleting preset:', error);
        } finally {
          this.stateService.stopLoading(LoadingState.PresetsLoading);
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    // reset presets.isLoaded to false
    if (this.currentState) {
      this.stateService.updateState({
        presets: { ...this.currentState.presets, isLoaded: false },
      });
    }
  }
}
