import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Timestamp } from '@angular/fire/firestore';
import { MatSort } from '@angular/material/sort';
import { Subject, takeUntil } from 'rxjs';
import { switchMap, tap, catchError } from 'rxjs/operators';

import { PdfAnalysisResultService } from '../../../services/pdf-analysis-result.service';
import { extractAddressInformation } from './../../shared/utils/address-utils';
import { AppStateService } from '../../../services/app-state.service';
import { FirebaseService } from '../../../services/firebase.service';
import { OrderService } from '../../../services/order.service';
import { isObjectEmpty } from './../../shared/utils/helper';
import {
  OrderType,
  AppState,
  PdfAnalysisPage,
  PdfAnalysisMessage,
  DeliveryTypeLineItem,
  AddressValidationResult,
  OrderLevelToMsgProps,
  PdfAnalysisResult,
  LoadingState
} from '../../../state/app-state';

interface RecipientInfo {
  email: string;
  recipient: string;
}

@Component({
  selector: 'app-channel-check',
  templateUrl: './channel-check.component.html',
  styleUrls: ['./channel-check.component.scss'],
})
export class ChannelCheckComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  private currentState!: AppState;
  private destroy$ = new Subject<void>();
  private skipResetCurrentOrder: boolean = false;
  private localLoaderRef: boolean = false;
  private prevCurrentEditOrderString: string = '';
  private orderId: string = '';
  private profileId: string = '';
  public currentEditOrder: OrderType | null = null;
  showButtons = true;

  preferredChannels: {
    label: string;
    value: 'ePost' | 'print' | 'email';
    checked: boolean;
  }[] = [
    { label: 'ePost', value: 'ePost', checked: true },
    { label: 'Print', value: 'print', checked: true },
    // { label: 'Email', value: 'email', checked: true },
  ];
  dataSource = new MatTableDataSource([] as AddressValidationResult[]);
  // TODO changes pipe
  isRecipientType(item: any): item is RecipientInfo {
    return (
      item && typeof item === 'object' && 'recipient' in item && 'email' in item
    );
  }
  columns = [
    {
      columnDef: 'recipient',
      header: 'Recipients',
      cell: (element: AddressValidationResult): RecipientInfo => {
        const email = element.email != undefined ? element.email : null;
        const recipient = element?.address?.recipient;
        return { email: email || '', recipient };
      },
      sortable: true,
    },
    {
      columnDef: 'subject',
      header: 'Subject',
      cell: (element: AddressValidationResult) => {
        return {
          subject: element.subject,
          isSubjectOverride: element.isSubjectOverride 
        };
      },
      cellClass: '',
      sortable: false,
    },
    {
      columnDef: 'pages',
      header: 'Pages',
      cell: (element: AddressValidationResult) =>
        !element.additionalBlankPage
          ? element.numPages + element.attachmentNumPages
          : element.numPages + 1 + element.attachmentNumPages,
      cellClass: 'cell-pages',
      sortable: true,
    },
    {
      columnDef: 'envelope',
      header: 'Envelope',
      cell: (element: AddressValidationResult) => {
        let envelopeSize = element?.envelopeSize;
        if (element.selectedChannel !== 'print') {
          envelopeSize = 'n/a';
        } else {
          const pages = !element.additionalBlankPage
            ? element.numPages + element.attachmentNumPages
            : element.numPages + 1 + element.attachmentNumPages;
          envelopeSize = pages <= 14 ? 'C5' : 'C4';
        }

        return envelopeSize;
      },
      cellClass: 'cell-pages',
      sortable: true,
    },
    {
      columnDef: 'doc-type',
      header: 'Doc Type',
      cell: (element: AddressValidationResult) => {
        const translatedDocType = this.translate.instant(`pages.addressCheck.editRecipient.${element.docType}`);
        return translatedDocType || '';
      },
      cellClass: '',
      sortable: true,
    },
    {
      columnDef: 'delivery-type',
      header: 'Delivery Type',
      cell: (element: AddressValidationResult) => {
        const deliveryTypeMap = {
          'b-post': 'B-Post',
          'b-post mass': 'B-Post Mass',
          'a-post': 'A-Post',
          'a-post plus': 'A-Post Plus',
          'registered': 'Registered',
        }
        let localDelivery = '';
        if (['b-post', 'a-post'].includes(element?.postalDeliveryTypeDomestic || '')) {
          localDelivery = deliveryTypeMap[element.postalDeliveryTypeDomestic];
        } else {
          localDelivery = 'B-Post'
        }

        return !element.isSwissAddress
          ? `${this.translate.instant('table.deliveryTypeInternational')} Standard`
          : `${this.translate.instant('table.deliveryTypeDomestic')} ${localDelivery}`;
      },
      cellClass: '',
      sortable: true,
    },
    {
      columnDef: 'supported-channel',
      header: 'Supported Channels',
      cell: (element: any) => Object.keys(element.supportedChannels || {}),
      sortable: true,
    },
    {
      columnDef: 'chosen-channel',
      header: 'Chosen Channel',
      cell: (element: any) => element.selectedChannel || 'n/a',
      sortable: true,
    },
  ];
  displayedColumns: string[] = [
    'recipient',
    'subject',
    'pages',
    'envelope',
    'doc-type',
    'delivery-type',
    'supported-channel',
    'chosen-channel',
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    public firebaseService: FirebaseService,
    public pdfAnalysisResultService: PdfAnalysisResultService,
    public stateService: AppStateService,
    public orderService: OrderService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.getCurrentEditOrder();

    this.stateService.appState$
      .pipe(takeUntil(this.destroy$))
      .subscribe((appState: AppState) => {
        this.currentState = appState;
        const currentOrder = appState.currentEditOrder as OrderType;
        const currentEditOrderStringify = JSON.stringify(currentOrder);

        if (!isObjectEmpty(currentOrder) && this.prevCurrentEditOrderString !== currentEditOrderStringify) {
          this.prevCurrentEditOrderString = currentEditOrderStringify;
          this.currentEditOrder = currentOrder;
          this.updateDataSource();
          this.updatePreferredChannelsExclusion();
        }

        if (
          !isObjectEmpty(appState.currentProfile) &&
          this.profileId !== appState?.currentProfile?.id
        ) {
          this.profileId = appState.currentProfile!.id;
        }
      });

      const customized = this.preferredChannels.find(obj => obj.checked === false);
      this.showButtons = customized ? false : true;

  }

  getCurrentEditOrder() {
    this.route.parent?.paramMap
      .pipe(
        tap(() => {
          if (!this.localLoaderRef) {
            this.stateService.startLoading(LoadingState.OrderLoading);
          }
        }),
        switchMap(async (params) => {
          const orderId = params.get('orderId');
          if (!orderId) {
            throw new Error('Order ID is not available.');
          }
          this.orderId = orderId;
          await this.orderService.getOrderWithPdfAnalysisResults(orderId);
          return orderId;
        }),
        switchMap((orderId) => this.startDeliveryItems(orderId)),
        catchError((err) => {
          console.error(err);
          this.stateService.stopLoading(LoadingState.OrderLoading);
          throw err;
        })
      )
      .subscribe();
  }

  async startDeliveryItems(orderId: any): Promise<void> {
    try {
      const deliveryTypeLineItems: DeliveryTypeLineItem[] =
        await this.firebaseService.computeDeliveryItems(orderId);
      let totalAmount: number = 0;
      deliveryTypeLineItems.forEach((i: any) => {
        totalAmount += i.priceCents * i.typeCount;
      });
      await this.updateCurrentOrder(totalAmount, deliveryTypeLineItems);
    } catch (err) {
      console.log('Error on Compute Delivery Items', err);
    }
  }

  async updateCurrentOrder(
    amountCents: number,
    deliveryTypeLineItems: DeliveryTypeLineItem[]
  ): Promise<void> {
    this.stateService.startLoading(LoadingState.OrderLoading);
    const updates: Partial<OrderType> = {
      amountCents,
      deliveryTypeLineItems,
    };
    await this.orderService.updateOrder(updates);
    this.stateService.stopLoading(LoadingState.OrderLoading);
  }

  async updateDataSource() {
    this.dataSource = new MatTableDataSource(this.getAllAddresses);
    this.dataSource.sortingDataAccessor = (
      item: AddressValidationResult,
      property: string
    ): string | number => {
      if (property === 'recipient') {
        return item.overrideRecipientDetails?.address?.recipient
          ? item.overrideRecipientDetails?.address?.recipient
          : item.address.recipient;
      }

      if (property === 'supported-channel') {
        if (
          item.supportedChannels &&
          Object.keys(item.supportedChannels).length > 0
        ) {
          const supportedChannels = Object.keys(item.supportedChannels);
          return supportedChannels.join(', ');
        } else {
          return 'print';
        }
      }

      if (property === 'chosen-channel') {
        return item.selectedChannel || 'n/a';
      }

      return item.address?.recipient ?? '';
    };
    this.dataSource.sort = this.sort;
  }

  async goBack() {
    this.orderService.updateCurrentOrderEditStep('address-check');
    this.router.navigate(['../address-check'], { relativeTo: this.route });
  }

  cancel() {
    this.router.navigate([this.profileId, 'orders']);
  }

  async onNext() {
    this.skipResetCurrentOrder = true;
    this.stateService.startLoading(LoadingState.OrderLoading);
    const updates: Partial<OrderType> = {
      currentEditStep: 'summary'
    };
    this.orderService.updateOrder(updates).then(() => {
      this.stateService.stopLoading(LoadingState.OrderLoading);
      this.router.navigate(['../summary'], { relativeTo: this.route });
    });
  }

  updatePreferredChannelsExclusion() {
    if (!isObjectEmpty(this.currentEditOrder)) {
      const { allowedChannels } = this.currentEditOrder!;
      this.preferredChannels.forEach((channel) => {
        switch (channel.value) {
          case 'ePost':
            channel.checked = allowedChannels.isEpostAllowed;
            break;
          case 'print':
            channel.checked = allowedChannels.isPrintAllowed;
            break;
          case 'email':
            channel.checked = false;
            break;
          default:
            channel.checked = true;
            break;
        }
      });
    }
  }

  get getAllAddresses(): AddressValidationResult[] {
    const {
      pdfAnalysisResults: orderPdfAnalysisResults,
      subject,
      docType,
      postalDeliveryTypeDomestic,
      printMetadata,
    } = this.currentEditOrder || {};
    const pdfAnalysisResults = orderPdfAnalysisResults as PdfAnalysisResult[];
    const orderLevelToMsgProps = {
      subject,
      docType,
      postalDeliveryTypeDomestic,
      printMetadata,
    } as OrderLevelToMsgProps;

    const addressValidationResults = pdfAnalysisResults
      // Flatten the messages arrays into a single array
      ?.flatMap((pdfAnalysisResult) =>
        pdfAnalysisResult.messages.map((message) => {
          return {
            ...message,
            docId: pdfAnalysisResult.id,
          };
        })
      )
      // Don't include deleted messages.
      .filter((message) => (!message?.isDeleted ? true : false))
      .map((x) => extractAddressInformation(x, orderLevelToMsgProps))
      // Sort the addressValidationResults array so that items with validationErrors appear first
      .sort((a, b) => {
        if (a.validationErrors.length > 0 && b.validationErrors.length === 0) {
          return -1;
        }
        if (a.validationErrors.length === 0 && b.validationErrors.length > 0) {
          return 1;
        }
        return 0;
      });

    return addressValidationResults ?? [];
  }

  onPreferredChannelsChange(): void {
    const orderId = this.currentEditOrder?.id;
    const preferredChannels = this.preferredChannels
      .filter((preferredChannel) => preferredChannel.checked)
      .map((preferredChannel) => preferredChannel.value);
    const allowedChannels = {
      isEpostAllowed: true,
      isPrintAllowed: true,
      isEmailAllowed: false,
    };
    this.preferredChannels.forEach((channel) => {
      switch (channel.value) {
        case 'ePost':
          allowedChannels.isEpostAllowed = channel.checked;
          break;
        case 'print':
          allowedChannels.isPrintAllowed = channel.checked;
          break;
        case 'email':
          allowedChannels.isEmailAllowed = channel.checked;
          break;
      }
    });

    const updates: any = (
      this.currentEditOrder?.pdfAnalysisResults ||
      []
    ).map((result) => {
      const messages = result.messages
        .filter((message) => !message?.isDeleted ? true : false)
        .map((mgs) => {
          const supportedChannels = [...Object.keys(mgs.supportedChannels)];
          let selectedChannel = '';

          for (let channel of preferredChannels) {
            if (supportedChannels.includes(channel)) {
              selectedChannel = channel;
              break;
            }
          }

          if (!selectedChannel) {
            selectedChannel = 'n/a';
          }

          return { ...mgs, selectedChannel };
        });

      return { docId: result.id, updatedPdfAnalysisResult: { messages } };
    });

    if (orderId) {
      this.orderService.updateOrder({ allowedChannels }).then(() => {
        this.localLoaderRef = true;
        this.stateService.startLoading(LoadingState.OrderLoading);
        this.pdfAnalysisResultService
          .updatePdfAnalysisResultsDocs(orderId, updates)
          .then((success) => {
            this.getCurrentEditOrder();
          });
      });
    } else {
      this.stateService.stopLoading(LoadingState.OrderLoading);
      console.log('Order ID is required.');
    }
  }

  ngOnDestroy(): void {
    // Destroy subscriptions.
    this.destroy$.next();
    this.destroy$.unsubscribe();
    if (!this.skipResetCurrentOrder) {
      this.stateService.updateState({ currentEditOrder: null });
    }
  }
}