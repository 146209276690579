import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
} from '@angular/fire/compat/auth-guard';

import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { OrdersComponent } from './components/orders/orders.component';
import { PresetsComponent } from './components/presets/presets.component';
import { EditOrderComponent } from './components/edit-order/edit-order.component';
import { PresetStudioComponent } from './components/preset-studio/preset-studio.component';
import { SummaryComponent } from './components/edit-order-steps/summary/summary.component';
import { CompletedComponent } from './components/edit-order-steps/completed/completed.component';
import { InvalidProfileComponent } from './components/invalid-profile/invalid-profile.component';
import { NoProfileFoundComponent } from './components/no-profile-found/no-profile-found.component';
import { DocUploadComponent } from './components/edit-order-steps/doc-upload/doc-upload.component';
import { DeliveredOrdersComponent } from './components/delivered-orders/delivered-orders.component';
import { AddressCheckComponent } from './components/edit-order-steps/address-check/address-check.component';
import { ChannelCheckComponent } from './components/edit-order-steps/channel-check/channel-check.component';
import { OrderSettingsComponent } from './components/edit-order-steps/order-settings/order-settings.component';
import { RecipientEditComponent } from './components/edit-order-steps/recipient-edit/recipient-edit.component';
import { PresetPdfUploadComponent } from './components/preset-edit-steps/preset-pdf-upload/preset-pdf-upload.component';
import { PresetEditorFormComponent } from './components/preset-edit-steps/preset-editor-form/preset-editor-form.component';

import { OrderStatusGuard } from './components/shared/guard/order-status.guard';
import { ProfileIdGuard } from './components/shared/guard/profile-id.guard';

const web2printTitle = 'Smartsend';
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['logout']);

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'orders' },
  { path: 'login', component: LoginComponent, title: web2printTitle },
  { path: 'logout', component: LogoutComponent, title: web2printTitle },
  {
    path: 'no-profile-found',
    component: NoProfileFoundComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'invalid-profile',
    component: InvalidProfileComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'orders',
    component: OrdersComponent,
    title: web2printTitle,
    canActivate: [AngularFireAuthGuard, ProfileIdGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin, changeProfile: true },
  },
  {
    path: ':profileId/orders',
    component: OrdersComponent,
    title: web2printTitle,
    canActivate: [AngularFireAuthGuard, ProfileIdGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin, changeProfile: true },
  },
  {
    path: ':profileId/orders/:orderId/edit',
    component: EditOrderComponent,
    title: web2printTitle,
    canActivate: [AngularFireAuthGuard, ProfileIdGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin, changeProfile: false },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'order-basics',
      },
      {
        path: 'order-basics',
        component: OrderSettingsComponent,
        canActivate: [OrderStatusGuard, ProfileIdGuard],
      },
      {
        path: 'doc-upload',
        component: DocUploadComponent,
        canActivate: [OrderStatusGuard, ProfileIdGuard],
      },
      {
        path: 'address-check',
        component: AddressCheckComponent,
        canActivate: [OrderStatusGuard, ProfileIdGuard],
      },
      {
        path: 'channel-check',
        component: ChannelCheckComponent,
        canActivate: [OrderStatusGuard, ProfileIdGuard],
      },
      {
        path: 'summary',
        component: SummaryComponent,
        canActivate: [OrderStatusGuard, ProfileIdGuard],
      },
      {
        path: 'completed',
        component: CompletedComponent,
        canActivate: [OrderStatusGuard, ProfileIdGuard],
      },
    ],
  },
  {
    path: ':profileId/monitoring/:orderId/summary',
    component: EditOrderComponent,
    title: web2printTitle,
    canActivate: [AngularFireAuthGuard, ProfileIdGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin, changeProfile: false },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: SummaryComponent,
        canActivate: [OrderStatusGuard],
      },
    ],
  },
  {
    path: ':profileId/presets',
    component: PresetsComponent,
    title: web2printTitle,
    canActivate: [AngularFireAuthGuard, ProfileIdGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin, changeProfile: true },
  },
  {
    path: ':profileId/presets',
    component: PresetStudioComponent,
    title: web2printTitle,
    canActivate: [AngularFireAuthGuard, ProfileIdGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin, changeProfile: false },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'create',
      },
      {
        path: 'create',
        component: PresetPdfUploadComponent,
        canActivate: [ProfileIdGuard],
      },
      {
        path: 'create/:presetId',
        component: PresetPdfUploadComponent,
        canActivate: [ProfileIdGuard],
      },
      {
        path: ':presetId/edit',
        component: PresetEditorFormComponent,
        canActivate: [ProfileIdGuard],
      },
    ]
  },
  {
    path: ':profileId/monitoring',
    component: DeliveredOrdersComponent,
    title: web2printTitle,
    canActivate: [AngularFireAuthGuard, ProfileIdGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin, changeProfile: true },
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
