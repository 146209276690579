<div class="process-loader-indicator">
	<div class="step-container">
		<div 
			*ngFor="let color of stepColor; let i = index" 
			class="step" 
			[ngClass]="{'blinking': color === 'orange', 'm-r-1': i !== stepColor.length - 1}"
			[ngStyle]="{'background-color': color}"
		></div>
	</div>
	<mat-progress-bar *ngIf="showProgressBar" [mode]="progressBarMode" [value]="progressValue"></mat-progress-bar>
	<div class="status-text">
		<ng-container *ngIf="analysisStatus === 'WAITING'">{{ 'stage.Waiting' | translate}}</ng-container>
		<ng-container *ngIf="analysisStatus === 'PREPARING'">{{ 'stage.Preparing' | translate}}</ng-container>
		<ng-container *ngIf="analysisStatus === 'DETECTING'">{{ 'stage.Detecting' | translate}}</ng-container>
		<ng-container *ngIf="analysisStatus === 'SEGREGATING'">{{ 'stage.Segregating' | translate}}</ng-container>
		<ng-container *ngIf="analysisStatus === 'VALIDATING'">{{ 'stage.Validating' | translate}}</ng-container>
		<ng-container *ngIf="analysisStatus === 'DONE'">{{ 'stage.Done' | translate}}</ng-container>
	</div>
	<div class="progress-count">
		<ng-container *ngIf="analysisStatus === 'PREPARING'">
			<span>{{ 'pages.summary.documents' | translate}} {{ preparingDocumentCurrentNum | number:'1.0-0' }} of {{ preparingDocumentCount | number:'1.0-0' }}</span>
		</ng-container>
		<ng-container *ngIf="analysisStatus === 'DETECTING'">
			<span>{{ 'overview.progress' | translate}}: {{ detectingProgressPercentage | number:'1.0-0' }}%</span>
		  </ng-container>
		<ng-container *ngIf="analysisStatus === 'SEGREGATING'">
			<!-- <span>Pages {{ segregatingCurrentPageNum }} of {{ segregatingNumPages }}</span> -->
			<span>{{ 'overview.progress' | translate}}: {{ segregatingProgressPercentage | number:'1.0-0' }}%</span>
		</ng-container>
		<ng-container *ngIf="analysisStatus === 'VALIDATING'">
			<!-- <span>Messages {{ validationCurrentMessageNum }} of {{ validationNumMessages }}</span> -->
			<span>{{ 'overview.progress' | translate}}: {{ validatingProgressPercentage | number:'1.0-0' }}%</span>
		</ng-container>
	</div>
	<div class="empty"></div>
</div>