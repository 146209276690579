import {
  Directive,
  Input,
  ElementRef,
  HostListener,
  Renderer2,
  OnInit,
} from '@angular/core';

@Directive({
  selector: '[appCustomTooltip]',
})
export class CustomTooltipDirective implements OnInit {
  @Input('appCustomTooltip') tooltipText!: string;

  private tooltipElement = this.renderer.createElement('span');

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.renderer.appendChild(this.el.nativeElement, this.tooltipElement);
    this.renderer.addClass(this.tooltipElement, 'custom-tooltip');
    this.renderer.setProperty(
      this.tooltipElement,
      'textContent',
      this.tooltipText
    );
    this.renderer.setStyle(this.tooltipElement, 'display', 'none');

    // Add Styles
    this.renderer.setStyle(this.tooltipElement, 'position', 'absolute');
    this.renderer.setStyle(this.tooltipElement, 'z-index', '10');
    this.renderer.setStyle(this.tooltipElement, 'bottom', '45px');
    this.renderer.setStyle(this.tooltipElement, 'background-color', 'rgba(51, 51, 51, 0.8)');
    this.renderer.setStyle(this.tooltipElement, 'color', '#ffffff');
    this.renderer.setStyle(this.tooltipElement, 'white-space', 'nowrap');
    this.renderer.setStyle(this.tooltipElement, 'min-width', '100px')
    this.renderer.setStyle(this.tooltipElement, 'padding', '4px 0');
    this.renderer.setStyle(this.tooltipElement, 'border-radius', '5px');
    this.renderer.setStyle(this.tooltipElement, 'font-size', '10px');
  }

  // @HostListener('mouseenter') onMouseEnter() {
  //   this.renderer.setStyle(this.tooltipElement, 'display', 'inline');
  // }

  // @HostListener('mouseleave') onMouseLeave() {
  //   this.renderer.setStyle(this.tooltipElement, 'display', 'none');
  // }

  show() {
    this.renderer.setStyle(this.tooltipElement, 'display', 'inline');
  }

  hide() {
    this.renderer.setStyle(this.tooltipElement, 'display', 'none');
  }
}
