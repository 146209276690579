import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import {
  AppState,
  KeycloakTokens,
  LoadingState,
  ProfileType,
  TenantToken,
} from '../../state/app-state';
import { UserProfileService } from '../../services/user-profile.service';
import { AppStateService } from '../../services/app-state.service';
import { FirebaseService } from '../../services/firebase.service';
import { SessionService } from '../../services/session.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  private authUser: firebase.default.User | null = null;
  public isLogout: boolean = false;

  constructor(
    private router: Router,
    private auth: AngularFireAuth,
    private sessionService: SessionService,
    private firebaseService: FirebaseService,
    private appStateService: AppStateService,
    private userProfileService: UserProfileService
  ) {
    this.subscriptions.add(
      this.appStateService.appState$.subscribe((state: AppState) => {
        this.isLogout = state.isLogout;
      })
    );
  }

  async ngOnInit() {
    this.subscriptions.add(
      this.auth.user.subscribe(async (user) => {
        this.authUser = user;
        if (!user) {
          if (!this.isLogout) {
            this.appStateService.startLoading(LoadingState.LoginLoading);
            await this.initializeLogin();
            this.appStateService.stopLoading(LoadingState.LoginLoading);
          }
        }
      })
    );
  }

  async initializeLogin() {
    const { domain, realmName, clientId, clientSecret, redirectUri } = environment.keycloak || {};
    // Get the origin of the current URL (protocol + host)
    const originUrl = window.location.origin;

    // Get the current URL
    const currentUrl = new URL(window.location.href);

    // Construct the keycloakLoginUrl with the extracted rootUrl
    const keycloakLoginUrl = `https://${domain}/auth/realms/${realmName}/protocol/openid-connect/auth?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}/login?root_url=${originUrl}&scope=openid`;

    // Check if code, session_state and rootUrl are present in the URL parameters and have a value
    const code = currentUrl.searchParams.get('code') || '';
    const session_state = currentUrl.searchParams.get('session_state') || '';
    const root_url = currentUrl.searchParams.get('root_url') || '';

    if (!code || !session_state) {
      // If code and session_state are not present or don't have a value, redirect to keycloakLoginUrl
      window.location.href = keycloakLoginUrl;
    } else {
      if (originUrl !== root_url) {
        window.location.href = `${root_url}/login?root_url=${root_url}&session_state=${session_state}&code=${code}`;
      } else {
        // Make a POST request to Keycloak's token endpoint to exchange the code for tokens
        const tokenUrl = `https://${domain}/auth/realms/${realmName}/protocol/openid-connect/token`;
        const body = {
          grant_type: 'authorization_code',
          client_id: clientId,
          client_secret: clientSecret,
          code,
          redirect_uri: `${redirectUri}/login?root_url=${root_url}`,
        };

        try {
          const { firebaseToken, keycloakTokens } = await this.firebaseService.getAndCreateTokens({ tokenUrl, body });

          if (!firebaseToken) {
            throw new Error('Failed to retrieve Firebase token.');
          }
          if (!keycloakTokens) {
            throw new Error('Failed to retrieve Keycloak tokens.');
          }

          console.log('Tokens received:', { firebaseToken, keycloakTokens });

          await this.handleReceivedTokens(firebaseToken, keycloakTokens);
        } catch (error) {
          console.error('Error during token processing:', error);
          // Optionally: Show user-friendly error message to the user via UI.
        }
      }
    }
  }

  async handleReceivedTokens(
    firebaseToken: string,
    keycloakTokens: KeycloakTokens
  ) {
    try {
      const idToken = await this.firebaseService.signInWithCustomToken(
        firebaseToken
      );
      console.log('Successfully authenticated with Firebase.');

      // get all tenants tokens and profiles
      const profiles: ProfileType[] | undefined =
        await this.getTokensForTenants(
          keycloakTokens.access_token,
          keycloakTokens.refresh_token
        );

      // If profiles is an empty array, stop further execution and show a message.
      if (profiles && profiles.length === 0) {
        console.error('No profiles found, stopping further execution.');
        await this.sessionService.logoutUser('no-valid-company');
        return;
      }

      // If profiles is undefined, it means there was an error and we should not proceed.
      if (!profiles) {
        console.error('Error fetching profiles, stopping further execution.');
        return;
      }

      const data = {
        idToken,
        uid: keycloakTokens.additionalInfo.uid,
        currentUserProfile: keycloakTokens.additionalInfo,
        profiles,
      };
      const tenants = await this.firebaseService.setUserClaimsAndProfiles(data);

      // Reload the current user to get the updated ID token with new claims
      const user = await this.auth.currentUser;
      if (user) {
        await user.reload();
        const updatedIdToken = await user.getIdToken(true); // Force refresh the ID token to get the latest claims

        if (!!this.authUser) {
          // load user profile list
          this.userProfileService.initFromUser(this.authUser);
        }
      }
    } catch (error) {
      console.error('Error handling received tokens:', error);
      // Optionally: Show user-friendly error message to the user via UI.
    }
  }

  async getTokensForTenants(
    userToken: string,
    refreshToken: string
  ): Promise<ProfileType[] | undefined> {
    const { domain, realmName, clientId, clientSecret } = environment.keycloak || {};
    const refreshUrl = `https://${domain}/auth/realms/${realmName}/protocol/openid-connect/token`;
    const body = {
      grant_type: 'refresh_token',
      client_id: clientId,
      client_secret: clientSecret,
      refresh_token: refreshToken,
    };
    const tenantTokens: { [tenantId: string]: KeycloakTokens } = {};
    const profiles: ProfileType[] = [];

    try {
      const refreshedTokens: TenantToken[] =
        await this.firebaseService.processTenantTokens({
          refreshUrl,
          body,
          userToken,
        });
      const currentTime = new Date().getTime();
      for (const token of refreshedTokens) {
        tenantTokens[token.additionalInfo.tenantId] = {
          ...token,
          receivedAt: currentTime,
        };
        profiles.push(token.profile);
      }
    } catch (error: any) {
      console.error(
        `Error processing token for tenants`,
        error?.details?.error
      );
      const errorCode = `${error?.details?.error}` || '500';
      await this.sessionService.logoutUser(errorCode);
      return;
    }

    this.sessionService.setTenantTokens(tenantTokens);
    console.log('Keycloak tokens stored in local storage.', tenantTokens);
    return profiles;
  }

  ngOnDestroy() {
    this.subscriptions?.unsubscribe();
  }
}
