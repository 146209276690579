import { Injectable } from '@angular/core';
import {
  collection,
  query,
  where,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  DocumentReference,
} from 'firebase/firestore';
import { Firestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { AppStateService } from './app-state.service';
import { AppState, ProfileType } from '../state/app-state';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  private appStateSubscription?: Subscription;
  private currentState!: AppState;

  constructor(
    private router: Router,
    private firestore: Firestore,
    private toastr: ToastrService,
    private translate: TranslateService,
    private appStateService: AppStateService,
  ) {
    this.appStateSubscription = this.appStateService.appState$.subscribe(
      (state) => {
        this.currentState = state;
      }
    );
  }

  private async getUserProfiles(userId: string) {
    const queryMultiDocuments = async (
      documentRefs: DocumentReference[]
    ): Promise<any[]> => {
      const q = query(
        collection(this.firestore, `profiles`),
        where(
          '__name__',
          'in',
          documentRefs.map((r) => r.id)
        )
      );
      const querySnapshot = await getDocs(q);
      const results = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return results;
    };
  
    const userRef = doc(this.firestore, 'users', userId);
    return getDoc(userRef).then((user) => {
      const userData = user.data();
      if (!userData || !userData['profiles']) {
        return []; // Return an empty array if profiles is not present or userData is undefined
      }
  
      const profileReferences = userData['profiles'] as DocumentReference[];
      return queryMultiDocuments(profileReferences);
    });
  }

  private async getProfileList(userId: string) {
    const profilesFromDb = await this.getUserProfiles(userId);
    const profiles: ProfileType[] = (profilesFromDb || []).map((profileFromDb) => {
      return {
        id: profileFromDb.id,
        tenantId: profileFromDb?.tenantId,
        companyId: profileFromDb?.companyId,
        name: profileFromDb.name,
        companyName: profileFromDb.name,
        klaraApiKey: profileFromDb.klaraApiKey,
      };
    });

    const selectedProfile = !this.currentState.profileId
      ? profiles[0]
      : (profiles || []).find(
          (profile) => profile.id === this.currentState.profileId
        ) || profiles[0];

    this.appStateService.updateState({
      currentProfile: selectedProfile,
      profiles: {
        ...this.currentState.profiles,
        isLoaded: true,
        list: profiles,
      },
    });

    // If no profileId specified, navigate to the first profile's orders.
    if (!this.currentState.profileId) {
      if (!selectedProfile) {
        this.appStateService.updateState({ profileId: null });
        this.router.navigate(['/orders']);
      } else {
        this.appStateService.updateState({ profileId: selectedProfile.id });
        this.router.navigate([selectedProfile.id, 'orders']);
      }
    }
  }

  public async initFromUser(user: firebase.default.User): Promise<void> {
    this.appStateService.updateState({
      currentUser: user,
      profiles: {
        ...this.currentState.profiles,
        isLoading: true,
      },
    });
    await this.getProfileList(user.uid).then(() => {
      this.appStateService.updateState({
        profiles: {
          ...this.currentState.profiles,
          isLoading: false,
        },
      });
    });
  }

  public updateProfile(updateData: Partial<ProfileType>): Promise<void> {
    const currentProfile = this.currentState.currentProfile as ProfileType;
    const profileRef = doc(this.firestore, 'profiles', currentProfile.id);
    let profileList = this.currentState.profiles.list as ProfileType[];

    const updatedProps: Partial<ProfileType> = {
      ...updateData,
    };
    profileList = profileList.map((profile) =>
      profile.id === currentProfile.id
        ? { ...profile, ...updatedProps }
        : profile
    );

    return updateDoc(profileRef, updatedProps)
      .then(() => {
        this.appStateService.updateState({
          currentProfile: { ...currentProfile, ...updatedProps },
          profiles: {
            ...this.currentState.profiles,
            list: profileList,
          },
        });
      })
      .catch((error) => {
        let errorMessage = error?.error?.error_message;
        if (!errorMessage) {
          this.translate.get('errMsg.updateProfileUpdateData').subscribe((res: string) => {
            errorMessage = res;
          });
        }
      
        this.translate.get('errMsg.error').subscribe((res: string) => {
          this.toastr.error(errorMessage, res);
        });
      });
  }

  ngOnDestroy(): void {
    this.appStateSubscription?.unsubscribe();
  }
}
