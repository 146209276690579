import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil, Subject } from 'rxjs';

import { AppStateService } from '../../../services/app-state.service';
import { AppState, OrderType, LoadingState } from '../../../state/app-state';
import { SessionService } from '../../../services/session.service';
import { OrderService } from '../../../services/order.service';
import { isObjectEmpty } from '../../shared/utils/helper';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit, OnDestroy {
  private profileId: string = '';
  private destroy$ = new Subject<void>();
  private currentState!: AppState;
  private skipResetCurrentOrder: boolean = false;
  public currentOrder: OrderType | null = null;
  public messagePdfsNames: string = '';
  public attachmentPdfsNames: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    public stateService: AppStateService,
    public orderService: OrderService,
  ) {}

  ngOnInit() {
    this.stateService.appState$
      .pipe(takeUntil(this.destroy$))
      .subscribe((appState: AppState) => {
        this.currentState = appState;
        this.currentOrder = appState.currentEditOrder as OrderType;
        if (!isObjectEmpty(this.currentOrder)) {
          const messagePdfsNames = (this.currentOrder.messagePdfs || []).map(
            (file) => file.name
          );
          const attachmentPdfsNames = (
            this.currentOrder.attachmentPdfs || []
          ).map((file) => file.name);

          if (messagePdfsNames.length) {
            this.messagePdfsNames = messagePdfsNames.join(', ') as string;
          }
          if (messagePdfsNames.length) {
            this.attachmentPdfsNames = attachmentPdfsNames.join(', ') as string;
          }
        }

        if (
          !isObjectEmpty(appState.currentProfile) &&
          this.profileId !== appState?.currentProfile?.id
        ) {
          this.profileId = appState.currentProfile!.id;
        }
      });
  }

  get isReadonly(): boolean {
    // throw 'TODO appState refactoring';
    return (
      this.stateService.appState$.value.currentEditOrder?.status !== 'Draft'
    );
  }

  get currentOrderHasValue(): boolean {
    return !isObjectEmpty(this.currentOrder);
  }

  cancel() {
    this.router.navigate([this.profileId, 'orders']);
  }

  async goBack() {
    this.stateService.startLoading(LoadingState.OrderLoading);
    if (this.isReadonly) {
      this.stateService.stopLoading(LoadingState.OrderLoading);
      this.router.navigate([this.profileId, 'monitoring']);
    }
    if (!this.isReadonly) {
      this.orderService.updateCurrentOrderEditStep('channel-check').then(() => {
        this.stateService.stopLoading(LoadingState.OrderLoading);
      });
      this.router.navigate(['../channel-check'], { relativeTo: this.route });
    }
  }

  async onNext() {
    this.skipResetCurrentOrder = true;
    this.stateService.startLoading(LoadingState.OrderLoading);
    const ePostToken = await this.sessionService.getSessionEpostToken(this.currentState?.currentProfile?.tenantId || '');
    const updatedOrderType: Partial<OrderType> = {
      status: 'PdfSplitting',
      currentEditStep: 'completed',
      ePostToken
    };
    await this.orderService.updateOrder(updatedOrderType).then(() => {
      this.stateService.stopLoading(LoadingState.OrderLoading);
      this.router.navigate(['../completed'], { relativeTo: this.route });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
    if (!this.skipResetCurrentOrder) {
      this.stateService.updateState({ currentEditOrder: null });
    }
  }
}
