<ng-container *ngIf="stateService.appState$ | async as appState">
    <div style="
            position: absolute;
            top: 160px;
            bottom: 49px;
            left: 68px;
            right: 68px;
            display: flex;
            flex-direction: column;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
            border-radius: 8px;">
            
        <!-- edit order subheader -->
        <!-- <app-order-name-subject-sub-header></app-order-name-subject-sub-header> -->
        
        <div style="
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 1rem;
            padding: 0 2rem;
        ">
            <div style="margin-right: 24px">
                <img src="assets/img/upload_img.svg" width="79.13px" height="68.35px">
            </div>
            <div style="
                display: flex;
                flex-direction: column;">
                <div class="title" style="font-size: 18px">{{"statement.letsStart" | translate}}</div>
                <div class="subtitle">{{"pages.docUpload.statement" | translate}}</div>
            </div>
        </div>

        <div style="
            flex-grow: 1;
            margin-left: 104px;
            display: grid;
            grid-template-columns: 3fr 1px 2fr;
            grid-template-rows: 60px auto 38px auto;
            gap: 10px;
            grid-auto-rows: min-content;
            padding: 0 2rem;
        ">
            <div style="grid-column: 2; grid-row: 1 / 4; width: 1px; background-color: #F4F4F4;"></div>
            <div style="
                grid-column: 1;
                grid-row: 1;
                margin-right: 65px;
                display: flex;
                flex-direction: column;
            ">
                <span class="title">
                    <strong>{{"pages.docUpload.mainDocument" | translate}}</strong>
                </span>
                <span class="subtitle">
                    {{"pages.docUpload.infoAbout" | translate}} <mat-icon>info</mat-icon>
                </span>
            </div>
            <div style="
                grid-column: 3;
                grid-row: 1;
                margin-left: 65px;
                display: flex;
                flex-direction: column;
            ">
                <span class="title">
                    <strong>{{"pages.docUpload.attachment" | translate}}</strong>
                </span>
                <span class="subtitle">
                    {{"pages.docUpload.enclosure" | translate}}
                </span>
            </div>
            <div style="
                grid-column: 1;
                grid-row: 2;
                margin-right: 65px;
                display: flex;
                flex-direction: column;
            ">
                <div 
                    style="
                        flex-grow: 1;
                        display: flex;
                        border: 2px dashed #d1d1d1;
                        border-radius: 8px;
                        min-height: 128px;
                    "
                    [ngClass]="{'drag-over': isPdfDragOver}"
                    (drop)="onFileDrop($event, 'pdf')"
                    (dragover)="onDragOver($event, 'pdf')"
                    (dragleave)="onDragLeave($event, 'pdf')"
                >
                    <label
                        style="
                            flex-grow: 1;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;"
                        for="mainDocumentsFileUpload">
                        <ng-template [ngIf]="checkAllPdfUploadsStatus === 'upload-all-complete'" [ngIfElse]="pdfsStatus">
                            <img
                                class="upload-icon"
                                width="24" 
                                height="24" 
                                src="assets/icons/upload-bottom.png" 
                                alt="upload icon" 
                                matTooltip="{{'pages.docUpload.uploadTooltip' | translate}}"
                            >
                            <span class="small-text">{{"pages.docUpload.selectOrDrag" | translate}}</span>
                        </ng-template>
                        <ng-template #pdfsStatus>
                            <div class="small-text">{{"pages.docUpload.uploading" | translate}}</div>
                            <div style="width: 40%">
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </div>
                        </ng-template>
                    </label>
                    <input #mainDocumentsFileUpload id="mainDocumentsFileUpload"
                        type="file" multiple
                        (change)="uploadFiles(mainDocumentsFileUpload)"
                        [disabled]="checkAllPdfUploadsStatus === 'uploading'"
                        accept=".pdf"
                        style="display: none;"
                        >
                </div>
            </div>
            <div 
                style="
                    grid-column: 3;
                    grid-row: 2;
                    margin-left: 65px;
                    margin-right: 65px;
                    display: flex;
                    flex-direction: column;
                "
            >
                <div 
                    style="
                        flex-grow: 1;
                        border: 2px dashed #d1d1d1;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        border-radius: 8px;
                        cursor: pointer;
                        min-height: 128px;
                    " 
                    [ngClass]="{'drag-over': isAttachmentDragOver}"
                    (drop)="onFileDrop($event, 'attachment')"
                    (dragover)="onDragOver($event, 'attachment')"
                    (dragleave)="onDragLeave($event, 'attachment')"
                >
                    <label
                        style="
                            flex-grow: 1;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;"
                        for="attachmentsFileUpload">
                        <ng-template [ngIf]="checkAllAttachmentUploadsStatus === 'upload-all-complete'" [ngIfElse]="attachmentsStatus">
                            <img
                                class="upload-icon"
                                width="24" 
                                height="24" 
                                src="assets/icons/upload-bottom.png" 
                                alt="upload icon" 
                                matTooltip="{{'pages.docUpload.uploadTooltip' | translate}}"
                            >
                            <span class="small-text">{{"pages.docUpload.selectOrDrag" | translate}}</span>
                        </ng-template>
                        <ng-template #attachmentsStatus>
                            <div class="small-text">{{"pages.docUpload.uploading" | translate}}</div>
                            <div style="width: 10rem">
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </div>
                        </ng-template>
                    </label>
                    <input #attachmentsFileUpload id="attachmentsFileUpload"
                        type="file" multiple
                        (change)="uploadFiles(attachmentsFileUpload, 'attachment')"
                        [disabled]="checkAllAttachmentUploadsStatus === 'uploading'"
                        accept=".pdf"
                        style="display: none;">
                </div>
            </div>
            <div class="toggle-docs-process-type-wrapper" *ngIf="uploadedPdfFileNamesArray.length && checkAllPdfUploadsStatus === 'upload-all-complete'">
                <span>{{"pages.docUpload.setNumOfRecipients" | translate}}</span>
                <mat-button-toggle-group class="custom-toggle-group" [value]="buttonGroupValue">
                    <mat-button-toggle 
                        matTooltip="{{'pages.docUpload.setProcesstypeMultiple' | translate}}"
                        value="single"
                        (click)="updateAllDocumentsProcessType('single')"
                    >
                        {{"pages.docUpload.single" | translate}}
                    </mat-button-toggle>
                    <mat-button-toggle 
                        matTooltip="{{'pages.docUpload.setProcesstypeSingle' | translate}}"
                        value="multiple"
                        (click)="updateAllDocumentsProcessType('multiple')"
                    >
                        {{"pages.docUpload.multiple" | translate}}
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <div class="uploaded-files-wrapper uploaded-pdf-wrapper">
                <div 
                    *ngFor="let uploadedFileName  of uploadedPdfFileNamesArray" 
                    style="
                        display: flex;
                        flex-direction: column;
                        width: 116px;
                        margin-right: 32px;
                    "
                >
                    <div style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 80px;
                        margin-left: 16px;
                        margin-right: 16px;
                        position: relative;
                    ">
                        <!-- Improvement: Non encoded document preview -->
                        <img src="assets/img/pdf-attchment-placeholder.png" width="100%">
                        <button 
                            mat-mini-fab class="remove-btn"
                            matTooltip="{{'pages.orderItem.delete' | translate}}"
                            *ngIf="!pdfUploadProgressMap.get(uploadedFileName.filename)?.isUploading" 
                            (click)="removeDoc(uploadedFileName.filename, 'pdf')"
                        >
                            <mat-icon>delete</mat-icon>
                        </button>
                        <mat-icon class="error-icon" *ngIf="uploadedFileName?.errorMessage" [matTooltip]="uploadedFileName.errorMessage!">
                            error
                        </mat-icon>
                    </div>
                    <mat-progress-bar
                        *ngIf="pdfUploadProgressMap.get(uploadedFileName.filename)?.isUploading"
                        style="width: 80px; margin: 0.5rem auto; "
                        mode="determinate"
                        [value]="pdfUploadProgressMap.get(uploadedFileName.filename)?.uploadProgress || 0"
                    >
                    </mat-progress-bar>
                    <span 
                        style="
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 12px;
                            margin: 0.5rem 0;
                        "
                        [matTooltip]="uploadedFileName.name"
                        matTooltipPosition="above"
                    >
                        <strong>{{ uploadedFileName.name }}</strong>
                    </span>
                    <mat-button-toggle-group class="custom-button-toggle-group"
                        *ngIf="!pdfUploadProgressMap.get(uploadedFileName.filename)?.isUploading"  
                        [(ngModel)]="uploadedFileName.processType"
                        (change)="onProcessTypeToggleChange($event, uploadedFileName.filename)"
                    >
                         <mat-button-toggle value="single" matTooltip="{{'pages.docUpload.setProcesstypeSingle' | translate}}">
                            {{"pages.docUpload.single" | translate}}
                        </mat-button-toggle>
                        <mat-button-toggle value="multiple" matTooltip="{{'pages.docUpload.setProcesstypeMultiple' | translate}}">
                            {{"pages.docUpload.multiple" | translate}}
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            </div>
            <div class="uploaded-files-wrapper uploaded-attachment-wrapper">
                <div 
                    *ngFor="let uploadedFileName  of uploadedAttachmentFileNamesArray" 
                    style="
                        display: flex;
                        flex-direction: column;
                        width: 116px;
                        margin-right: 32px;
                    "
                >
                    <div style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 80px;
                        margin-left: 16px;
                        margin-right: 16px;
                        position: relative;
                    ">
                        <!-- Improvement: Non encoded document preview -->
                        <img src="assets/img/pdf-attchment-placeholder.png" width="100%">
                        <button 
                            mat-mini-fab class="remove-btn"
                            matTooltip="{{'pages.orderItem.delete' | translate}}"
                            *ngIf="!attachmentUploadProgressMap.get(uploadedFileName.filename)?.isUploading" 
                            (click)="removeDoc(uploadedFileName.filename, 'attachment')"
                        >
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                    <mat-progress-bar
                        *ngIf="attachmentUploadProgressMap.get(uploadedFileName.filename)?.isUploading"
                        style="width: 80px; margin: 0 auto;"
                        mode="determinate"
                        [value]="attachmentUploadProgressMap.get(uploadedFileName.filename)?.uploadProgress || 0"
                    >
                    </mat-progress-bar>
                    <span 
                        style="
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 12px;
                            margin: 0.5rem 0;
                        "
                        [matTooltip]="uploadedFileName.name"
                        matTooltipPosition="above"
                    >
                        <strong>{{ uploadedFileName.name }}</strong>
                    </span>
                    <mat-button-toggle-group class="custom-button-toggle-group"
                        *ngIf="!attachmentUploadProgressMap.get(uploadedFileName.filename)?.isUploading"  
                        [(ngModel)]="uploadedFileName.attachmentType"
                        (change)="onAttachmentTypeToggleChange($event, uploadedFileName.filename)"
                    >
                    <mat-button-toggle value="prepend" matTooltip="{{'pages.docUpload.setPrepend'| translate}}">
                        {{"pages.docUpload.prepend" | translate}}
                        </mat-button-toggle>
                        <mat-button-toggle value="append" matTooltip=" {{'pages.docUpload.setAppend'| translate}}">
                            {{"pages.docUpload.append" | translate}}
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            </div>

        </div>

        <div style="padding: 0 2rem;">
            <div class="button-bar">
                <button mat-raised-button class="footer-btn" (click)="cancel()">{{"buttons.cancel" | translate}}</button>
                <!-- <button mat-raised-button class="footer-btn" (click)="goBack()">Back</button> -->
                <button mat-raised-button color="primary" class="footer-btn next-btn"
                    [disabled]="!nextStepAllowed" (click)="onNext()">{{"buttons.next" | translate}}
                </button>
            </div>
        </div>

    </div>
</ng-container>