<ng-container *ngIf="this.stateService.appState$ | async as appState">
        <div *ngIf="!!appState.currentEditOrder" style="
                        position: absolute;
                        top: 160px;
                        bottom: 49px;
                        left: 68px;
                        right: 68px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
                        border-radius: 8px;
                        padding: 30px 30px 0;
                ">
                <div class="completed-msg">
                        <img src="assets/img/completed.svg" height="151.31px" width="150.35px">
                        <div>{{"pages.completed.thankYou" | translate}} {{appState.currentUser?.displayName}}!</div>
                        <div>{{"pages.completed.orderReceived" | translate}} </div>
                        <div>{{"pages.completed.statusOfOrder" | translate}} </div>
                </div>

                <div class="button-bar">
                        <button mat-raised-button color="primary" class="footer-btn next-btn" (click)="goToOrders()">
                                {{"buttons.close" | translate}} 
                        </button>
                </div>
        </div>
</ng-container>