import { Injectable } from '@angular/core';
import {
  FormGroup,
  FormControl,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { AddressValidationFlag } from '../state/app-state';
import { TranslateService } from '@ngx-translate/core';


class FormControlWithWarnings extends FormControl {
  warnings?: { [key: string]: any } | undefined | null;
}

interface CustomAbstractControl extends AbstractControl {
  warnings?: any;
}

type FieldState = {
  hasCustomError: boolean;
  customErrorMessage: string;
  hasCustomWarning: boolean;
  customWarningMessage: string;
};

@Injectable({
  providedIn: 'root',
})
export class RecipientFormValidationService {
  private errorCodes = ['street', 'streetNo', 'streetSubNo', 'zip', 'city'];
  private flagFields = ['recipient', 'street', 'number', 'zip', 'city'];
  fieldFlagsStates: { [key: string]: FieldState } = {};

  constructor(
    private translateService: TranslateService
  ) {}

  initializeFieldFlagsStates(form: FormGroup): void {
    this.flagFields.forEach((field) => {
      this.updateState(form, field);
    });
  }

  updateState(form: FormGroup, fieldName: string): void {
    const control = form.get(fieldName) as CustomAbstractControl;
    if (!control) return;

    const state: FieldState = {
      hasCustomError: !!control.errors?.['customError'],
      customErrorMessage: (() => {
        const errorMessage = control.errors?.['customError']?.['message'];
        if (errorMessage === 'Invalid' || errorMessage === 'Invalid and Invalid') {
          return this.translateService.instant('invalidErrorMessage'); 
        }
        return errorMessage || ''; 
      })(),
      hasCustomWarning: !!control.warnings?.['customWarning'],
      customWarningMessage: control.warnings?.['customWarning']?.['message'] || '',
    };
    
    this.fieldFlagsStates[fieldName] = state;
  }

  setValidationFlags(
    errors: AddressValidationFlag[] = []
  ): Map<string, { code: string; message: string }> {
    const targetMap: Map<string, { code: string; message: string }> = new Map();

    for (const error of errors) {
      if (this.errorCodes.includes(error.code)) {
        targetMap.set(error.code, error);
      }
    }

    return targetMap;
  }

  optionalEmailValidation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        // if the control is empty, no need to validate
        return null;
      }
      // apply the email validator only if the control has a value
      return Validators.email(control);
    };
  }

  checkForErrors(
    errors: Map<string, any>,
    formControlName: string
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let errorMessages: string[] = [];

      if (formControlName === 'number') {
        const streetNoError = errors.get('streetNo');
        const streetSubNoError = errors.get('streetSubNo');

        if (streetNoError) {
          errorMessages.push(streetNoError.message);
        }

        if (streetSubNoError) {
          errorMessages.push(streetSubNoError.message);
        }

        if (errorMessages.length) {
          if (errorMessages.length === 1) {
            return { customError: { message: errorMessages[0] } };
          } else {
            return { customError: { message: errorMessages.join(' and ') } };
          }
        }
      } else {
        const error = errors.get(formControlName);

        if (error) {
          return { customError: { message: error.message } };
        }
      }

      return null;
    };
  }

  checkForWarnings(
    warnings: Map<string, any>,
    formControlName: string,
    control: FormControlWithWarnings
  ): void {
    let warningMessages: string[] = [];

    if (formControlName === 'number') {
      const streetNoWarning = warnings.get('streetNo');
      const streetSubNoWarning = warnings.get('streetSubNo');

      if (streetNoWarning) {
        warningMessages.push(streetNoWarning.message);
      }

      if (streetSubNoWarning) {
        warningMessages.push(streetSubNoWarning.message);
      }

      if (warningMessages.length) {
        if (warningMessages.length === 1) {
          control.warnings = { customWarning: { message: warningMessages[0] } };
        } else {
          control.warnings = {
            customWarning: { message: warningMessages.join(' and ') },
          };
        }
      }
    } else {
      const warning = warnings.get(formControlName);

      if (warning) {
        control.warnings = { customWarning: { message: warning.message } };
      }
    }
  }

  setControlWarnings(
    form: FormGroup,
    validationWarnings: Map<string, AddressValidationFlag>
  ): void {
    for (const key in form.controls) {
      const control = form.controls[key] as FormControlWithWarnings;
      this.checkForWarnings(validationWarnings, key, control);
    }
  }
}
