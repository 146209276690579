import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-chosen-channel',
  templateUrl: './chosen-channel.component.html',
  styleUrls: ['./chosen-channel.component.scss']
})
export class ChosenChannelComponent {
  @Input() chosenChannel: string = 'n/a';

}
