
export const assertNever = <T>(value: never, message?: string): T => {
    throw new Error(JSON.stringify(value) + " is not of type never." + ", message: " + message);
};

export const removeWhitespaceCharacters = (input: string): string | undefined => {
    if (input === null || input === undefined) { return undefined };
    return input.replace(/[\r\n\t]+/g, ' ').replace(/\s+/g, ' ').trim();
};

export type KeyValue<K, V> = { key: K, value: V };