<ng-container *ngIf="this.stateService.appState$ | async as appState">
	<div *ngIf="!!appState.currentEditOrder" style="
			position: absolute;
			top: 160px;
			bottom: 49px;
			left: 68px;
			right: 68px;
			display: flex;
			flex-direction: column;
			box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
			border-radius: 8px;">
		<!-- edit order subheader -->
        <app-order-name-subject-sub-header></app-order-name-subject-sub-header>

		<div style="
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			min-height: 200px;
			overflow: auto;
			margin-top: 1rem;
			position: relative;
			padding: 0 2rem;
		">
			<div style="margin-right: 24px">
				<img src="assets/img/upload_img.svg" width="79.13px" height="68.35px">
			</div>

			<div style="flex-grow: 1;">
				<app-delivery-type-items-table 
					[deliveryTypeLineItems]="(currentEditOrder?.deliveryTypeLineItems || [])"
					[totalNumberOfRecipients]="currentEditOrder?.numRecipients || 0"
					[totalAmountInCents]="currentEditOrder?.amountCents || 0"
					[printMetadata]="currentEditOrder?.printMetadata"
				></app-delivery-type-items-table>
			</div>
		</div> 

		<div class="preferred-channels">
			<div class="label">{{"pages.channelCheck.channelSelection" | translate}}</div>
			<div class="toggle-container">
				<mat-slide-toggle [(ngModel)]="showButtons" color="primary">
					{{ !showButtons ? 'Custom' : 'Auto' }}
				</mat-slide-toggle>
			</div>
		
			<div *ngIf="!showButtons">
				<div class="items-wrapper">
					<div class="item" *ngFor="let choice of preferredChannels; let i=index">
						<input type="checkbox" [(ngModel)]="choice.checked" (change)="onPreferredChannelsChange()">
						<ng-container [ngSwitch]="choice.value">
							<img 
							*ngSwitchCase="'ePost'" 
							class="icon" 
							src="assets/img/ePost.png" 
							alt="ePost" 
							matTooltip="ePost"
							/>
							<mat-icon 
							*ngSwitchCase="'print'"
							class="icon mat-icon print-icon"
							matTooltip="Print"
							>
							print
							</mat-icon>
							<!-- <mat-icon 
							*ngSwitchCase="'email'"
							class="icon mat-icon email-icon"
							matTooltip="Email"
							>
							alternate_email
							</mat-icon> -->
						</ng-container>
					</div>
				</div>
			</div>
		</div>

		<div style="
			flex-grow: 1;
			margin-left: 104px;
			display: grid;
			grid-template-columns: auto;
			gap: 10px;
			grid-auto-rows: min-content;
			overflow: auto;
			padding: 0 2rem;
		">
			<table mat-table [dataSource]="dataSource" matSort>
				<ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
					<th 
						style="padding: 0 10px;" mat-header-cell *matHeaderCellDef mat-sort-header 
						[ngClass]="['supported-channel', 'chosen-channel', 'subject'].includes(column.columnDef) ? 'centered' : ''"
					>
					{{ 'table.' + column.header | translate}}
					</th>
					<td 
						mat-cell *matCellDef="let row" 
						[ngClass]="['supported-channel', 'chosen-channel', 'subject'].includes(column.columnDef) ? 'centered' : ''"
					>
						<ng-container *ngIf="column.columnDef !== 'supported-channel' && column.columnDef !== 'chosen-channel' && column.columnDef !== 'subject'; else block">
							<ng-container *ngIf="column.columnDef !== 'recipient'">
								{{ column.cell(row) }}
							</ng-container>
							<ng-container *ngIf="column.columnDef === 'recipient'">
								<ng-container *ngIf="column.cell(row) as recipientInfo">
									<div *ngIf="isRecipientType(recipientInfo)">
										<div>{{recipientInfo.recipient}}</div>
										<div>{{recipientInfo.email}}</div>
									</div>
								</ng-container>
							</ng-container>
						</ng-container>
						<ng-template #block>
							<app-supported-channels *ngIf="column.columnDef === 'supported-channel'" [supportedChannels]="column.cell(row)"></app-supported-channels>
							<app-chosen-channel *ngIf="column.columnDef === 'chosen-channel'" [chosenChannel]="column.cell(row)"></app-chosen-channel>
							<app-subject-icon *ngIf="column.columnDef === 'subject'" [subjectInfo]="column.cell(row)"></app-subject-icon>
						</ng-template>
					</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
			</table>
		</div>

		<div style="padding: 0 2rem;">
			<div class="button-bar">
				<button mat-raised-button class="footer-btn" (click)="cancel()">{{"buttons.cancel" | translate}}</button>
				<!-- <button mat-raised-button class="footer-btn" (click)="goBack()">Back</button> -->
				<button mat-raised-button color="primary" class="footer-btn next-btn" (click)="onNext()">{{"buttons.next" | translate}}</button>
			</div>
		</div>

	</div>
</ng-container>