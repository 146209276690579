import {
  Component,
  OnInit,
  OnChanges,
  AfterViewInit,
  OnDestroy,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { KeyValue } from '@angular/common';
import {
  Observable,
  Subject,
  takeUntil,
  scan,
  map,
  EMPTY,
  shareReplay,
  merge,
  tap,
} from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { assertNever } from 'functions/src/util';

@Component({
  selector: 'preset-editor',
  templateUrl: './preset-editor.component.html',
  styleUrls: ['./preset-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class PresetEditorComponent implements  OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('page') parent!: ElementRef;
  @ViewChild('moveable') moveable!: any;
  @Input() rectangleInUnits = new Map<number, RectangleUnit>();
  @Input() pageDimensionInUnit: DimensionUnit = {
    widthUnit: 210,
    heightUnit: 297,
  };
  @Input() pdfScreenshotUrl: string = '';
  @Input() addressBoxLocation: 'left' | 'right' = 'left';
  @Input() showPerforatedLine: boolean = false;
  /**
   * The minimum dimension constraint of rectangle in unit.
   * 
   * Note. Can be set only once, further update to the constraint is not propagated.
   * @default - No constraint.
   */
  @Input() minimumConstraintDimensionsInUnit = new Map<number, Dimension<'Unit'>>();
  /**
   * The maximum dimension constraint of rectangle in unit.
   * 
   * Note. Just like minimum dimension, can be set only once,
   *  further update to the constraint is not propagated.
   * @default - No constraint.
   */
  @Input() maximumConstraintDimensionsInUnit = new Map<number, Dimension<'Unit'>>();

  // Improvement. Type definition.
  @Output() onRectangleResize = new EventEmitter<any>();
  private destroy$ = new Subject<void>();
  private targetRectangleChanged$ = new Subject<KeyValue<number, RectanglePx>>();
  private targetRectanglesChanged$ = new Subject<Map<number, RectangleUnit>>();

  // Internal Options
  readonly BorderSides = 2;
  readonly BorderSizeInPx = 0;
  bounds: any = {
    "left": this.BorderSizeInPx,
    "top": this.BorderSizeInPx,
    "right": -this.BorderSizeInPx,
    "bottom": -this.BorderSizeInPx,"position":"css"
  };

  /**
   * Observable to hold UI state.
   * Tag. Output.
   */
  renderState$: Observable<RenderState> = EMPTY;

  /**
   * Local copy of render state.
   * *Note: This is only for reference and should not be re-assigned / change.
   */
  renderStateCopy!: RenderState;

  constructor(private cd: ChangeDetectorRef) { }  

  ngOnInit(): void {
    // Set temporary constraint dimension
    const minDimensions = { widthUnit: 10, heightUnit: 10 };
    const maxDimensions = { widthUnit: 80, heightUnit: 30 };

    for (let i = 0; i < 4; i++) {
        this.minimumConstraintDimensionsInUnit.set(i, minDimensions);
        this.maximumConstraintDimensionsInUnit.set(i, maxDimensions);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['rectangleInUnits'] && !changes['rectangleInUnits'].isFirstChange()) {
      const prevValues: Map<number, RectangleUnit> = changes['rectangleInUnits'].previousValue;
      const newValues: Map<number, RectangleUnit> = changes['rectangleInUnits'].currentValue;
      if (JSON.stringify([...prevValues]) !== JSON.stringify([...newValues])) {
        this.targetRectanglesChanged$.next(newValues);
      }
    }
  }

  onDrag(event: any) {
    const { target, beforeTranslate, width, height } = event;
    const eventTargetElement = (target as HTMLElement);

    // *** TODO. constrain the x and y not to be negative.
    const targetRec: RectanglePx = {
      xPx: beforeTranslate[0],
      yPx: beforeTranslate[1],
      widthPx: width,
      heightPx: height,
    };

    const targetId = this.convertTargetElementIdToLookupId(eventTargetElement.id);

    this.targetRectangleChanged$.next({ key: targetId, value: targetRec });
  }

  onDragStart(event: any): void {
    const { set, stop, target } = event;
    const targetElement = target as HTMLElement;

    // Guard to early stop if there is no defined ID.
    if (targetElement?.id === null || targetElement?.id === undefined) { stop(); return; }

    const targetRectangle = this.getCurrentTargetRectanglePx(targetElement.id);

    // Guard if there is not target rectangle found.
    if (targetRectangle === undefined) { stop(); return; }

    const translateData = [targetRectangle?.xPx, targetRectangle?.yPx];

    // Set the current translate.
    set(translateData);
  }

  onDragEnd(event: any) {}

  onResizeStart(event: any) {
    const { setOrigin, dragStart, setMin, setMax, target } = event;

    const eventTargetElement = (target as HTMLElement);
    setOrigin(['%', '%']);

    // Guard to early stop if there is no defined ID.
    if (eventTargetElement?.id === null || eventTargetElement?.id === undefined) { stop(); return; }

    const targetRectangle = this.getCurrentTargetRectanglePx(eventTargetElement.id);

    // Guard if there is not target rectangle found.
    if (targetRectangle === undefined) { stop(); return; }

    if(dragStart) {
      const translateData = [targetRectangle?.xPx, targetRectangle?.yPx];
      // Set drag start translate.
      dragStart.set(translateData)
    }

    const parentRectPx = this.renderStateCopy.parentRectangleInPx;
    const currentTargetRectangleId = this.convertTargetElementIdToLookupId(eventTargetElement.id);

    // Constrain minimum dimension.
    const minimumDimensionConstraintInUnit = this.renderStateCopy.constraints
      .minimumDimensionsInUnit.get(currentTargetRectangleId);
    if (minimumDimensionConstraintInUnit) {
      const { widthPx, heightPx } = convertDimensionUnitToPixel(
        minimumDimensionConstraintInUnit, parentRectPx, this.pageDimensionInUnit);
      setMin([ widthPx, heightPx ]);
    }

    // Constrain maximum dimension.
    const maximumDimensionConstraintInUnit = this.renderStateCopy.constraints
      .maximumDimensionsInUnit.get(currentTargetRectangleId);
    if (maximumDimensionConstraintInUnit) {
      const { widthPx, heightPx }  = convertDimensionUnitToPixel(
        maximumDimensionConstraintInUnit, parentRectPx, this.pageDimensionInUnit);
      setMax([ widthPx, heightPx ]);
    }
  }

  onResize(event: any) {
    const { target, width, height, drag } = event;
    const beforeTranslate = drag.beforeTranslate;
    const eventTargetElement = (target as HTMLElement);

    // *** TODO. constrain the x and y not to be negative.
    const newTargetRectangle: RectanglePx = {
      xPx: beforeTranslate[0],
      yPx: beforeTranslate[1],
      widthPx: width,
      heightPx: height,
    };

    const targetId = this.convertTargetElementIdToLookupId(eventTargetElement.id);

    this.targetRectangleChanged$.next({ key: targetId, value: newTargetRectangle });
  }

  onResizeEnd(event: any) {}

  onBound(event: any) {}

  ngAfterViewInit(): void {
    // Setup on resize of the parent.
    const onParentResize$: Observable<RectanglePx> = new Observable<RectanglePx>(observer => {
      const resizeObserver = new ResizeObserver(entries => {
        const parentElem = this.parent.nativeElement;
        const parentRec: RectanglePx = {
          xPx: parentElem.offsetLeft,
          yPx: parentElem.offsetTop,
          widthPx: parentElem.offsetWidth - this.BorderSides * this.BorderSizeInPx,
          heightPx: parentElem.offsetHeight - this.BorderSides * this.BorderSizeInPx,
        };
        observer.next(structuredClone(parentRec));
      });

      resizeObserver.observe(this.parent.nativeElement);

      return () => {
        resizeObserver.unobserve(this.parent.nativeElement);
      }
    });
   
    const initialState: ComponentState = {
      pageDimensionInUnit: this.pageDimensionInUnit,
      parentRectangleInPxHistory: [],
      rectanglesInPx: new Map<number, RectanglePx>(),
      rectanglesInUnit: this.rectangleInUnits,
      constraints: {
        minimumDimensionsInUnit: structuredClone(this.minimumConstraintDimensionsInUnit),
        maximumDimensionsInUnit: structuredClone(this.maximumConstraintDimensionsInUnit)
      }
    };

    const componentState$ = merge(
      onParentResize$.pipe(map(x => <ComponentEvent>({ _tag: 'onParentResize', value: x }))),
      this.targetRectangleChanged$.pipe(map(x => <ComponentEvent>({ _tag: 'onTargetRectangleChanged', value: x }))),
      this.targetRectanglesChanged$.pipe(map(x => <ComponentEvent>({ _tag: 'onTargetRectanglesChanged', value: x })))
    )
    .pipe(
      scan(updateComponentState, initialState),
      shareReplay(),
    );

    // Map to rendering.
    this.renderState$ = componentState$.pipe(
      // Convert Component state to Render state.
      map(componentState => {
        const currentParentRectangleInPx = componentState.parentRectangleInPxHistory[1] ?? componentState.parentRectangleInPxHistory[0];
        const renderState: RenderState = {
          parentRectangleInPx: currentParentRectangleInPx!,
          rectanglesInPx: componentState.rectanglesInPx,
          rectanglesInUnit: componentState.rectanglesInUnit,
          constraints: componentState.constraints
        };
        return renderState;
      }),
      shareReplay()
    );

    this.renderState$
    .pipe(takeUntil(this.destroy$))
    .subscribe(renderState => {
      // Propagate copy of the render state.
      this.renderStateCopy = structuredClone(renderState);
      this.cd.detectChanges();
    });

    this.renderState$
    .pipe(
        debounceTime(200), 
        takeUntil(this.destroy$)
    )
    .subscribe(renderState => {
      const resizedRectangles = structuredClone(renderState);
      this.onRectangleResize.emit(resizedRectangles);
    });
  }

  trackByKey<TValue>(index: number, item: KeyValue<number, TValue>): number {
    return item.key;
  }

  private convertTargetElementIdToLookupId (elementId: string, prefix = "target"): number {
    return parseInt(elementId.replace(prefix, ""));
  };

  /**
   * Queries the current state and returns the RectanglePx using elementId.
   * @param elementId format: `target{number}`
   * @returns 
   */
  private getCurrentTargetRectanglePx(elementId: string): RectanglePx | undefined {
    // Get the current target rectangle in the state.
    return this.renderStateCopy.rectanglesInPx.get(this.convertTargetElementIdToLookupId(elementId));
  }

  removeSkeletonLoader() {
    this.parent.nativeElement.classList.remove('skeleton-loader');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

// Data types
type ComponentState = {
  pageDimensionInUnit: DimensionUnit,
  parentRectangleInPxHistory: [ ] | [ RectanglePx ] | [ RectanglePx, RectanglePx ],
  rectanglesInPx: Map<number, RectanglePx>,
  rectanglesInUnit: Map<number, RectangleUnit>,
  constraints: {
    minimumDimensionsInUnit: Map<number, DimensionUnit>,
    maximumDimensionsInUnit: Map<number, DimensionUnit>,
  },
}
type RenderState = {
  parentRectangleInPx: RectanglePx,
  rectanglesInPx: Map<number, RectanglePx>,
  rectanglesInUnit: Map<number, RectangleUnit>,
  constraints: {
    minimumDimensionsInUnit: Map<number, DimensionUnit>,
    maximumDimensionsInUnit: Map<number, DimensionUnit>,
  }
}
type ComponentEvent =
  | { _tag: 'onParentResize', value: RectanglePx }
  | { _tag: 'onTargetRectangleChanged', value: KeyValue<number, RectanglePx> }
  | { _tag: 'onTargetRectanglesChanged', value: Map<number, RectangleUnit> }

type Dimension<Unit extends string> = {
  [K in 'width' | 'height' as `${K}${Unit}`]: number;
};
type DimensionUnit = Dimension<'Unit'>;
type DimensionPx = Dimension<'Px'>;
type _2DShape<Unit extends string> = {
  [K in 'x' | 'y' | 'width' | 'height' as `${K}${Unit}`]: number;
};
type RectanglePx = _2DShape<'Px'>;
type RectangleUnit = _2DShape<'Unit'>;
type RectangleUnitLess = _2DShape<'UnitLess'>;

// -- Calculations
function calculateRelativeRectangle(oldParentRect: RectanglePx, innerRect: RectanglePx): RectangleUnitLess {
  return {
    xUnitLess: innerRect.xPx / oldParentRect.widthPx,
    yUnitLess: innerRect.yPx / oldParentRect.heightPx,
    widthUnitLess: innerRect.widthPx / oldParentRect.widthPx,
    heightUnitLess: innerRect.heightPx / oldParentRect.heightPx
  };
}

function transformRectangleRelativeToParent(newParentRect: RectanglePx, relativeRectangle: RectangleUnitLess): RectanglePx {
  return {
    xPx: relativeRectangle.xUnitLess * newParentRect.widthPx,
    yPx: relativeRectangle.yUnitLess * newParentRect.heightPx,
    widthPx: relativeRectangle.widthUnitLess * newParentRect.widthPx,
    heightPx: relativeRectangle.heightUnitLess * newParentRect.heightPx
  };
}

function calculateUnitRectangle(targetRec: RectanglePx, parent: RectanglePx, pageDimension: DimensionUnit): RectangleUnit {
  return {
    xUnit: (targetRec.xPx / parent.widthPx) * pageDimension.widthUnit,
    yUnit: (targetRec.yPx / parent.heightPx) * pageDimension.heightUnit,
    widthUnit: (targetRec.widthPx / parent.widthPx) * pageDimension.widthUnit,
    heightUnit: (targetRec.heightPx / parent.heightPx) * pageDimension.heightUnit
  };
}

function convertRectangleUnitToPixel (rectangleUnit: RectangleUnit, parentRectangle: RectanglePx, pageDimension: DimensionUnit): RectanglePx {
  return {
    xPx: rectangleUnit.xUnit * (parentRectangle.widthPx / pageDimension.widthUnit),
    yPx: rectangleUnit.yUnit * (parentRectangle.heightPx / pageDimension.heightUnit),
    widthPx: rectangleUnit.widthUnit * (parentRectangle.widthPx / pageDimension.widthUnit),
    heightPx: rectangleUnit.heightUnit * (parentRectangle.heightPx / pageDimension.heightUnit),
  };
}

function convertDimensionUnitToPixel (dimensionUnit: DimensionUnit, parentRectangle: RectanglePx, pageDimension: DimensionUnit): DimensionPx {
  return {
    widthPx: dimensionUnit.widthUnit * (parentRectangle.widthPx / pageDimension.widthUnit),
    heightPx: dimensionUnit.heightUnit * (parentRectangle.heightPx / pageDimension.heightUnit),
  };
}

// -- Utility function.
function mapValuesMap<K, A, B>(map: Map<K, A>, mappingFunction: (value: A) => B): Map<K, B> {

  const newMap = new Map();

  for (const [key, value] of map) {
    const newValue = mappingFunction(value);
    newMap.set(key, newValue);
  }

  return newMap;
}

// -- Update state
function handleParentResize(componentState: ComponentState, parentNewRectangleInPx: RectanglePx): ComponentState {
  const currentHistory = componentState.parentRectangleInPxHistory;

  if (currentHistory.length === 0) {
    const initialParentRectangleInPx = parentNewRectangleInPx;
    const initialRectanglesPx = mapValuesMap(componentState.rectanglesInUnit, rectangleUnit =>
      convertRectangleUnitToPixel(
        rectangleUnit, initialParentRectangleInPx, componentState.pageDimensionInUnit));

    return {
      ...componentState,
      parentRectangleInPxHistory: [initialParentRectangleInPx],
      rectanglesInPx: initialRectanglesPx
    };
  }

  if (currentHistory.length === 1) {
    return {
      ...componentState,
      parentRectangleInPxHistory: [currentHistory[0], parentNewRectangleInPx]
    };
  }

  const previousParentRectangleInPx = currentHistory[1];
  const updatedTargetRectanglesInPx = mapValuesMap(componentState.rectanglesInPx, (rectangle) => {
    const relativeRectangle = calculateRelativeRectangle(
      previousParentRectangleInPx,
      rectangle
    );
    const newTargetRectangle = transformRectangleRelativeToParent(
      parentNewRectangleInPx,
      relativeRectangle
    );

    return newTargetRectangle;
  });

  return {
    ...componentState,
    parentRectangleInPxHistory: [previousParentRectangleInPx, parentNewRectangleInPx],
    rectanglesInPx: updatedTargetRectanglesInPx
  };
}

function handleTargetRectangleChanged(componentState: ComponentState, targetRectangleInPxAndId: KeyValue<number, RectanglePx>): ComponentState {
  const updatedRectangles = new Map(componentState.rectanglesInPx);
  updatedRectangles.set(targetRectangleInPxAndId.key, targetRectangleInPxAndId.value);

  const currentParentRectangleInPx = componentState.parentRectangleInPxHistory[1] ?? componentState.parentRectangleInPxHistory[0];

  const updatedRectanglesInUnit = mapValuesMap(updatedRectangles, (rectangle) => {
    return calculateUnitRectangle(
      rectangle,
      currentParentRectangleInPx!,
      componentState.pageDimensionInUnit
    );
  });

  return {
    ...componentState,
    rectanglesInPx: updatedRectangles,
    rectanglesInUnit: updatedRectanglesInUnit
  };
}

function handleTargetRectanglesChanged(componentState: ComponentState, rectangleUnits: Map<number, RectangleUnit>): ComponentState {
  const latestParentRectangleInPx = componentState.parentRectangleInPxHistory[1] ?? componentState.parentRectangleInPxHistory[0];

  const updatedRectanglesPx = mapValuesMap(rectangleUnits, rectangleUnit =>
    convertRectangleUnitToPixel(
      rectangleUnit, latestParentRectangleInPx!, componentState.pageDimensionInUnit));

  return {
    ...componentState,
    rectanglesInUnit: rectangleUnits,
    rectanglesInPx: updatedRectanglesPx
  }
}

function updateComponentState(componentState: ComponentState, componentEvent: ComponentEvent): ComponentState {
  switch (componentEvent._tag) {
    case 'onParentResize':
      return handleParentResize(componentState, componentEvent.value);
    case 'onTargetRectangleChanged':
      return handleTargetRectangleChanged(componentState, componentEvent.value);
    case 'onTargetRectanglesChanged':
      return handleTargetRectanglesChanged(componentState, componentEvent.value);
    default:
      return assertNever(componentEvent);
  }
}
