import { Injectable } from '@angular/core';
import {
  Firestore,
  collection as collection$,
  collectionData,
  deleteDoc,
  deleteField,
  runTransaction,
} from '@angular/fire/firestore';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  writeBatch,
  updateDoc,
} from 'firebase/firestore';
import {
  distinctUntilChanged,
  filter,
  Observable,
  switchMap,
  Subscription,
  from,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import {
  AppState,
  OrderStatus,
  OrderType,
  PdfAnalysisResult,
} from '../state/app-state';
import { AppStateService } from './app-state.service';

@Injectable({
  providedIn: 'root',
})
export class PdfAnalysisResultService {
  private appStateSubscription?: Subscription;
  private currentState!: AppState;

  constructor(
    private firestore: Firestore,
    private toastr: ToastrService,
    private translate: TranslateService,
    private appStateService: AppStateService
  ) {
    this.appStateSubscription = this.appStateService.appState$.subscribe(
      (state) => {
        this.currentState = state;
      }
    );
  }

  async getPdfAnalysisResults(orderId: string): Promise<OrderType> {
    // Get a reference to the order document
    const orderRef = doc(this.firestore, 'orders', orderId);

    // First, fetch the order data
    const orderSnapshot = await getDoc(orderRef);
    if (!orderSnapshot.exists()) {
      throw new Error('Order not found');
    }
    const orderData = orderSnapshot.data() as OrderType;

    // Now, get a reference to the pdfAnalysisResults sub-collection
    const pdfAnalysisResultsRef = collection(orderRef, 'pdfAnalysisResults');

    // Fetch the pdfAnalysisResults data
    const pdfAnalysisResults$ = from(getDocs(pdfAnalysisResultsRef)).pipe(
      map((querySnapshot) =>
        querySnapshot.docs.map((doc) => {
          const data = doc.data();
          const id = doc.id;
          return {
            id,
            numPages: data['numPages'] ?? 0,
            startsAtPage: data['startsAtPage'] ?? 0,
            pages: data['pages'] ?? [],
            messages: data['messages'] ?? [],
          };
        })
      )
    );

    return new Promise((resolve, reject) => {
      pdfAnalysisResults$.subscribe(
        (pdfAnalysisResults) => {
          // Merge the order data with the pdfAnalysisResults
          const updatedOrder = {
            ...orderData,
            pdfAnalysisResults,
          };

          // Update the state with the new order data
          this.appStateService.updateState({
            orders: {
              ...this.currentState.orders,
              list: new Map(this.currentState.orders.list).set(
                orderId,
                updatedOrder
              ),
            },
          });

          resolve(updatedOrder);
        },
        (error) => {
          let errorMessage = error?.error?.error_message;
          if (!errorMessage) {
            this.translate
              .get('errMsg.pdfAnalysisResults')
              .subscribe((res: string) => {
                errorMessage = res;
              });
          }

          this.translate.get('errMsg.error').subscribe((res: string) => {
            this.toastr.error(errorMessage, res);
          });

          reject();
        }
      );
    });
  }

  ordersWithOrderStatus$(
    orderStatuses: OrderStatus[]
  ): Observable<OrderType[]> {
    return this.appStateService.appState$.pipe(
      // Ensure to react only if the current user and current profile are both available.
      filter(
        (state) =>
          !(
            state.currentUser === null ||
            state.currentUser === undefined ||
            state.currentProfile === null ||
            state.currentProfile === undefined
          )
      ),

      // Current Appstate is mutable usage of `structuredClone` to ensure that
      //  the AppState refers to new references.
      //  Then select just the User ID and Profile ID.
      map((appState) => {
        return {
          userId: structuredClone(appState.currentUser!.uid),
          profileId: structuredClone(appState.currentProfile!.id),
        };
      }),

      // Allow only to emit if there is a change in either User or Profile.
      distinctUntilChanged(
        (previous, current) =>
          previous.userId === current.userId &&
          previous.profileId === current.profileId
      ),

      // Using Profile ID, query the orders collection.
      switchMap(({ profileId }) => {
        const collectionRef = collection$(this.firestore, 'orders');
        const collectionQuery = query(
          collectionRef,
          where('profileId', '==', profileId),
          where('status', 'in', orderStatuses)
        );
        return collectionData(collectionQuery, { idField: 'id' }) as Observable<
          OrderType[]
        >;
      })
    );
  }

  subscribeOrdersPdfAnalysis() {
    const switchToDraftOnDoneOfRealtimeAnalysis = (
      order: OrderType
    ): OrderType => {
      if (
        order.status === 'PDFAnalysis' &&
        order.pdfAnalysisStatus?.stage === 'DONE'
      ) {
        // TODO. update also the current edit step not only the status. @see ChannelDetermination condition.
        // Update the order to status 'Draft' manually.
        const draftOrder: OrderType = { ...order, status: 'Draft' };
        return draftOrder;
      }
      if (
        order.status === 'ChannelDetermination' &&
        !(
          order.channelDeterminationStatus === null ||
          order.channelDeterminationStatus === undefined
        )
      ) {
        // Update the order to status 'Draft' manually and current edit step to channel-check.
        const fullyProcessed =
          order.channelDeterminationStatus.currentMessageNum ===
          order.channelDeterminationStatus.numMessages;
        const draftOrder: OrderType = {
          ...order,
          status: 'Draft',
          currentEditStep: 'channel-check',
        };
        return fullyProcessed ? draftOrder : order;
      }
      return order;
    };

    return this.ordersWithOrderStatus$([
      'PDFAnalysis',
      'ChannelDetermination',
      'PdfSplitting',
      'MessageProduction',
      'InProduction',
      'Done',
      'Error',
      'PDFMismatch',
    ]).subscribe((updatedOrders) => {
      const orderList = this.currentState.orders.list;
      const orderListDone = this.currentState.orders.listDone;
      // Update the orders list in the appState. O(1) lookup, O(n) update.
      updatedOrders
        .map(switchToDraftOnDoneOfRealtimeAnalysis)
        .forEach((order) => {
          if (order.status === 'Done') {
            // If the order is in the list, remove it from there and add to listDone
            if (orderList.has(order.id)) {
              orderList.delete(order.id);
            }
            orderListDone.set(order.id, order);
          } else {
            orderList.set(order.id, order);
          }
        });

      // But allow the UI to update first. Let it breathe!
      setTimeout(() => {
        this.appStateService.updateState({
          orders: {
            ...this.currentState.orders,
            list: orderList,
            listDone: orderListDone,
          },
        });
      }, 0);
    });
  }

  async deleteMessage(docId: string = '', messageId: string = '') {
    if (!docId || !messageId) {
      let errorMessage = '';
      if (!errorMessage) {
        this.translate.get('errMsg.docResponseId').subscribe((res: string) => {
          errorMessage = res;
        });
      }
      this.translate.get('errMsg.error').subscribe((res: string) => {
        this.toastr.error(errorMessage, res);
      });

      return Promise.reject(errorMessage);
    }
    const currentOrderId = this.currentState.currentEditOrder?.id;
    if (!currentOrderId) {
      let errorMessage = '';
      if (!errorMessage) {
        this.translate
          .get('errMsg.noCurrentOrderId')
          .subscribe((res: string) => {
            errorMessage = res;
          });
      }
      this.translate.get('errMsg.error').subscribe((res: string) => {
        this.toastr.error(errorMessage, res);
      });

      return Promise.reject(errorMessage);
    }

    // Run transaction read pdf analysis result and read and update the Orders.numRecipients
    return await runTransaction(this.firestore, async (transaction) => {
      // Update PDF Analysis results.
      const pdfAnalysisResultDocRef = doc(
        this.firestore,
        `orders/${currentOrderId}/pdfAnalysisResults/${docId}`
      );
      const pdfAnalysisResultDocSnapshot = await getDoc(
        pdfAnalysisResultDocRef
      );
      const pdfAnalysisResult =
        pdfAnalysisResultDocSnapshot.data() as PdfAnalysisResult;
      const updatedMessages = pdfAnalysisResult.messages.map((message) => {
        if (message.id === messageId) {
          return {
            ...message,
            isDeleted: true,
          };
        }
        return message;
      });
      transaction.update(pdfAnalysisResultDocRef, {
        messages: updatedMessages,
      });

      // Update Order number of recipient.
      const orderDocRef = doc(this.firestore, `orders/${currentOrderId}`);
      const orderDocSnapshot = await getDoc(orderDocRef);
      const order = orderDocSnapshot.data() as OrderType;
      const updatedNumberOfRecipient = order.numRecipients - 1;
      transaction.update(orderDocRef, {
        numRecipients: updatedNumberOfRecipient,
      });

      return updatedMessages;
    })
      .then((updatedMessages) => {
        // Update AppState.
        // Update this.currentState with the updated message
        const orderList = this.currentState.orders.list;
        const currentEditOrder = {
          ...this.currentState.currentEditOrder!,
          numRecipients: this.currentState.currentEditOrder!.numRecipients - 1,
          pdfAnalysisResults: (
            this.currentState.currentEditOrder?.pdfAnalysisResults || []
          ).map((pdfAnalysisResult) => {
            if (pdfAnalysisResult.id === docId) {
              return {
                ...pdfAnalysisResult,
                messages: updatedMessages,
              };
            }
            return pdfAnalysisResult;
          }),
        };
        orderList.set(currentEditOrder.id, currentEditOrder);

        this.appStateService.updateState({
          currentEditOrder,
          orders: {
            ...this.currentState.orders,
            list: orderList,
          },
        });
      })
      .catch((error) => {
        let errorMessage = error?.error?.error_message;
        if (!errorMessage) {
          this.translate
            .get('errMsg.runningTransaction')
            .subscribe((res: string) => {
              errorMessage = res;
            });
        }
        this.translate.get('errMsg.error').subscribe((res: string) => {
          this.toastr.error(errorMessage, res);
        });
      });
  }

  async updatePdfAnalysisResultsDocs(
    oderId: string,
    updates: {
      docId: string;
      updatedPdfAnalysisResult: Partial<PdfAnalysisResult>;
    }[]
  ): Promise<boolean> {
    if (!oderId || !updates || updates.length === 0) {
      let errorMessage = '';
      if (!errorMessage) {
        this.translate.get('errMsg.orderIdUpdates').subscribe((res: string) => {
          errorMessage = res;
        });
      }
      this.translate.get('errMsg.error').subscribe((res: string) => {
        this.toastr.error(errorMessage, res);
      });
      return Promise.reject(errorMessage);
    }

    const batch = writeBatch(this.firestore);

    for (const update of updates) {
      const pdfAnalysisResultDocRef = doc(
        this.firestore,
        `orders/${oderId}/pdfAnalysisResults/${update.docId}`
      );
      batch.update(pdfAnalysisResultDocRef, update.updatedPdfAnalysisResult);
    }

    return batch
      .commit()
      .then(() => {
        return true;
      })
      .catch((error) => {
        let errorMessage = error?.error?.error_message;
        if (!errorMessage) {
          this.translate
            .get('errMsg.updatePdfAnalysis')
            .subscribe((res: string) => {
              errorMessage = res;
            });
        }
        this.translate.get('errMsg.error').subscribe((res: string) => {
          this.toastr.error(errorMessage, res);
        });
        return false;
      });
  }

  async updatePdfAnalysisResultDoc(
    oderId: string,
    docId: string,
    updatedPdfAnalysisResult: Partial<PdfAnalysisResult>
  ): Promise<boolean> {
    if (!docId || !oderId) {
      let errorMessage = '';
      if (!errorMessage) {
        this.translate.get('errMsg.docOrderId').subscribe((res: string) => {
          errorMessage = res;
        });
      }
      this.translate.get('errMsg.error').subscribe((res: string) => {
        this.toastr.error(errorMessage, res);
      });
      return Promise.reject(errorMessage);
    }
    const pdfAnalysisResultDocRef = doc(
      this.firestore,
      `orders/${oderId}/pdfAnalysisResults/${docId}`
    );

    return updateDoc(pdfAnalysisResultDocRef, updatedPdfAnalysisResult)
      .then(() => {
        return true;
      })
      .catch((error) => {
        let errorMessage = error?.error?.error_message;
        if (!errorMessage) {
          this.translate
            .get('errMsg.updatePdfAnalysis')
            .subscribe((res: string) => {
              errorMessage = res;
            });
        }
        this.translate.get('errMsg.error').subscribe((res: string) => {
          this.toastr.error(errorMessage, res);
        });
        return false;
      });
  }

  async deletePropsWhenMismatched(orderId: string) {
    try {
      // References
      const ordersCollectionRef = collection(this.firestore, 'orders');
      const documentRef = doc(ordersCollectionRef, orderId);
      const subCollectionRef = collection(
        this.firestore,
        'orders',
        orderId,
        'pdfAnalysisResults'
      );
      const subCollectionSnapshot = await getDocs(subCollectionRef);

      // Delete all documents in the 'pdfAnalysisResults' sub-collection
      try {
        const deletePromises = subCollectionSnapshot.docs.map((doc) =>
          deleteDoc(doc.ref)
        );
        await Promise.all(deletePromises);
      } catch (error) {
        console.error('Error deleting documents: ', error);
      }

      // Prepare update data: delete specific properties and update 'currentEditStep'
      const updateData = {
        pdfAnalysisStatus: deleteField(),
        pdfAnalysisProcessing: deleteField(),
        pdfAnalysisPageReferences: deleteField(),
        orderInitializerProcessing: deleteField(),
        orderInitializerStatus: deleteField(),
        currentEditStep: 'doc-upload',
      };

      // Update the Firestore document
      await updateDoc(documentRef, updateData);

      // Update local state
      const existingOrder = this.currentState.orders.list.get(
        orderId
      ) as OrderType;
      const localUpdateData = {
        ...existingOrder,
        currentEditStep: 'doc-upload',
      } as OrderType & { [key: string]: any };

      if (localUpdateData['pdfAnalysisStatus'])
        delete localUpdateData['pdfAnalysisStatus'];
      if (localUpdateData['pdfAnalysisProcessing'])
        delete localUpdateData['pdfAnalysisProcessing'];
      if (localUpdateData['pdfAnalysisPageReferences'])
      delete localUpdateData['pdfAnalysisPageReferences'];
      if (localUpdateData['orderInitializerProcessing'])
      delete localUpdateData['orderInitializerProcessing'];
      if (localUpdateData['orderInitializerStatus'])
      delete localUpdateData['orderInitializerStatus'];

      const orderList = this.currentState.orders.list;
      orderList.set(orderId, localUpdateData);

      this.appStateService.updateState({
        currentEditOrder: localUpdateData,
        orders: {
          ...this.currentState.orders,
          list: orderList,
        },
      });
    } catch (error: any) {
      let errorMessage = error?.error?.error_message;
      if (!errorMessage) {
        errorMessage = await this.translate
          .get('errMsg.delDocProps')
          .toPromise();
      }
      const errorTitle = await this.translate.get('errMsg.error').toPromise();
      this.toastr.error(errorMessage, errorTitle);
    }
  }

  ngOnDestroy(): void {
    this.appStateSubscription?.unsubscribe();
  }
}
