import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";


@Component({
  selector: 'app-loading',
  template: `
	  <div mat-dialog-content>
	    <div style="width: 40vw">
		    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
	    </div>
	  </div>
    `
})
export class LoadingComponent {
  constructor(
    public dialogRef: MatDialogRef<LoadingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
}
