import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AppStateService } from '../../../services/app-state.service';
import { OrderService } from '../../../services/order.service';
import { OrderType } from '../../../state/app-state';

@Injectable({
  providedIn: 'root',
})
export class OrderStatusGuard implements CanActivate {
  constructor(
    private router: Router,
    private stateService: AppStateService, 
    private orderService: OrderService, 
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const profileId = route.params['profileId'] || (route.parent && route.parent.params['profileId']);
    const parentRoute = route.parent;
    const urlParts = state.url.split('/');
    const lastPart = urlParts[urlParts.length - 1];
    const secondFromLastPart = urlParts[urlParts.length - 2];

    if (!parentRoute) {
      this.router.navigate([profileId, 'orders']);
      console.error('Order ID is not available.');
      return false;
    }

    const orderId = parentRoute.paramMap.get('orderId');

    // special handling for doc-upload
    if (orderId === 'null' && lastPart === 'doc-upload') {
      return true;
    }

    /**
     * if invalid orderId -> don't allow (false)
     * if draft -> proceed (true)
     * if done -> navigate summary
     *
     */

    if (orderId === null) {
      console.log('[debug] order id is null.');
      this.router.navigate([profileId, 'orders']);
      return false;
    }

    let currentEditOrder: OrderType | null =
      this.stateService.appState$.value.currentEditOrder;
      
    // Load Order, if there is no current order.
    if (!currentEditOrder) {
      console.log('[debug] order not found, loading order...');
      await this.orderService.getOrder(orderId);
      currentEditOrder = this.stateService.appState$.value.currentEditOrder;
    }

    if (!currentEditOrder) {
      console.log('[debug] order not found, navigating to orders.');
      this.router.navigate([profileId, 'orders']);
      return false;
    }

    if (currentEditOrder.status === 'Draft' && secondFromLastPart === 'edit') { 
      console.log('[debug] order on draft and viewing on of edit order pages..');
      console.log('[debug] currentEditOrder..',currentEditOrder.currentEditStep);
      switch (currentEditOrder.currentEditStep) {
        case 'order-basics':
          if (lastPart !== 'order-basics') {
            this.router.navigate([profileId, 'orders', orderId, 'edit', 'order-basics']);
            return false;
          }
          break;
        case 'doc-upload':
          if (lastPart !== 'doc-upload') {
            this.router.navigate([profileId, 'orders', orderId, 'edit', 'doc-upload']);
            return false;
          }
          break;
        case 'address-check':
          if (lastPart !== 'address-check') {
            this.router.navigate([profileId, 'orders', orderId, 'edit', 'address-check']);
            return false;
          }
          break;
        case 'channel-check':
          if (lastPart !== 'channel-check') {
            this.router.navigate([profileId, 'orders', orderId, 'edit', 'channel-check']);
            return false;
          }
          break;
        case 'summary':
          if (lastPart !== 'summary') {
            this.router.navigate([profileId, 'orders', orderId, 'edit', 'summary']);
            return false;
          }
          break;
        case 'completed':
          if (lastPart !== 'completed') {
            this.router.navigate([profileId, 'orders', orderId, 'edit', 'completed']);
            return false;
          }
          break;
      }
      return true;
    }

    if ((currentEditOrder.status === 'Done' || currentEditOrder.status === 'InProduction') && lastPart === 'summary') { 
      console.log('[debug] order on done and viewing summary..');
      return true;
    }

    if ((currentEditOrder.status === 'PdfSplitting' || currentEditOrder.status === 'Done' || currentEditOrder.status === 'InProduction') && lastPart === 'completed') { //currentEditOrder.status === 'Done'
      console.log('[debug] order on done, navigating to completed..');
      return true; 
    }

    if ((currentEditOrder.status === 'Done' || currentEditOrder.status === 'InProduction') && lastPart === 'orders' ) {
      console.log('[debug] order on done, navigating to summary..');
      this.router.navigate([profileId, 'orders', orderId, 'summary']);
      return true; 
    }
    if (currentEditOrder.status === 'PDFMismatch' ) { 
      console.log('[debug] order on PDFMismatch and viewing order-basics..');
      return true;
    }
    if (currentEditOrder.status === 'PDFPageLimit' ) { 
      console.log('[debug] order on PDFPageLimit and viewing doc-upload..');
      return true;
    }
    console.log('[debug] Route guard no condition met.');
    this.router.navigate([profileId, 'orders']);
    return false;
  }
}
