export const environment = {
  name: 'klara-prod',
  production: false,
  firebase: {
    apiKey: 'AIzaSyCw3u0jc1Hu86vk9Eo1mXCRKjXhRuZC2ZI',
    authDomain: 'klara-epost-prod-doc2epost.firebaseapp.com',
    projectId: 'klara-epost-prod-doc2epost',
    storageBucket: 'klara-epost-prod-doc2epost.appspot.com',
    messagingSenderId: '1012389404342',
    appId: '1:1012389404342:web:990e66252c1a5260d21017',
    measurementId: 'G-MMR041Q3ZD',
  },
  keycloak: {
    domain: 'login.klara.ch',
    realmName: 'klara',
    clientId: 'smartsend',
    clientSecret: 'f6def406-eae4-47e2-844a-5e16edd97ef3',
    redirectUri: 'https://smartsend.epost.ch',
  },
  ePostUrl: 'https://app.epost.ch'
};
