import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

import { PdfAnalysisPreset } from '../../../state/app-state';

@Component({
  selector: 'app-set-base-preset-dialog',
  template: `
    <div class="dialog-header">
      <img
        class="file-exchange-icon"
        alt="file-exchange"
        height="96"
        width="96"
        src="assets/icons/file-exchange.png"
      />
      <h2 mat-dialog-title>
        {{
          "presets.config.adopt" | translate
        }}
      </h2>
      <p>
        {{
          "presets.config.savesFrom" | translate
        }}
      </p>
    </div>
    <mat-dialog-content>
      <form [formGroup]="confirmForm" novalidate>
        <mat-radio-group formControlName="confirmation">
          <mat-radio-button value="no">{{"presets.no" | translate}}</mat-radio-button>
          <mat-radio-button value="yes">{{"presets.yes" | translate}}</mat-radio-button>
          <div class="select-preset-wrapper" *ngIf="confirmForm.value.confirmation === 'yes'">
            <span>{{"pages.orderBasics.configSet" | translate}}*</span>
            <app-pdf-analysis-preset-select
              [label]="'pages.orderBasics.configSet' | translate"
              [control]="basePresetControl"
              [useCustomField]="true"
            ></app-pdf-analysis-preset-select>
          </div>
        </mat-radio-group>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <div class="button-bar">
        <button mat-raised-button class="footer-btn" (click)="cancel()">
          {{ 'presets.buttonLabels.cancel' | translate }}
        </button>
        <button
          mat-raised-button
          color="primary"
          class="footer-btn next-btn"
          (click)="next()"
        >
          {{ 'presets.buttonLabels.next' | translate }}
        </button>
      </div>
    </mat-dialog-actions>
  `,
  styles: [
    `
      .dialog-header {
        padding-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        h2 {
          padding: 0;
        }
      }

      form {
        max-width: 312px;
        margin: 0 auto;
      }

      mat-radio-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
      }

      ::ng-deep .select-preset-wrapper {
        margin-top: 8px;
        padding: 0 2.5rem;
        .pdf-analysis-preset-select mat-form-field {
          margin-top: 8px;
        }
      }

      .button-bar {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        border-top: 1px solid #f4f4f4;
        padding-top: 15px;
        padding-bottom: 15px;

        .footer-btn {
          width: 160px;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        }

        .next-btn {
          border: 2px solid #ffffff;
        }
      }
    `,
  ],
})
export class SetBasePresetDialogComponent {
  public confirmForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<SetBasePresetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {
    this.confirmForm = this.fb.group({
      confirmation: new FormControl<'no' | 'yes'>('no'),
      basePreset: new FormControl<PdfAnalysisPreset | null>(null),
    });
  }

  get basePresetControl(): FormControl {
    return (
      (this.confirmForm.get('basePreset') as FormControl) || new FormControl()
    );
  }

  cancel() {
    this.dialogRef.close({ action: 'cancel' });
  }

  next() {
    this.dialogRef.close({ action: 'next', data: this.confirmForm.value });
  }
}
