import { Component, Input } from '@angular/core';
import { pdfViolationsFlag , OrderType } from '../../../../state/app-state';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-page-limit-flag',
  templateUrl: './page-limit-flag.component.html',
  styleUrls: ['./page-limit-flag.component.scss'],
})
export class PageLimitFlagComponent {
  @Input() order!: OrderType;
  errorMessage = '';
  
  constructor(
    private translate: TranslateService
  ){

  }

  formatTooltip(pdfNames: pdfViolationsFlag[]): string {
    this.translate.get('errMsg.overviewPageLimit').subscribe((res: string) => {
      this.errorMessage = res;
    });
  
    let tooltipContent = `${this.errorMessage}\n\n`;
  
    for (const pdf of pdfNames) {
      tooltipContent += `- ${pdf.name}: ${pdf.pageCount}\n`;
    }

    tooltipContent = tooltipContent.trim();
  
    return tooltipContent;
  }
  
  

}
