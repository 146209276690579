import {
  ValidityLevel,
  PdfAnalysisResult,
  AddressValidation,
  PdfAnalysisMessage,
  OrderLevelToMsgProps,
  AddressValidationFlag,
  addressValidationField,
  AddressValidityDetails,
  AddressValidationResult,
} from '../../../state/app-state';
import { isObjectEmpty } from './helper';

export function analyzeAddressValidationFlags(
  validationResult: AddressValidityDetails
): { errors: AddressValidationFlag[]; warnings: AddressValidationFlag[] } {
  if(!validationResult) return { errors: [], warnings: []};

  return Object.entries(validationResult)
    .filter(
      ([key]) => !['validationEngine', 'recipient', 'countryCode'].includes(key)
    )
    .reduce(
      (acc, [field, componentData]) => {
        const component = componentData as {
          validityLevel: ValidityLevel;
          validityMessage: string;
          suggestedCorrection: string;
        };

        if (!component) return acc;

        // handle warnings
        if (component.validityLevel === 'warning') {
          acc.warnings.push({
            code: field as addressValidationField,
            message: component.validityMessage,
          });
        }

        // handle error
        if (component.validityLevel === 'error') {
          acc.errors.push({
            code: field as addressValidationField,
            message: component.validityMessage,
          });
        }

        return acc;
      },
      {
        errors: [] as AddressValidationFlag[],
        warnings: [] as AddressValidationFlag[],
      }
    );
}

export function parseAddressValidationResultToAddress(
  validationResult: AddressValidityDetails
): AddressValidation {
  return {
    recipient: validationResult?.recipient?.suggestedCorrection || '',
    street: validationResult?.street?.suggestedCorrection || '',
    excessStreetText: validationResult?.street?.excessText || '',
    streetNo: validationResult?.streetNo?.suggestedCorrection || '',
    streetSubNo: validationResult?.streetSubNo?.suggestedCorrection || '',
    zip: validationResult?.zip?.suggestedCorrection || '',
    city: validationResult?.city?.suggestedCorrection || '',
    countryCode: validationResult?.countryCode?.suggestedCorrection || ''
  };
}

export function extractAddressInformation(
  message: PdfAnalysisMessage,
  orderLevel: OrderLevelToMsgProps,
  amount?: number,
  reference?: string
): AddressValidationResult {
  const {
    recipientDetails,
    overrideRecipientDetails,
    addressValidationResult,
    overrideAddressValidationResult,
  } = message || {};
  const { address: overrideAddress, email: overrideEmail } = overrideRecipientDetails || {};
  const recipientData = {
    address: overrideAddress ? overrideAddress : recipientDetails.address,
    email: overrideEmail ? overrideEmail : recipientDetails?.email,
  };
  const email = recipientData?.email?.emailAddress || '';
  const isEmailValid = recipientData?.email?.isEmailValid || false;
  const validationLevel = overrideAddressValidationResult?.validationLevel || addressValidationResult?.validationLevel || 1;
  let address: AddressValidationResult = {
    messageId: message.id,
    docId: message?.docId,
    numPages: message.numPages,
    startsAtPage: message.startsAtPage,
    qrInvoicePageNumber: message?.qrInvoicePageNumber,
    address: {
      recipient: '',
      street: '',
      number: '',
      streetNo: '',
      streetSubNo: '',
      zip: '',
      city: '',
      countryCode: '',
      ...(message.isSwissAddress ? { country: '' } : {}),
    },
    selectedChannel: message.selectedChannel,
    supportedChannels: message.supportedChannels,
    validationErrors: [],
    validationWarnings: [],
    emailInvalidWarning: '',
    amount,
    reference,
    email,
    isEmailValid,
    subject: message?.overrideSubject || orderLevel.subject,
    isSubjectOverride: Boolean(message?.overrideSubject),
    docType: message?.overrideDocType || orderLevel.docType,
    postalDeliveryTypeDomestic: message?.overridePostalDeliveryTypeDomestic || orderLevel.postalDeliveryTypeDomestic,
    postalDeliveryTypeInternational: message?.overridePostalDeliveryTypeInternational || orderLevel.postalDeliveryTypeInternational,
    printMetadata: {
      ...orderLevel.printMetadata,
      ...(message?.overridePrintMetadata || {}),
    },
    isSwissAddress: message.isSwissAddress,
    suggestedAddress: parseAddressValidationResultToAddress(addressValidationResult),
    validationEngine: addressValidationResult?.validationEngine,
    validationLevel,
    additionalBlankPage: message?.additionalBlankPage || false,
    isEdited: !isObjectEmpty(overrideRecipientDetails),
    klaraDeliveryStatus: message?.klaraDeliveryStatus,
    klaraDeliveryStatusMessage: message?.klaraDeliveryStatusMessage,
    attachmentNumPages: message?.attachmentNumPages || 0,
    qstatRelocationFlag: message?.qstatRelocationFlag,
    envelopeSize: message.envelopeSize,
  };

  // email invalid warnings handling
  if (address.email && !address.isEmailValid) {
    address.emailInvalidWarning = 'Invalid email address';
  }

  // if message error exist
  if (addressValidationResult?.error) {
    address.error = addressValidationResult.error;
  }

  // if no addressValidationResult
  if (!addressValidationResult) {
    address.error = {
      title: 'Unable to process address validation',
      message: 'Message has no addressValidationResult',
    };
  }

  if (!isObjectEmpty(overrideAddress)) {
    address = {
      ...address,
      address: { ...address.address, ...overrideAddress },
    };
    const addressValidation: any = !isObjectEmpty(overrideAddressValidationResult) ? overrideAddressValidationResult : addressValidationResult;
    const { errors: validationErrors = [], warnings: validationWarnings = [] } = analyzeAddressValidationFlags(addressValidation);
    address.validationErrors = validationErrors;
    address.validationWarnings = validationWarnings;

    return address;
  }

  address.address = { ...address.address, ...recipientData.address };
  const { errors: validationErrors = [], warnings: validationWarnings = [] } = analyzeAddressValidationFlags(addressValidationResult);
  address.validationErrors = validationErrors;
  address.validationWarnings = validationWarnings;

  // handling for undefined address recipient
  if (!address.address.recipient) {
    address.validationErrors.push({
      code: 'point_of_interest',
      message: 'address.recipient is undefined',
    });
  }

  return address;
}

export function getCurrentOrderPdfAnalysisResultMessage(
  pdfAnalysisResults: PdfAnalysisResult[] = [],
  docId: string,
  messageId: string
): PdfAnalysisMessage | null {
  let message = null;

  pdfAnalysisResults.forEach((result: PdfAnalysisResult) => {
    if (result.id === docId) {
      message = result.messages.find(
        (message: PdfAnalysisMessage) => message.id === messageId
      );
    }
  });

  return message;
}
