import { Component, EventEmitter, Output, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import {
  LanguageService,
  SupportedLanguages,
} from 'src/app/services/language.service';
import { AppState, OrderType, PdfAnalysisPreset } from '../../state/app-state';
import { AppStateService } from '../../services/app-state.service';
import { SessionService } from '../../services/session.service';
import { OrderService } from '../../services/order.service';
import { NgZone } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy {
  @Output() public onSelectCompany = new EventEmitter<{ profileId?: string }>();
  private appStateSubscription?: Subscription;
  private currentState!: AppState;
  private urlSubscription?: Subscription;
  private currentUrl: 'orders' | 'presets' = 'orders';
  private selectedProfileId: string = '';
  languages: { code: string; language: string }[] = [
    {
      code: 'de',
      language: '',
    },
    // Default language.
    {
      code: 'en',
      language: '',
    },
    {
      code: 'fr',
      language: '',
    },
    {
      code: 'it',
      language: '',
    },
  ];
  selectedLanguage: (typeof this.languages)[number];

  canChangeProfile = false;
  isMultipleProfiles = false;
  isNavExpanded: boolean = false;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AngularFireAuth,
    private orderService: OrderService,
    private translate: TranslateService,
    public stateService: AppStateService,
    private sessionService: SessionService,
    private languageService: LanguageService,
    private ngZone: NgZone
  ) {


    const foundLanguage = this.languages.find(
      (language) => language.code === this.languageService.currentLanguage
    );
    // Improvement. Store in environment variable.
    const defaultLanguage = this.languages.find(
      (language) => language.code === 'en'
    );

    // Set selected language.
    this.selectedLanguage = foundLanguage ?? defaultLanguage!;

    this.updateLanguages();

    this.route.data.subscribe((p) => {
      this.canChangeProfile = p['changeProfile'];
    });

    this.appStateSubscription = this.stateService.appState$.subscribe(
      (state) => {
        this.currentState = state;
        this.isNavExpanded = state.isNavExpanded;
        this.selectedProfileId = state?.currentProfile?.id || '';
        this.isMultipleProfiles = state.profiles.list.length > 1;
      }
    );

    this.urlSubscription = this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        if (event.url.includes('/presets')) {
          this.currentUrl = 'presets';
        } else if (event.url.includes('/orders')) {
          this.currentUrl = 'orders';
        }
      });
  }

  // setLanguage(language: (typeof this.languages)[number]) {
  //   this.selectedLanguage = language;
  //   this.translate.use(this.selectedLanguage.code);

  //   // Persist the current selected language.
  //   // Improvement. Remove force casting.
  //   this.languageService.currentLanguage = this.selectedLanguage.code as SupportedLanguages;
  // }

  switchLanguage(languageCode: (typeof this.languages)[number]) {
    this.ngZone.run(() => {
        this.translate.use(languageCode.code); 
      // Update selectedLanguage directly
      this.selectedLanguage = {
        code: languageCode.code,
        language: this.translate.instant(`languages.${languageCode.code}`)
      };   
      this.languages.forEach(lang => {
        lang.language = this.translate.instant(`languages.${lang.code}`);
      });   
      this.languageService.currentLanguage = this.selectedLanguage.code as SupportedLanguages;
    });
  }

  
  private updateLanguages() {
    // Update languages with translations
    this.languages.forEach(lang => {
      lang.language = this.translate.instant(`languages.${lang.code}`);
    });
  }
  
  async selectCompany(id: string) {
    const foundProfile = this.currentState.profiles.list.find(
      (profile) => profile.id === id
    );

    if (!foundProfile) {
      throw new Error(
        '[ERROR] Cannot find the appropriate profile of id: ' + id
      );
    }

    if (this.currentUrl === 'orders') {
      this.stateService.updateState({
        profileId: foundProfile.id,
        currentProfile: foundProfile,
        orders: {
          isLoading: false,
          isLoaded: false,
          list: new Map<string, OrderType>(),
          listDone: new Map<string, OrderType>(),
        },
      });
      this.router.navigate([foundProfile.id, 'orders']);
    }

    if (this.currentUrl === 'presets') {
      this.stateService.updateState({
        profileId: foundProfile.id,
        currentProfile: foundProfile,
        presets: {
          isLoading: false,
          isLoaded: false,
          list: new Map<string, PdfAnalysisPreset>(),
          listPublic: [],
        },
      });
      this.router.navigate([foundProfile.id, 'presets']);
    }
  }

  async logout() {
    await this.sessionService.logoutUser();
  }

  toggleSidenav() {
    this.stateService.updateState({
      ...this.currentState,
      isNavExpanded: !this.isNavExpanded,
    });
  }

  ngOnDestroy(): void {
    this.appStateSubscription?.unsubscribe();
    this.urlSubscription?.unsubscribe();
  }
}
