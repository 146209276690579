<div class="klara-validation-flags">
    <ng-container *ngIf="isFailed">
      <mat-icon class="klara-check-icon material-icons-outlined" matTooltip="{{deliveryMessage}}">
        error
      </mat-icon>
    </ng-container>
  
    <ng-container *ngIf="!isFailed">
      <ng-container *ngIf="pdfAnalysisMessage.klaraDeliveryStatus === 'DELIVERED'">
        <mat-icon class="klara-check-icon check-icon" matTooltip="{{deliveryMessage}}">
          check
        </mat-icon>
      </ng-container>
  
      <ng-container *ngIf="pdfAnalysisMessage.klaraDeliveryStatus === 'n/a'">
        <div class="klara-check-icon n-a-text">
          n/a
        </div>
      </ng-container>
    </ng-container>
  </div>
  