
<ng-container *ngIf="order.pdfViolations;">
    <mat-icon 
        matTooltipClass="break-line-tooltip" 
        [matTooltip]="formatTooltip(order.pdfViolations)"
    >
            error
    </mat-icon>
</ng-container>
    

