import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime, Subject, Subscription, takeUntil } from 'rxjs';

import {
  AppState,
  OrderStatus,
  OrderType,
  LoadingState,
} from '../../state/app-state';
import { PdfAnalysisResultService } from '../../services/pdf-analysis-result.service';
import { isObjectEmpty, isArrayEmpty } from '../shared/utils/helper';
import { AppStateService } from '../../services/app-state.service';
import { OrderService } from '../../services/order.service';

const OrderSortProperties = ['Date', 'Order name', 'Recipients'] as const;
type OrderSortPropertyType = (typeof OrderSortProperties)[number];
type OrderTransformationCriteria = {
  orderNameSearchKeyword: string;
  sort: {
    property: OrderSortPropertyType;
    direction: 'asc' | 'desc';
  };
};

@Component({
  selector: 'app-delivered-orders',
  templateUrl: './delivered-orders.component.html',
  styleUrls: ['./delivered-orders.component.scss'],
})
export class DeliveredOrdersComponent implements OnInit, OnDestroy {
  currentState!: AppState;
  orderSearchTerm$ = new Subject<void>();
  sorter: OrderSortPropertyType[] = ['Date', 'Order name', 'Recipients'];
  selectedSort: OrderSortPropertyType = 'Date';
  selectedSortDirection: 'asc' | 'desc' = 'desc';
  orderNameSearchKeyword = '';
  orders: OrderType[] = [];

  private destroy$ = new Subject<void>();
  private subscriptions: Subscription = new Subscription();
  private profileId: string = '';

  constructor(
    private router: Router,
    private orderService: OrderService,
    public stateService: AppStateService,
    private pdfAnalysisResultService: PdfAnalysisResultService
  ) {}

  async ngOnInit() {
    this.stateService.appState$
      .pipe(takeUntil(this.destroy$))
      .subscribe((appState: AppState) => {
        this.currentState = appState;
        if (
          !isObjectEmpty(appState.currentUser) &&
          !isObjectEmpty(appState.currentProfile) &&
          !appState.orders.isLoading &&
          !appState.orders.isLoaded
        ) {
          this.stateService.startLoading(LoadingState.OrdersLoading);
          this.orderService.loadDoneOrders().then(() => {
            this.stateService.stopLoading(LoadingState.OrdersLoading);
          });
        }

        this.orders = DeliveredOrdersComponent.transformDeliveredOrders(
          Array.from(this.currentState.orders.listDone.values()),
          this.getTransformationCriteria
        );

        if (appState.profileId && this.profileId !== appState?.profileId) {
          this.profileId = appState?.profileId;
        }
      });

    // Transform orders on search trigger with debounce.
    const OrderSearchDebounceTimeMs = 250;
    this.orderSearchTerm$
      .pipe(debounceTime(OrderSearchDebounceTimeMs), takeUntil(this.destroy$))
      .subscribe((_) => {
        this.orders = DeliveredOrdersComponent.transformDeliveredOrders(
          Array.from(this.currentState.orders.listDone.values()),
          this.getTransformationCriteria
        );
      });

    this.subscriptions.add(
      this.pdfAnalysisResultService.subscribeOrdersPdfAnalysis()
    );
  }

  private static sortOrders(
    orders: OrderType[],
    sortCriteria: OrderTransformationCriteria['sort']
  ) {
    const compareValues = (
      property: OrderSortPropertyType,
      a: OrderType,
      b: OrderType
    ): number => {
      switch (property) {
        case 'Date':
          return (
            a.createdAt.toDate().getTime() - b.createdAt.toDate().getTime()
          );
        case 'Order name':
          return a.name.localeCompare(b.name);
        case 'Recipients':
          return a.numRecipients - b.numRecipients;
        default:
          throw new Error(`Invalid sort property: ${property}`);
      }
    };
    const { property, direction } = sortCriteria;
    const comparisonFunction = (a: OrderType, b: OrderType) =>
      compareValues(property, a, b);

    if (direction === 'desc') {
      return orders.sort((a, b) => -comparisonFunction(a, b));
    }

    return orders.sort(comparisonFunction);
  }

  private static transformDeliveredOrders(
    orders: OrderType[],
    orderTransformationCriteria: OrderTransformationCriteria
  ) {
    const { orderNameSearchKeyword } = orderTransformationCriteria;
    const searchKeyword = orderNameSearchKeyword?.trim().toLocaleLowerCase();

    const filteredOrders = orders.filter((orderType) =>
      (orderType?.name || '').trim().toLocaleLowerCase().includes(searchKeyword)
    );

    const sortedOrders = this.sortOrders(
      filteredOrders,
      orderTransformationCriteria.sort
    );

    return sortedOrders;
  }

  get getTransformationCriteria(): OrderTransformationCriteria {
    return {
      orderNameSearchKeyword: this.orderNameSearchKeyword,
      sort: {
        property: this.selectedSort,
        direction: this.selectedSortDirection,
      },
    };
  }

  onSearchChange($event: any) {
    this.orderSearchTerm$.next();
  }

  sort(value: any) {
    this.selectedSortDirection = value;
    this.orders = DeliveredOrdersComponent.transformDeliveredOrders(
      Array.from(this.currentState.orders.listDone.values()),
      this.getTransformationCriteria
    );
  }

  selectSort(value: any) {
    this.selectedSort = value;
    this.orders = DeliveredOrdersComponent.transformDeliveredOrders(
      Array.from(this.currentState.orders.listDone.values()),
      this.getTransformationCriteria
    );
  }

  onNewOrder() {
    this.router.navigate([
      this.profileId,
      'orders',
      'null',
      'edit',
      'doc-upload',
    ]);
  }

  isNoSearchResult() {
    return isArrayEmpty(this.orders);
  }

  trackByOrderId(index: number, order: OrderType): string {
    return order.id;
  }

  onViewOrderSummary(orderId: string) {
    const currentEditOrder =
      this.currentState.orders.listDone.get(orderId) ?? null;
    this.stateService.updateState({ currentEditOrder });
    this.router.navigate([this.profileId, 'monitoring', orderId, 'summary']);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.subscriptions && !this.subscriptions.closed) {
      this.subscriptions.unsubscribe();
    }
    // reset orders.isLoaded to false
    if (this.currentState) {
      this.stateService.updateState({
        orders: { ...this.currentState.orders, isLoaded: false },
      });
    }
  }
}
