import { Directive, ElementRef, AfterViewInit } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[appEllipsisTooltip]',
  exportAs: 'appEllipsisTooltip',
})
export class EllipsisTooltipDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef, private matTooltip: MatTooltip) {}

  ngAfterViewInit() {
    this.checkEllipsis();
  }

  private checkEllipsis() {
    const element = this.elementRef.nativeElement;
    if (element.offsetWidth < element.scrollWidth) {
      this.matTooltip.disabled = false;
    } else {
      this.matTooltip.disabled = true;
    }
  }
}
